export const TRANSLATIONS_FI = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'Home',
      about: 'Tungkol sa',
      support: 'Suporta',
      pricing: 'Pagpepresyo',
      signIn: 'Mag-sign In',
      signUp: 'Mag-sign Up'
    },
    // banner: {
    //   header: 'Sistema ng Pamamahala ng Feedback',
    //   subHeader:
    //     'Pinapatakbo ng Polaron, ang Feedback Now ay isang madaling gamitin, secure at napakahusay na cost-effective na paraan upang palawakin ang iyong abot at makuha ang pulso ng iyong mga audience',
    //   supportingText:
    //     'Sa FeedbackNow, maaari mong bigyan ang iyong mga customer ng boses sa anumang wika, anumang format, anumang oras.',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: 'Sabihin sa amin ... sa iyong wika!',
      subHeader:
        'Alam mo ba na sa aming mga komunidad ay nagsasalita kami ng higit sa 270 mga wika, kumakatawan sa 300 mga ninuno at nagsasagawa ng 100 mga relihiyon?',
      supportingText1:
        'Ang FeedbackNow ay isang madaling gamitin, ligtas at murang paraan',
      supportingText2: 'upang palawakin ang inyong kayang abutin at makuha ang pulso ng inyong mga manonood sa 50+ na mga wika.',
      generic: {
        getStarted: 'Magbigay ng Feedback Ngayon'
      }
    },
    about: {
      header: 'Sabihin sa amin ... sa iyong wika!',
      supportingText:
        'Alam mo ba na sa aming mga komunidad ay nagsasalita kami ng higit sa 270 mga wika, kumakatawan sa 300 mga ninuno at nagsasagawa ng 100 mga relihiyon?',
      boxOne: 'Pagkumpleto',
      boxTwo: 'Suporta',
      boxThree: 'Pagsasalin'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'Paano ito gumagana para sa mga kliyente',
        step_one: 'Mag-sign up bilang isang kliyente',
        step_two: 'Kunin ang iyong API key',
        step_three: 'Ipadala ang iyong feedback sa anumang wika kasama ng API key',
        step_four: 'Kumuha ng feedback na isinalin sa inyong wika'
      },
      translator: {
        header: 'Paano ito gumagana para sa mga tagasalinwika',
        step_one: 'Mag-sign up bilang isang tagasalinwika',
        step_two: 'I-upload ang iyong mga sertipiko ng NAATI at ma-verify',
        step_three: 'Isalinwika ang mga itinalagang feedback',
        step_four: 'Mabayaran'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'Piliin ang Uri ng Feedback',
    enterText: 'I-enter Ang Iyong Feedback',
    generic: {
      submit: 'Isumite',
      word: 'mga salita',
      send: 'Send',
      audioRecorder: 'Audio Recorder',
      audioFile:'Audio File',
      videoRecorder:'Video Recorder ',
      videoFile:'Video File ',
      uploadText:'Mag-upload ng file o i-drag at i-drop',
      uploadAudio:'Mag-upload ng Audio',
      uploadVideo:'Mag-upload ng Video',
      uploading: 'Nag-a-upload',
      startRecording: 'Simulan ang Pag-record',
      stopRecording: 'Ihinto ang Pag-record',
      saveRecording: 'I-save ang Pag-record',
      cancelRecording: 'Kanselahin ang Pag-record',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'Mangyaring i-enter ang iyong feedback bago magpatuloy. Salamat po.',
      errorMessage: 'Nagkaproblema. Pakisubukang muli.',
      successMessage: 'Matagumpay na naidagdag ang feedback.'
    },
    content: {
      completion: 'Pagkumpleto',
      support: 'Suporta',
      translation: 'Pagsasalin'
    }
  },
  pricingModule: {
    header: 'Pagpapresyo',
    subheader: 'Piliin ang pinakamagandang plano para sa iyo',
    supportingText:
      'Ang aming modelo ng pagpepresyo ay nababaluktot at makabago, na may 80% ng mga bayarin na direktang napupunta sa mga tagasalin. Kung ikaw ay isang rural NGO, maliit na negosyo o isang malaking tagapagbigay ng pangangalagang pangkalusugan, mayroon kaming plano upang umangkop sa iyong mga pangangailangan. Kailangan ng karagdagang impormasyon? Makipag-ugnayan sa amin dito',
    module: {
      title: 'Modelo ng Social Enterprise',
      descriptionLineOne:
        'Isinasalin ng FeedbackNow ang feedback ng iyong customer sa pamamagitan ng mga tagasalin na na-certify ng NAATI. Hindi mo kailangang mag-alala tungkol sa pagsasalin ng feedback ng iyong customer. Nagpadala ka sa amin ng feedback sa anumang wika at gagawin namin ang pagsasalin para sa iyo.',
      descriptionLineTwo:
        'Lilikha ka rin ng mga pagtitipid sa gastos at kahusayan para sa iyong organisasyon.'
    }
  },
  footer: {
    gridOne: {
      title: 'SUPORTA',
      listOne: 'Pagpepresyo',
      listTwo: 'Dokumentasyon',
      listThree: 'Maki-ugnay sa amin'
    },
    gridTwo: {
      title: 'KOMPANYA',
      listOne: 'Tungkol sa',
      listTwo: 'Blog'
    },
    gridThree: {
      title: 'LEGAL',
      listOne: 'Privacy at Patakaran',
      listTwo: 'Mga Tuntunin at Kundisyon'
    },
    gridFour: {
      title: 'MAG-SUBSCRIBE SA AMING NEWSLETTER',
      listOne:
        'Ang pinakabagong mga balita, artikulo at mapagkukunan, na ipinapadala sa inyong inbox linggu-linggo.',
      placeHolder: 'I-enter ang email',
      button: 'Mag-subscribe'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. Lahat ng karapatan ay nakalaan.'
    }
  },
  aboutUsModule: {
    title: 'Tungkol sa amin',
    description:
      'Gaano man kaabanse ang naabot ng teknolohiya, ang mga tumpak na solusyon sa wika ay nangangailangan pa rin ng kadalubhasaan ng tao. Ang mga tagasalin ay patuloy na magsisilbing mahalagang link sa pagitan ng mga tagapagbigay ng serbisyo (service providers) at mga multikultural na komunidad ng Australya. Sa nakalipas na 20 taon, ang aming mga kostumer ay nasa kaibuturan ng lahat ng ginagawa ng mga serbisyong pangwika ng Polaron...'
  },
  contactUsModule: {
    title: 'Maki-ugnay sa amin',
    header: 'Paano kami makakatulong?',
    supportingText:
      'Pakipaliwanag kung ano ang isyu sa ibaba. Sisikapin naming tumugon sa loob ng 24 oras. Kung apurahan ang iyong isyu, mangyaring tumawag sa 1300 88 55 61.',
    fieldOneLabel: 'Ang Iyong Pangalan ',
    fieldOnePlaceholder: 'Halimbawa: John Doe',
    fieldTwoLabel: 'Ang Iyong email ',
    fieldTwoPlaceholder: 'Halimbawa: john@feedbacknow.com',
    fieldThreeLabel: 'Ang Iyong Isyu o Feedback',
    fieldThreePlaceholder: 'Pumasok dito',
    button: 'Isumite'
  },
  userInfoForm: {
    title: 'Impormasyon ng User',
    header: 'itago mo',
    public: 'Pampubliko',
    private: 'Pribado',
    fieldOneLabel: 'Pangalan',
    fieldOnePlaceholder: 'Halimbawa: John',
    fieldTwoLabel: 'Apelyido',
    fieldTwoPlaceholder: 'Halimbawa: Doe',
    fieldThreeLabel: 'Email',
    fieldThreePlaceholder: 'Halimbawa: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'Kailangan ang Impormasyon ng User'
    }
  }
};
