import { ActionType } from '../action-types';
import {
  GetAllTranslatorListAction,
  GetTranslatorDetailAction,
  VerifyTranslatorAction,
  RejectTranslatorAction,
  RejectDocumentAction,
  ResetAction,
  VerifyDocumentAction
} from '../actions';

interface IAdminTranslatorState {
  getAllTranslatorListRequesting: boolean;
  getAllTranslatorListResponse: any | [];
  getAllTranslatorListSuccess: boolean;
  getAllTranslatorListSuccessMsg: string | '';
  getAllTranslatorListError: boolean;
  getAllTranslatorListErrorMsg: string | '';
  getAllTranslatorListResponseStatusCode: number | null;

  getTranslatorDetailRequesting: boolean;
  getTranslatorDetailResponse: any | [];
  getTranslatorDetailSuccess: boolean;
  getTranslatorDetailSuccessMsg: string | '';
  getTranslatorDetailError: boolean;
  getTranslatorDetailErrorMsg: string | '';
  getTranslatorDetailResponseStatusCode: number | null;

  verifyTranslatorRequesting: boolean;
  verifyTranslatorResponse: any | [];
  verifyTranslatorSuccess: boolean;
  verifyTranslatorSuccessMsg: string | '';
  verifyTranslatorError: boolean;
  verifyTranslatorErrorMsg: string | '';
  verifyTranslatorResponseStatusCode: number | null;

  rejectTranslatorRequesting: boolean;
  rejectTranslatorResponse: any | [];
  rejectTranslatorSuccess: boolean;
  rejectTranslatorSuccessMsg: string | '';
  rejectTranslatorError: boolean;
  rejectTranslatorErrorMsg: string | '';
  rejectTranslatorResponseStatusCode: number | null;

  rejectDocumentRequesting: boolean;
  rejectDocumentResponse: any | [];
  rejectDocumentSuccess: boolean;
  rejectDocumentSuccessMsg: string | '';
  rejectDocumentError: boolean;
  rejectDocumentErrorMsg: string | '';
  rejectDocumentResponseStatusCode: number | null;

  verifyDocumentRequesting: boolean;
  verifyDocumentResponse: any | [];
  verifyDocumentSuccess: boolean;
  verifyDocumentSuccessMsg: string | '';
  verifyDocumentError: boolean;
  verifyDocumentErrorMsg: string | '';
  verifyDocumentResponseStatusCode: number | null;
}

const initialState = {
  getAllTranslatorListRequesting: false,
  getAllTranslatorListResponse: [],
  getAllTranslatorListSuccess: false,
  getAllTranslatorListSuccessMsg: '',
  getAllTranslatorListError: false,
  getAllTranslatorListErrorMsg: '',
  getAllTranslatorListResponseStatusCode: null,

  getTranslatorDetailRequesting: false,
  getTranslatorDetailResponse: [],
  getTranslatorDetailSuccess: false,
  getTranslatorDetailSuccessMsg: '',
  getTranslatorDetailError: false,
  getTranslatorDetailErrorMsg: '',
  getTranslatorDetailResponseStatusCode: null,

  verifyTranslatorRequesting: false,
  verifyTranslatorResponse: [],
  verifyTranslatorSuccess: false,
  verifyTranslatorSuccessMsg: '',
  verifyTranslatorError: false,
  verifyTranslatorErrorMsg: '',
  verifyTranslatorResponseStatusCode: null,

  rejectTranslatorRequesting: false,
  rejectTranslatorResponse: [],
  rejectTranslatorSuccess: false,
  rejectTranslatorSuccessMsg: '',
  rejectTranslatorError: false,
  rejectTranslatorErrorMsg: '',
  rejectTranslatorResponseStatusCode: null,

  rejectDocumentRequesting: false,
  rejectDocumentResponse: [],
  rejectDocumentSuccess: false,
  rejectDocumentSuccessMsg: '',
  rejectDocumentError: false,
  rejectDocumentErrorMsg: '',
  rejectDocumentResponseStatusCode: null,

  verifyDocumentRequesting: false,
  verifyDocumentResponse: [],
  verifyDocumentSuccess: false,
  verifyDocumentSuccessMsg: '',
  verifyDocumentError: false,
  verifyDocumentErrorMsg: '',
  verifyDocumentResponseStatusCode: null
};

const reducer = (
  state: IAdminTranslatorState = initialState,
  action:
    | GetAllTranslatorListAction
    | GetTranslatorDetailAction
    | VerifyTranslatorAction
    | RejectTranslatorAction
    | ResetAction
    | VerifyDocumentAction
    | RejectDocumentAction
): IAdminTranslatorState => {
  switch (action.type) {
    case ActionType.GET_ALL_TRANSLATOR_LIST_REQUEST:
      return {
        ...state,
        getAllTranslatorListRequesting: true,
        getAllTranslatorListResponse: [],
        getAllTranslatorListSuccess: false,
        getAllTranslatorListSuccessMsg: '',
        getAllTranslatorListError: false,
        getAllTranslatorListErrorMsg: '',
        getAllTranslatorListResponseStatusCode: null
      };
    case ActionType.GET_ALL_TRANSLATOR_LIST_SUCCESS:
      return {
        ...state,
        getAllTranslatorListRequesting: false,
        getAllTranslatorListResponse: action.payload.data,
        getAllTranslatorListSuccess: true,
        getAllTranslatorListSuccessMsg: action.payload.message,
        getAllTranslatorListResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_ALL_TRANSLATOR_LIST_ERROR:
      return {
        ...state,
        getAllTranslatorListRequesting: false,
        getAllTranslatorListResponse: action.payload.data,
        getAllTranslatorListError: true,
        getAllTranslatorListErrorMsg: action.payload.message,
        getAllTranslatorListResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_TRANSLATOR_DETAIL_REQUEST:
      return {
        ...state,
        getTranslatorDetailRequesting: true,
        getTranslatorDetailResponse: [],
        getTranslatorDetailSuccess: false,
        getTranslatorDetailSuccessMsg: '',
        getTranslatorDetailError: false,
        getTranslatorDetailErrorMsg: '',
        getTranslatorDetailResponseStatusCode: null
      };
    case ActionType.GET_TRANSLATOR_DETAIL_SUCCESS:
      return {
        ...state,
        getTranslatorDetailRequesting: false,
        getTranslatorDetailResponse: action.payload.data,
        getTranslatorDetailSuccess: true,
        getTranslatorDetailSuccessMsg: action.payload.message,
        getTranslatorDetailResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_TRANSLATOR_DETAIL_ERROR:
      return {
        ...state,
        getTranslatorDetailRequesting: false,
        getTranslatorDetailResponse: action.payload.data,
        getTranslatorDetailError: true,
        getTranslatorDetailErrorMsg: action.payload.message,
        getTranslatorDetailResponseStatusCode: action.payload.statusCode
      };

    case ActionType.VERIFY_TRANSLATOR_REQUEST:
      return {
        ...state,
        verifyTranslatorRequesting: true,
        verifyTranslatorResponse: [],
        verifyTranslatorSuccess: false,
        verifyTranslatorSuccessMsg: '',
        verifyTranslatorError: false,
        verifyTranslatorErrorMsg: '',
        verifyTranslatorResponseStatusCode: null
      };
    case ActionType.VERIFY_TRANSLATOR_SUCCESS:
      return {
        ...state,
        verifyTranslatorRequesting: false,
        verifyTranslatorResponse: action.payload.data,
        verifyTranslatorSuccess: true,
        verifyTranslatorSuccessMsg: action.payload.message,
        verifyTranslatorResponseStatusCode: action.payload.statusCode
      };
    case ActionType.VERIFY_TRANSLATOR_ERROR:
      return {
        ...state,
        verifyTranslatorRequesting: false,
        verifyTranslatorResponse: action.payload.data,
        verifyTranslatorError: true,
        verifyTranslatorErrorMsg: action.payload.message,
        verifyTranslatorResponseStatusCode: action.payload.statusCode
      };

    case ActionType.REJECT_TRANSLATOR_REQUEST:
      return {
        ...state,
        rejectTranslatorRequesting: true,
        rejectTranslatorResponse: [],
        rejectTranslatorSuccess: false,
        rejectTranslatorSuccessMsg: '',
        rejectTranslatorError: false,
        rejectTranslatorErrorMsg: '',
        rejectTranslatorResponseStatusCode: null
      };
    case ActionType.REJECT_TRANSLATOR_SUCCESS:
      return {
        ...state,
        rejectTranslatorRequesting: false,
        rejectTranslatorResponse: action.payload.data,
        rejectTranslatorSuccess: true,
        rejectTranslatorSuccessMsg: action.payload.message,
        rejectTranslatorResponseStatusCode: action.payload.statusCode
      };
    case ActionType.REJECT_TRANSLATOR_ERROR:
      return {
        ...state,
        rejectTranslatorRequesting: false,
        rejectTranslatorResponse: action.payload.data,
        rejectTranslatorError: true,
        rejectTranslatorErrorMsg: action.payload.message,
        rejectTranslatorResponseStatusCode: action.payload.statusCode
      };

    case ActionType.REJECT_DOCUMENT_REQUEST:
      return {
        ...state,
        rejectDocumentRequesting: true,
        rejectDocumentResponse: [],
        rejectDocumentSuccess: false,
        rejectDocumentSuccessMsg: '',
        rejectDocumentError: false,
        rejectDocumentErrorMsg: '',
        rejectDocumentResponseStatusCode: null
      };
    case ActionType.REJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        rejectDocumentRequesting: false,
        rejectDocumentResponse: action.payload.data,
        rejectDocumentSuccess: true,
        rejectDocumentSuccessMsg: action.payload.message,
        rejectDocumentResponseStatusCode: action.payload.statusCode
      };
    case ActionType.REJECT_DOCUMENT_ERROR:
      return {
        ...state,
        rejectDocumentRequesting: false,
        rejectDocumentResponse: action.payload.data,
        rejectDocumentError: true,
        rejectDocumentErrorMsg: action.payload.message,
        rejectDocumentResponseStatusCode: action.payload.statusCode
      };

    case ActionType.VERIFY_DOCUMENT_REQUEST:
      return {
        ...state,
        verifyDocumentRequesting: true,
        verifyDocumentResponse: [],
        verifyDocumentSuccess: false,
        verifyDocumentSuccessMsg: '',
        verifyDocumentError: false,
        verifyDocumentErrorMsg: '',
        verifyDocumentResponseStatusCode: null
      };
    case ActionType.VERIFY_DOCUMENT_SUCCESS:
      return {
        ...state,
        verifyDocumentRequesting: false,
        verifyDocumentResponse: action.payload.data,
        verifyDocumentSuccess: true,
        verifyDocumentSuccessMsg: action.payload.message,
        verifyDocumentResponseStatusCode: action.payload.statusCode
      };
    case ActionType.VERIFY_DOCUMENT_ERROR:
      return {
        ...state,
        verifyDocumentRequesting: false,
        verifyDocumentResponse: action.payload.data,
        verifyDocumentError: true,
        verifyDocumentErrorMsg: action.payload.message,
        verifyDocumentResponseStatusCode: action.payload.statusCode
      };

    case ActionType.RESET_TRANSLATOR_DETAIL_INFO_PREVIOUS_STATE:
      return {
        ...state,
        verifyTranslatorRequesting: false,
        verifyTranslatorResponse: [],
        verifyTranslatorSuccess: false,
        verifyTranslatorSuccessMsg: '',
        verifyTranslatorError: false,
        verifyTranslatorErrorMsg: '',
        verifyTranslatorResponseStatusCode: null,

        rejectTranslatorRequesting: false,
        rejectTranslatorResponse: [],
        rejectTranslatorSuccess: false,
        rejectTranslatorSuccessMsg: '',
        rejectTranslatorError: false,
        rejectTranslatorErrorMsg: '',
        rejectTranslatorResponseStatusCode: null,

        verifyDocumentRequesting: false,
        verifyDocumentResponse: [],
        verifyDocumentSuccess: false,
        verifyDocumentSuccessMsg: '',
        verifyDocumentError: false,
        verifyDocumentErrorMsg: '',
        verifyDocumentResponseStatusCode: null
      };
    default:
      return state;
  }
};

export default reducer;
