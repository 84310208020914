export enum ActionType {
  /**********************************/
  /*                                 */
  /* GET ALL CLIENT LIST ACTION TYPE */
  /*                                 */
  /***********************************/

  GET_ALL_CLIENT_LIST_REQUEST = 'get_all_client_list_request',
  GET_ALL_CLIENT_LIST_SUCCESS = 'get_all_client_list_success',
  GET_ALL_CLIENT_LIST_ERROR = 'get_all_client_list_error'
}
