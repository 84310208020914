export const TRANSLATIONS_PL = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'Strona główna',
      about: 'O nas',
      support: 'Pomoc',
      pricing: 'Cennik',
      signIn: 'Zaloguj się',
      signUp: 'Zapisz się'
    },
    // banner: {
    //   header: 'System zarządzania opiniami',
    //   subHeader:
    //     'Obsługiwana przez firmę Polaron usługa Feedback Now jest łatwym w użyciu, bezpiecznym i niezwykle ekonomicznym sposobem na zwiększenie zasięgu i mierzenie tętna odbiorców.',
    //   supportingText:
    //     'Dzięki FeedbackNow możesz przekazać swoim klientom głos w dowolnym języku, dowolnym formacie io każdej porze.',
    //   generic: {
    //     getStarted: 'Zaczynaj'
    //   }
    // },
    banner: {
      header: 'Powiedz nam ... w swoim języku!',
      subHeader:
        'Czy wiesz, że w naszych społecznościach mówimy ponad 270 językami, reprezentujemy 300 przodków i wyznajemy 100 religii?',
      supportingText1:
        'FeedbackNow to łatwy w użyciu, bezpieczny i ekonomiczny sposób',
      supportingText2: 'na zwiększenie zasięgu i zmierzenia pulsu swoich odbiorców w ponad 50 językach.',
      generic: {
        getStarted: 'Przekaż opinię teraz'
      }
    },
    about: {
      header: 'Powiedz nam… w swoim języku!',
      supportingText:
        'Czy wiesz, że w naszych społecznościach mówimy ponad 270 językami, reprezentujemy 300 przodków i wyznajemy 100 religii?',
      boxOne: 'ukończenie',
      boxTwo: 'wsparcie',
      boxThree: 'tłumaczenie'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'Jak FeedbackNow działa dla klientów',
        step_one: 'Zapisz się jako klient',
        step_two: 'Pobierz klucz API',
        step_three: 'Wyślij swoją opinię w dowolnym języku wraz z kluczem API',
        step_four: 'Otrzymasz opinię przetłumaczoną'
      },
      translator: {
        header: 'Jak FeedbackNow działa dla tłumaczy',
        step_one: 'Zapisz się jako tłumacz',
        step_two: 'Prześlij swój certyfikat NAATI w celu weryfikacji',
        step_three: 'Przetłumacz przypisane tobie opinie',
        step_four: 'Płatności'
      }
    }
  },
  selectLanguage: {
    title: 'Wybierz swój język.',
    generic: {
      letsGo: 'Chodźmy'
    }
  },
  selectFeedbackType: {
    title: 'Wybierz sposób, w jaki chcesz przekazać swoją opinię',
    enterText: 'Przekaż swoją opinię',
    generic: {
      submit: 'Wyślij',
      word: 'słowa',
      send: 'Wysyłanie',
      audioRecorder: 'Rejestrator dźwięku',
      audioFile:'Plik dźwiękowy',
      videoRecorder:'Rejestrator wideo ',
      videoFile:'Plik wideo',
      uploadText:'Prześlij plik lub przeciągnij i upuść',
      uploadAudio:'Wgraj plik dzwiękowy',
      uploadVideo:'Wgraj wideo',
      uploading: 'Przesyłanie',
      startRecording: 'Rozpocznij nagranie',
      stopRecording: 'Zatrzymaj nagranie',
      saveRecording: 'Zachowaj nagranie',
      cancelRecording: 'Anuluj nagranie',
      acceptRecording: 'Zaakceptuj nagranie',
      processing: 'Przetwarzamy twoją opinię',
      startingIn: 'Nagranie rozpocznie się za',
      recordingNow: 'Nagrywamy',
      recordingDeleted: 'Nagranie usunięte',
    }
  },
  thankyou: {
    title: 'Dziękujemy!',
    message: 'Otrzymaliśmy twoją opinię.'
  },
  generic: {
    message: {
      fieldValidation: 'Najpiesz wpisz swoją opinię. Dziękujemy. ',
      errorMessage: 'Coś poszło nie tak. Spróbuj ponownie.',
      successMessage: 'Opinia dodana pomyśłnie'
    }
  },
  pricingModule: {
    header: 'Cennik',
    subheader: 'Wybierz najlepszy plan dla siebie',
    supportingText:
      'Nasz model cenowy jest elastyczny i innowacyjny, a 80% opłat trafia bezpośrednio do tłumaczy. Niezależnie od tego, czy jesteś organizacją pozarządową działającą na obszarach wiejskich, małą firmą czy dużym dostawcą usług medycznych, mamy plan dostosowany do Twoich potrzeb. Potrzebujesz więcej informacji? Skontaktuj się z nami tutaj',
    module: {
      title: 'Social Enterprise Model',
      descriptionLineOne:
        'FeedbackNow tłumaczy opinie klientów za pośrednictwem tłumaczy z certyfikatem NAATI. Nie musisz się martwić o tłumaczenie opinii klientów. Przesłałeś nam opinię w dowolnym języku, a my wykonamy dla Ciebie tłumaczenie.',
      descriptionLineTwo:
        'Będziesz także tworzyć oszczędności i wydajność dla swojej organizacji.'
    }
  },
  footer: {
    gridOne: {
      title: 'WSPARCIE',
      listOne: 'Cennik',
      listTwo: 'Dokumentacja',
      listThree: 'Kontakt'
    },
    gridTwo: {
      title: 'FIRMA',
      listOne: 'O nas',
      listTwo: 'Blog'
    },
    gridThree: {
      title: 'LEGAL',
      listOne: 'Prywatność i polityka',
      listTwo: 'Zasady i warunki'
    },
    gridFour: {
      title: 'ZAPISZ SIĘ DO NEWSLETTERA',
      listOne:
        'Najnowsze wiadomości, artykuły i zasoby wysyłane co tydzień na Twoją skrzynkę odbiorczą.',
      placeHolder: 'Wpisz email',
      button: 'Zapisz się '
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. Wszelkie prawa zastrzeżone.'
    }
  },
  aboutUsModule: {
    title: 'O nas',
    description:
      'Niezależnie od tego, jak daleko posunie się technologia, precyzyjne usługi językowe nadal wymagają ludzkiej wiedzy. Tłumacze zawsze będą istotnym łącznikiem między usługodawcami a wielokulturowymi społecznościami Australii. Od ponad 20 lat, Polaron stawia naszych klientów w centrum wszystkiego, co robimy…'
  },
  contactUsModule: {
    title: 'Kontakt',
    header: 'W czym możemy pomóc?',
    supportingText:
      'Wyjaśnij, na czym polega problem. Postaramy się odpowiadać w ciągu 24 godzin. Jeśli problem jest pilny, zadzwoń pod numer 1300 88 55 61.',
    fieldOneLabel: 'Dane osobowe',
    fieldOnePlaceholder: 'Przykład: John Doe',
    fieldTwoLabel: 'Twój email',
    fieldTwoPlaceholder: 'Przykład: john@feedbacknow.com',
    fieldThreeLabel: 'Twój problem lub opinia',
    fieldThreePlaceholder: 'Wejdź tutaj',
    button: 'Składać'
  },
  userInfoForm: {
    title: 'Informacje o użytkowniku',
    header: 'Zostawić',
    public: 'Publiczne',
    private: 'Prywatne',
    fieldOneLabel: 'Imię',
    fieldOnePlaceholder: 'Przykład: John',
    fieldTwoLabel: 'Nazwisko',
    fieldTwoPlaceholder: 'Przykład: Doe',
    fieldThreeLabel: 'Email',
    fieldThreePlaceholder: 'Przykład: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'Wymagane informacje o użytkowniku'
    }
  }
};
