import { Dispatch } from 'redux';

import { ActionType } from '../action-types';
import { GetAllAdminNotificationAction } from '../actions';
import { fbnApi as API } from '../../../../../../../utils/api-handler';

/*******************************************/
/*                                         */
/* GET ALL ADMIN NOTIFICATIONS API CREATOR */
/*                                         */
/*******************************************/

type GetAllAdminNotificationsRequestDataType = {
  userId: string;
  token: string;
  page: number;
  limit: number;
};

export const getAllAdminNotificationsList = ({
  userId,
  token,
  page,
  limit
}: GetAllAdminNotificationsRequestDataType) => {
  return async (dispatch: Dispatch<GetAllAdminNotificationAction>) => {
    dispatch({
      type: ActionType.GET_ALL_ADMIN_NOTIFICATIONS_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/admin/notifications/${userId}` // ?page=${page}&perpage=${limit}`
        },
        token
      );
      dispatch({
        type: ActionType.GET_ALL_ADMIN_NOTIFICATIONS_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_ADMIN_NOTIFICATIONS_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};
