export const TRANSLATIONS_VI = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'Trang nhà',
      about: 'Sơ lược',
      support: 'Ủng hộ',
      pricing: 'Giá cả',
      signIn: 'Đăng nhập',
      signUp: 'Đăng ký'
    },
    // banner: {
    //   header: 'Hệ thống quản lý phản hồi',
    //   subHeader:
    //     'Được cung cấp bởi Polaron, Feedback Now là một cách dễ sử dụng, an toàn và tiết kiệm chi phí đáng kể để mở rộng phạm vi tiếp cận và nắm bắt nhịp đập của khán giả',
    //   supportingText:
    //     'With Feedback Now, you can give your customers voice in any language, any format, any time. ',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: 'Cho chúng tôi biết…bằng ngôn ngữ của quý vị!',
      subHeader:
        'Bạn có biết rằng trong các cộng đồng của chúng ta, chúng ta nói hơn 270 ngôn ngữ, đại diện cho 300 tổ tiên và thực hành 100 tôn giáo?',
      supportingText1:
        'FeedbackNow là cách dễ dàng sử dụng, an toàn và ít tốn kém nhưng hiệu',
      supportingText2: 'quả để mở rộng tầm với và hiểu được quan điểm thính giả của quý vị với hơn 50 ngôn ngữ.',
      generic: {
        getStarted: 'Đưa ra phản hồi ngay bây giờ'
      }
    },
    about: {
      header: 'Hãy cho chúng tôi biết … bằng ngôn ngữ của bạn!',
      supportingText:
        'Bạn có biết rằng trong các cộng đồng của chúng ta, chúng ta nói hơn 270 ngôn ngữ, đại diện cho 300 tổ tiên và thực hành 100 tôn giáo?',
      boxOne: 'Hoàn thành',
      boxTwo: 'Ủng hộ',
      boxThree: 'Dịch'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'Điều này hiệu quả cho khách hàng ra sao',
        step_one: 'Đăng ký với tư cách là khách hàng',
        step_two: 'Nhận khóa API của quý vị',
        step_three: 'Gửi ý kiến đóng góp bằng bất cứ ngôn ngữ nào cùng với khóa API',
        step_four: 'Nhận ý kiến đóng góp đã được dịch'
      },
      translator: {
        header: 'Điều này hiệu quả cho phiên dịch viên ra sao?',
        step_one: 'Đăng ký với tư cách là phiên dịch viên',
        step_two: 'Đăng tải lên bằng cấp NAATI của quý vị và được xác nhận',
        step_three: 'Dịch các ý kiến đóng góp được phân bổ',
        step_four: 'Lãnh tiền'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'Chọn Loại Ý kiến',
    enterText: 'Nhập Ý kiến Đóng góp của Quý vị',
    generic: {
      submit: 'Gửi đi',
      word: 'từ',
      send: 'Send',
      audioRecorder: 'Máy Thu âm',
      audioFile:'Tệp Thu âm',
      videoRecorder:'Máy Thu hình',
      videoFile:'Tệp Thu hình',
      uploadText:'Đăng tải lên một tệp hoặc kéo và thả xuống',
      uploadAudio:'Đăng tải lên tệp Thu âm',
      uploadVideo:'Đăng tải lên Tệp Thu hình',
      uploading: 'Đang tải lên',
      startRecording: 'Bắt đầu Thu âm/Thu hình',
      stopRecording: 'Dừng Thu âm/Thu hình',
      saveRecording: 'Lưu lại tệp Thu âm/Thu hình',
      cancelRecording: 'Hủy Thu âm/Thu hình',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'Xin vui lòng nhập ý kiến đóng góp của quý vị trước khi tiếp tục. Cảm ơn.',
      errorMessage: 'Có điều gì đó không đúng. Xin vui lòng làm lại.',
      successMessage: 'Ý kiến được thêm vào thành công'
    },
    content: {
      completion: 'Hoàn thành',
      support: 'Ủng hộ',
      translation: 'Dịch'
    }
  },
  pricingModule: {
    header: 'Giá cả',
    subheader: 'Chọn kế hoạch tốt nhất cho quý vị',
    supportingText:
      'Mô hình định giá của chúng tôi rất linh hoạt và sáng tạo, với 80% phí sẽ được chuyển trực tiếp cho người dịch. Cho dù bạn là một tổ chức phi chính phủ ở nông thôn, doanh nghiệp nhỏ hay nhà cung cấp dịch vụ chăm sóc sức khỏe lớn, chúng tôi đều có kế hoạch phù hợp với nhu cầu của bạn. Cần thêm thông tin? Liên hệ với chúng tôi tại đây',
    module: {
      title: 'Mô hình doanh nghiệp xã hội',
      descriptionLineOne:
        'FeedbackNow dịch phản hồi khách hàng của bạn thông qua các dịch giả được NAATI chứng nhận. Bạn không phải lo lắng về việc dịch phản hồi của khách hàng. Bạn đã gửi phản hồi cho chúng tôi bằng bất kỳ ngôn ngữ nào và chúng tôi sẽ thực hiện bản dịch cho bạn.',
      descriptionLineTwo:
        'Bạn cũng sẽ tạo ra các khoản tiết kiệm chi phí và hiệu quả cho tổ chức của mình.'
    }
  },
  footer: {
    gridOne: {
      title: 'HỖ TRỢ',
      listOne: 'Giá cả',
      listTwo: 'Tài liệu',
      listThree: 'Liên lạc Chúng tôi'
    },
    gridTwo: {
      title: 'CÔNG TY',
      listOne: 'Sơ lược',
      listTwo: 'Nhật ký Trực tuyến'
    },
    gridThree: {
      title: 'HỢP PHÁP',
      listOne: 'Quyền riêng tư và Chính sách',
      listTwo: 'Các điều khoản và điều kiện'
    },
    gridFour: {
      title: 'ĐĂNG KÝ NHẬN TỜ THÔNG TIN CỦA CHÚNG TÔI',
      listOne:
        'Tin tức, các bài viết và nguồn tài liệu mới nhất, được gửi đến hộp thư điện tử của quý vị hàng tuần.',
      placeHolder: 'Nhập email',
      button: 'Đăng ký'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. Bảo lưu mọi quyền hạn.'
    }
  },
  aboutUsModule: {
    title: 'Về chúng tôi',
    description:
      'Bất kể công nghệ tân tiến thế nào, giải pháp ngôn ngữ chính xác vẫn cần đến chuyên môn của con người. Các phiên dịch viên sẽ luôn luôn là mối liên kết vô cùng quan trọng giữa các nhà cung cấp dịch vụ và các cộng đồng đa văn hóa của Úc. Hơn 20 năm qua, nhà cung cấp dịch vụ ngôn ngữ Polaron đã đặt khách hàng của chúng tôi là trọng tâm của mọi điều chúng tôi làm…'
  },
  contactUsModule: {
    title: 'Liên lạc Chúng tôi',
    header: 'Chúng tôi có thể giúp bằng cách nào?',
    supportingText:
      'Xin vui lòng giải thích vấn đề ra sao ở dưới đây. Chúng tôi cố gắng hồi đáp trong vòng 24 giờ. Nếu là vấn đề khẩn cấp, xin vui lòng gọi số 1300 88 55 61.',
    fieldOneLabel: 'Tên Quý vị',
    fieldOnePlaceholder: 'Ví dụ: John Doe',
    fieldTwoLabel: 'Email của Quý vị',
    fieldTwoPlaceholder: 'Ví dụ: john@feedbacknow.com',
    fieldThreeLabel: 'Vấn đề hoặc Ý kiến của Quý vị',
    fieldThreePlaceholder: 'Nhập vào đây',
    button: 'Gửi đi'
  },
  userInfoForm: {
    title: 'Thông tin của Người sử dụng',
    header: 'giữ thông tin',
    public: 'Công khai',
    private: 'Riêng tư',
    fieldOneLabel: 'Tên',
    fieldOnePlaceholder: 'Ví dụ: John',
    fieldTwoLabel: 'Họ',
    fieldTwoPlaceholder: 'Ví dụ: Doe',
    fieldThreeLabel: 'Email',
    fieldThreePlaceholder: 'Ví dụ: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'Yêu cầu Thông tin của Người sử dụng'
    }
  }
};
