export const TRANSLATIONS_HI = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'घर',
      about: 'के बारे में',
      support: 'सहायता',
      pricing: 'मूल्य निर्धारण',
      signIn: 'साइन इन करें',
      signUp: 'साइन अप करें'
    },
    // banner: {
    //   header: 'प्रतिक्रिया प्रबंधन प्रणाली',
    //   subHeader:
    //     'Polaron द्वारा संचालित, FeedbackNow आपकी पहुंच का विस्तार करने और अपने दर्शकों की नब्ज लेने के लिए उपयोग में आसान, सुरक्षित और उल्लेखनीय रूप से लागत प्रभावी तरीका है',
    //   supportingText:
    //     'FeedbackNow के साथ, आप अपने ग्राहकों को किसी भी भाषा में, किसी भी प्रारूप में, किसी भी समय आवाज दे सकते हैं।',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: 'हमें बताओ … अपनी भाषा में',
      subHeader:
        'क्या आप जानते हैं कि हमारे समुदायों में हम 270 से अधिक भाषाएँ बोलते हैं, 300 पूर्वजों का प्रतिनिधित्व करते हैं और 100 धर्मों का पालन करते हैं?',
      supportingText1:
        'FeedbackNow अपनी पहुंच बढ़ाने और 50+ भाषाओं में अपने',
      supportingText2:
        'दर्शकों की नब्ज लेने का उपयोग में आसान, सुरक्षित और किफायती तरीका है।',
      generic: {
        getStarted: 'अभी प्रतिक्रिया दें'
      }
    },
    about: {
      header: 'हमें बताओ … अपनी भाषा में!',
      supportingText:
        'क्या आप जानते हैं कि हमारे समुदायों में हम 270 से अधिक भाषाएँ बोलते हैं, 300 पूर्वजों का प्रतिनिधित्व करते हैं और 100 धर्मों का पालन करते हैं?',
      boxOne: 'समापन',
      boxTwo: 'सहायता',
      boxThree: 'अनुवाद'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'यह ग्राहकों के लिए कैसे काम करता है',
        step_one: 'ग्राहक के रूप में साइन अप करें',
        step_two: 'अपनी एपीआई कुंजी प्राप्त करें',
        step_three: 'एपीआई कुंजी के साथ किसी भी भाषा में अपनी प्रतिक्रिया भेजें',
        step_four: 'अनुवादित फीडबैक प्राप्त करें'
      },
      translator: {
        header: 'यह अनुवादकों के लिए कैसे काम करता है',
        step_one: 'अनुवादक के रूप में साइन अप करें',
        step_two: 'अपना NAATI प्रमाणपत्र अपलोड करें और सत्यापित करें',
        step_three: 'असाइन किए गए फीडबैक का अनुवाद करें',
        step_four: 'भुगतान प्राप्त करना'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'फीडबैक प्रकार चुनें',
    enterText: 'अपनी प्रतिक्रिया दर्ज करें',
    generic: {
      submit: 'प्रस्तुत',
      word: 'शब्द',
      send: 'भेजना',
      audioRecorder: 'ऑडियो रिकॉर्डर',
      audioFile:'ऑडियो फाइल',
      videoRecorder:'वीडियो रिकॉर्डर',
      videoFile:'वीडियो फाइल',
      uploadText:'फ़ाइल अपलोड करें या खींचें और छोड़ें',
      uploadAudio:'ऑडियो अपलोड करें',
      uploadVideo:'विडियो को अॅॅपलोड करें',
      uploading: 'अपलोड हो रहा है',
      startRecording: 'रिकॉर्डिंग शुरू',
      stopRecording: 'रिकॉर्डिंग बंद करें',
      saveRecording: 'रिकॉर्डिंग सहेजें',
      cancelRecording: 'रिकॉर्डिंग रद्द करें',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'कृपया आगे बढ़ने से पहले अपना फ़ीडबैक दर्ज करें। धन्यवाद।',
      errorMessage: 'कुछ गलत हो गया। कृपया पुन: प्रयास करें।',
      successMessage: 'फ़ीडबैक सफलतापूर्वक जोड़ा गया'
    },
    content: {
      completion: 'समापन',
      support: 'सहायता',
      translation: 'अनुवाद'
    }
  },
  pricingModule: {
    header: 'मूल्य निर्धारण',
    subheader: 'अपने लिए सबसे अच्छी योजना चुनें',
    supportingText:
      'हमारा मूल्य निर्धारण मॉडल लचीला और अभिनव है, जिसमें 80% शुल्क सीधे अनुवादकों के पास जाता है। चाहे आप एक ग्रामीण एनजीओ, छोटे व्यवसाय या एक बड़े स्वास्थ्य देखभाल प्रदाता हों, हमारे पास आपकी आवश्यकताओं के अनुरूप एक योजना है। कुछ और जानकारी चाहिये? हमसे यहाँ संपर्क करें',
    module: {
      title: 'सामाजिक उद्यम मॉडल',
      descriptionLineOne:
        'FeedbackNow NAATI-प्रमाणित अनुवादकों के माध्यम से आपके ग्राहक फ़ीडबैक का अनुवाद करता है। आपको अपनी ग्राहक प्रतिक्रिया का अनुवाद करने के बारे में चिंता करने की आवश्यकता नहीं है। आपने हमें किसी भी भाषा में प्रतिक्रिया भेजी है और हम आपके लिए अनुवाद करेंगे।',
      descriptionLineTwo:
        'आप अपने संगठन के लिए लागत बचत और दक्षता भी बना रहे होंगे।'
    }
  },
  footer: {
    gridOne: {
      title: 'सहायता',
      listOne: 'मूल्य निर्धारण',
      listTwo: 'प्रलेखन',
      listThree: 'संपर्क करें'
    },
    gridTwo: {
      title: 'कंपनी',
      listOne: 'के बारे में',
      listTwo: 'ब्लॉग'
    },
    gridThree: {
      title: 'कानूनी',
      listOne: 'गोपनीयता और नीति',
      listTwo: 'नियम और शर्तें'
    },
    gridFour: {
      title: 'हमारे न्युजलेटर की सदस्यता प्राप्त करें',
      listOne:
        'नवीनतम समाचार, लेख और संसाधन, आपके इनबॉक्स में साप्ताहिक रूप से भेजे जाते हैं।',
      placeHolder: 'ईमेल दर्ज करें',
      button: 'सदस्यता लें'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. All rights reserved.'
    }
  },
  aboutUsModule: {
    title: 'हमारे बारे में',
    description:
      'इससे कोई फर्क नहीं पड़ता कि तकनीक कितनी आगे बढ़ चुकी है, सटीक भाषा समाधान के लिए अभी भी मानव विशेषज्ञता की आवश्यकता है। सेवा प्रदाताओं और ऑस्ट्रेलिया के बहुसांस्कृतिक समुदायों के बीच अनुवादक हमेशा एक महत्वपूर्ण कड़ी होंगे। पिछले 20 वर्षों में, पोलरॉन की भाषा सेवाएं हमारे ग्राहकों को हमारे हर काम के केंद्र में रखती हैं।'
  },
  contactUsModule: {
    title: 'संपर्क करें',
    header: 'हम आपकी सहायता किस तरह से कर सकते है?',
    supportingText:
      'कृपया नीचे बताएं कि समस्या क्या है. हम 24 घंटे के भीतर जवाब देने का लक्ष्य रखते हैं। यदि आपकी समस्या अत्यावश्यक है, तो कृपया 1300 88 55 61 पर कॉल करें।',
    fieldOneLabel: 'आपका नाम',
    fieldOnePlaceholder: 'उदाहरण: John Doe',
    fieldTwoLabel: 'आपका ईमेल',
    fieldTwoPlaceholder: 'उदाहरण: john@feedbacknow.com',
    fieldThreeLabel: 'आपका मुद्दा या प्रतिक्रिया',
    fieldThreePlaceholder: 'यहाँ से प्रवेश करें',
    button: 'जमा करना'
  },
  userInfoForm: {
    title: 'उपयोगकर्ता जानकारी',
    header: 'इसे रखें',
    public: 'जनता',
    private: 'निजी',
    fieldOneLabel: 'पहला नाम',
    fieldOnePlaceholder: 'उदाहरण: John',
    fieldTwoLabel: 'उपनाम',
    fieldTwoPlaceholder: 'उदाहरण: Doe',
    fieldThreeLabel: 'ईमेल',
    fieldThreePlaceholder: 'उदाहरण: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'उपयोगकर्ता जानकारी आवश्यक है'
    }
  }
};
