export const TRANSLATIONS_ES = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'Home',
      about: 'Sobre nosotros',
      support: 'Soporte',
      pricing: 'Precios',
      signIn: 'Entrar',
      signUp: 'Registrarse'
    },
    // banner: {
    //   header: 'Sistema de gestión de comentarios',
    //   subHeader:
    //     'Con la tecnología de Polaron, Feedback Now es una forma fácil de usar, segura y muy rentable de expandir su alcance y tomar el pulso de su audiencia.',
    //   supportingText:
    //     'Con FeedbackNow, puede dar voz a sus clientes en cualquier idioma, cualquier formato, en cualquier momento.',
    //   generic: {
    //     getStarted: 'Comencemos'
    //   }
    // },
    banner: {
      header: '¡Cuéntanos… en tu idioma!',
      subHeader:
        '¿Sabía que en nuestras comunidades hablamos más de 270 idiomas, representamos 300 ancestros y practicamos 100 religiones?',
      supportingText1:
        'FeedbackNow es una manera fácil, segura y retantable de',
      supportingText2: 'expandir tu alcance y medir el pulso de tu audiencia en más de 50 idiomas.',
      generic: {
        getStarted: 'Dar su opinión ahora'
      }
    },
    about: {
      header: '¡Cuéntanos… en tu idioma!',
      supportingText:
        '¿Sabía que en nuestras comunidades hablamos más de 270 idiomas, representamos 300 ancestros y practicamos 100 religiones?',
      boxOne: 'Terminación',
      boxTwo: 'Apoyo',
      boxThree: 'Traducción'
    },
    support: {
      supportingText:
        'Esas estadísticas sugieren que capturar y administrar respuestas en diferentes idiomas podría ayudarlo a recopilar datos más precisos, crear un compromiso auténtico y mejorar la toma de decisiones.'
    },
    howItWorks: {
      client: {
        header: 'Cómo funciona para los clientes',
        step_one: 'Regístrate como cliente',
        step_two: 'Obten tu clave API',
        step_three: 'Envía tus comentarios en cualquier idioma junto con la clave API',
        step_four: 'Recibe los comentarios traducidos'
      },
      translator: {
        header: 'Cómo funciona para traductores ',
        step_one: 'Regístrate como traductor ',
        step_two: 'Carga tus certificados (NAATI) y recibe su verificación ',
        step_three: 'Traduce comentarios asignados ',
        step_four: 'Recibe tu pago '
      }
    }
  },
  selectLanguage: {
    title: 'Elige tu idioma.',
    generic: {
      letsGo: 'Vamos'
    }
  },
  selectFeedbackType: {
    title: 'Selecciona cómo quieres dejar un comentario ',
    enterText: 'Ingresa tu comentario ',
    generic: {
      submit: 'Enviar ',
      word: 'palabras ',
      send: 'Enviando',
      audioRecorder: 'Grabadora de audio ',
      audioFile:'Archivo de audio ',
      videoRecorder:'Grabadora de vídeo ',
      videoFile:'Archivo de vídeo ',
      uploadText:'Cargue un archivo o arrastre y suelte ',
      uploadAudio:'Cargar Audio ',
      uploadVideo:'Cargar video ',
      uploading: 'Cargando',
      startRecording: 'Empezar a grabar ',
      stopRecording: 'Parar grabación ',
      saveRecording: 'Guardar grabación ',
      cancelRecording: 'Cancelar grabación ',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation: 'Por favor ingresa tu comentario antes de continuar. Gracias. ',
      errorMessage: 'Algo salió mal. Por favor intenta nuevamente. ',
      successMessage: 'Comentarios enviados con éxito '
    }
  },
  pricingModule: {
    header: 'Precios ',
    subheader: 'Elije el mejor plan para tus necesidades ',
    supportingText:
      'Nuestro modelo de precios es flexible e innovador, ya que el 80 % de los honorarios van directamente a los traductores. Ya sea que sea una ONG rural, una pequeña empresa o un gran proveedor de atención médica, tenemos un plan que se adapta a sus necesidades. ¿Necesitas más información? Contáctenos aquí',
    module: {
      title: 'Modelo de empresa social',
      descriptionLineOne:
        'FeedbackNow traduce los comentarios de sus clientes a través de traductores certificados por NAATI. No tiene que preocuparse por traducir los comentarios de sus clientes. Nos envió comentarios en cualquier idioma y haremos la traducción por usted.',
      descriptionLineTwo:
        'También generará ahorros de costos y eficiencias para su organización.'
    }
  },
  footer: {
    gridOne: {
      title: 'Ayuda y soporte técnico',
      listOne: 'Precios ',
      listTwo: 'Documentación ',
      listThree: 'Contacto '
    },
    gridTwo: {
      title: 'COMPAÑÍA ',
      listOne: 'Sobre nosotros ',
      listTwo: 'Blog '
    },
    gridThree: {
      title: 'LEGAL',
      listOne: 'Privacidad y política',
      listTwo: 'Términos y condiciones'
    },
    gridFour: {
      title: 'SUSCRÍBETE A NUESTRO BOLETÍN ',
      listOne:
        'Las últimas noticias, artículos y recursos enviados semanalmente a tu bandeja de entrada. ',
      placeHolder: 'Ingrese su email ',
      button: 'Suscríbete '
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. Todos los derechos reservados.  '
    }
  },
  aboutUsModule: {
    title: 'Sobre nosotros ',
    description:
      'No importa cuánto avance la tecnología, tener soluciones lingüísticas precisas todavía requiere de experiencia humana. Los traductores profesionales siempre serán un vínculo vital para conectar los proveedores de servicios con las comunidades multiculturales de Australia. Durante los últimos 20 años, Polaron ha ofrecido servicios de idiomas que ponen a nuestros clientes en el centro de todo lo que hacemos... '
  },
  contactUsModule: {
    title: 'Contacto',
    header: '¿Cómo podemos ayudarte? ',
    supportingText:
      'Explique cuál es el problema. Nuestro objetivo es responder en un plazo de 24 horas. Si tu problema es urgente, llama al 1300 88 55 61. ',
    fieldOneLabel: 'Tu nombre ',
    fieldOnePlaceholder: 'Ejemplo: John Doe',
    fieldTwoLabel: 'Tu correo electrónico ',
    fieldTwoPlaceholder: 'Ejemplo: john@feedbacknow.com',
    fieldThreeLabel: 'Tu problema o comentario ',
    fieldThreePlaceholder: 'Entre aquí',
    button: 'Enviar '
  },
  userInfoForm: {
    title: 'Información de usuario ',
    header: 'Guardar comentario ',
    public: 'Público',
    private: 'Privado',
    fieldOneLabel: 'Primer nombre ',
    fieldOnePlaceholder: 'Ejemplo: John',
    fieldTwoLabel: 'Apellido',
    fieldTwoPlaceholder: 'Ejemplo: Doe',
    fieldThreeLabel: 'correo electrónico',
    fieldThreePlaceholder: 'Ejemplo: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'Información de usuario requerida '
    }
  }
};
