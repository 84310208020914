export enum ActionType {
  /********************************************/
  /*                                            */
  /* GET ALL ASSIGNED FEEDBACK LIST ACTION TYPE */
  /*                                            */
  /**********************************************/
  GET_ALL_ASSIGNED_FEEDBACK_LIST_REQUEST = 'get_all_assigned_feedback_list_request',
  GET_ALL_ASSIGNED_FEEDBACK_LIST_SUCCESS = 'get_all_assigned_feedback_list_success',
  GET_ALL_ASSIGNED_FEEDBACK_LIST_ERROR = 'get_all_assigned_feedback_list_error',

  /********************************************/
  /*                                          */
  /* GET FEEDBACK DETAIL ACTION TYPE */
  /*                                          */
  /********************************************/
  GET_FEEDBACK_DETAIL_REQUEST = 'get_feedback_detail_request',
  GET_FEEDBACK_DETAIL_SUCCESS = 'get_feedback_detail_success',
  GET_FEEDBACK_DETAIL_ERROR = 'get_feedback_detail_error',

  /********************************************/
  /*                                          */
  /* UPDATE SHOW FEEDBACK ON WEBSITE ACTION TYPE */
  /*                                          */
  /********************************************/
  UPDATE_SHOW_FEEDBACK_ON_WEBSITE_REQUEST = 'update_show_feedback_on_website_request',
  UPDATE_SHOW_FEEDBACK_ON_WEBSITE_SUCCESS = 'update_show_feedback_on_website_success',
  UPDATE_SHOW_FEEDBACK_ON_WEBSITE_ERROR = 'update_show_feedback_on_website_error',

}
