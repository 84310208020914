export const TRANSLATIONS_EN = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'Home',
      about: 'About',
      support: 'Support',
      pricing: 'Pricing',
      signIn: 'Sign in',
      signUp: 'Sign Up'
    },
    // banner: {
    //   header: 'Feedback Management System',
    //   subHeader:
    //     'Powered by Polaron, Feedback Now is an easy to use, secure and remarkably cost-effective way to expand your reach and take the pulse of your audiences',
    //   supportingText:
    //     'With Feedback Now, you can give your customers voice in any language, any format, any time. ',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      // header: 'Tell us … in your language',
      header: 'Have your say … in your language',
      subHeader:
        'Did you know that across our communities we speak over 270 languages, represent 300 ancestries and practice 100 religions?',
      supportingText1:
        'FeedbackNow is an easy-to-use, secure and cost-effective way',
      supportingText2: 'to expand your reach and take the pulse of your audiences in 50+ languages.',
      generic: {
        getStarted: 'Give feedback now'
      }
    },
    about: {
      header: 'Tell us … in your language!',
      supportingText:
        'Did you know that across our communities we speak over 270 languages, represent 300 ancestries and practice 100 religions?',
      boxOne: 'Completion',
      boxTwo: 'Support',
      boxThree: 'Translation'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'How it works for clients',
        step_one: 'Sign up as a client',
        step_two: 'Get your API key',
        step_three: 'Send your feedbacks in any language along with API key',
        step_four: 'Get Translated Feedback'
      },
      translator: {
        header: 'How it works for translators',
        step_one: 'Sign up as a translator',
        step_two: 'Upload your NAATI certificates and get verified',
        step_three: 'Translate assigned feedback',
        step_four: 'Get paid'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'Select how you want to give your feedback',
    enterText: 'Enter your feedback',
    generic: {
      submit: 'Submit',
      word: 'words',
      send: 'Send',
      audioRecorder: 'Audio recorder',
      audioFile:'Audio file',
      videoRecorder:'Video recorder',
      videoFile:'Video file',
      uploadText:'Upload a file or drag and drop',
      uploadAudio:'Upload audio',
      uploadVideo:'Upload video',
      uploading: 'Uploading',
      startRecording: 'Start recording',
      stopRecording: 'Stop recording',
      saveRecording: 'Save recording',
      cancelRecording: 'Cancel recording',
      acceptRecording: 'Accept recording',
      processing: 'We are processing your feedback.',
      startingIn: 'Starting in',
      recordingNow: 'Recording now',
      recordingDeleted: 'Recording deleted'
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'Please enter your feedback before proceeding. Thank you.',
      errorMessage: 'Something went wrong. Please try again.',
      successMessage: 'Feedback added succesfully'
    },
    content: {
      completion: 'Completion',
      support: 'Support',
      translation: 'Translation'
    }
  },
  pricingModule: {
    header: 'Pricing',
    subheader: 'Choose the best plan for you',
    supportingText:
      'Our pricing model is flexible and innovative, with 80% of fees going directly to the translators. Whether you are a rural NGO, small business or a large health care provider, we have a plan to suit your needs. Need more information? Contact us here',
    module: {
      title: 'Social Enterprise Model',
      descriptionLineOne:
        'FeedbackNow translates your customer feedback via NAATI-certified translators. You do not have to worry about translating your customer feedback. You sent us feedback in any language and we will do the translation for you.',
      descriptionLineTwo:
        'You will also be creating cost savings and efficiencies for your organization.'
    }
  },
  footer: {
    gridOne: {
      title: 'SUPPORT',
      listOne: 'Pricing',
      listTwo: 'Documentation',
      listThree: 'Contact Us'
    },
    gridTwo: {
      title: 'COMPANY',
      listOne: 'About',
      listTwo: 'Blog'
    },
    gridThree: {
      title: 'LEGAL',
      listOne: 'Privacy and Policy',
      listTwo: 'Terms and Conditions'
    },
    gridFour: {
      title: 'SUBSCRIBE TO OUR NEWSLETTER',
      listOne:
        'The latest news, articles and resources, sent to your inbox weekly.',
      placeHolder: 'Enter email',
      button: 'Subscribe'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. All rights reserved.'
    }
  },
  aboutUsModule: {
    title: 'About Us',
    description:
      'No matter how far technology advances, accurate language solutions still require human expertise. Translators will always be a vital link between service providers and Australia’s multicultural communities. Over the last 20 years, Polaron’s language services has been putting our customers at the centre of everything we do..'
  },
  contactUsModule: {
    title: 'Contact Us',
    header: 'How can we help?',
    supportingText:
      'Please explain what the issue is below. We aim to respond within 24 hours. If your issue is urgent, please call 1300 88 55 61.',
    fieldOneLabel: 'Your Name',
    fieldOnePlaceholder: 'Example: John Doe',
    fieldTwoLabel: 'Your Email',
    fieldTwoPlaceholder: 'Example: john@feedbacknow.com',
    fieldThreeLabel: 'Your Issue or Feedback',
    fieldThreePlaceholder: 'Enter here',
    button: 'Submit'
  },
  userInfoForm: {
    title: 'User info',
    header: 'Keep it',
    public: 'Public',
    private: 'Private',
    fieldOneLabel: 'First Name',
    fieldOnePlaceholder: 'Example: John',
    fieldTwoLabel: 'Last Name',
    fieldTwoPlaceholder: 'Example: Doe',
    fieldThreeLabel: 'Email',
    fieldThreePlaceholder: 'Example: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'User Info Required'
    }
  }
};
