import { Dispatch } from 'redux';

import { ActionType } from '../action-types';
import {
  Action,
  AssignFeedbackTranslationTaskAction,
  GetAllActiveTranslatorAction,
  GetFeedbackDetailAction,
  ReviewTranslatedFeedbackAction,
  ResetFeedbackModuleStateAction,
  GetAllActiveLanguageAction,
  GetAllActiveClientAction
} from '../actions';
import { fbnApi as API } from '../../../../../../../utils/api-handler';

/***************************************/
/*                                     */
/* RESET FEEDBACK MODULE STATE CREATOR */
/*                                     */
/***************************************/

export const resetFeedbackModuleState = () => {
  return async (dispatch: Dispatch<ResetFeedbackModuleStateAction>) => {
    dispatch({
      type: ActionType.RESET_FEEDBACK_MODULE_STATE
    });
  };
};

/*************************************/
/*                                   */
/* GET ALL FEEDBACK LIST API CREATOR */
/*                                   */
/*************************************/

type FilterOptionValueType = {
  [translator: string]: string;
  status: string;
  language: string;
  from: string;
  to: string;
};

type GetAllFeedbackListRequestDataType = {
  userId: string;
  token: string;
  page: number;
  limit: number;
  filterOptionValue: FilterOptionValueType;
};

export const getAllFeedbackList = ({
  userId,
  token,
  page,
  limit,
  filterOptionValue
}: GetAllFeedbackListRequestDataType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.GET_ALL_FEEDBACK_LIST_REQUEST
    });

    let filterOptionQueryParams = '';

    if (filterOptionValue) {
      Object.keys(filterOptionValue).map(each => {
        if (each !== '') {
          filterOptionQueryParams = `${filterOptionQueryParams}&${each}=${filterOptionValue[each]}`;
        }
        return null;
      });
    }

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/feedbacks/${userId}?page=${page}&perpage=${limit}${filterOptionQueryParams}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_ALL_FEEDBACK_LIST_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_FEEDBACK_LIST_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later'
        }
      });
    }
  };
};

/************************************************/
/*                                              */
/* ASSIGN FEEDBACK TRANSLATION TASK API CREATOR */
/*                                              */
/************************************************/

type AssignFeedbackTranslationTaskRequestDataType = {
  token: string;
  userId: string;
  feedbackId: string;
  translatorId: string;
  deadline?: Date | null;
};

export const assignFeedbackTranslationTask = ({
  token,
  userId,
  deadline,
  feedbackId,
  translatorId
}: AssignFeedbackTranslationTaskRequestDataType) => {
  return async (dispatch: Dispatch<AssignFeedbackTranslationTaskAction>) => {
    dispatch({
      type: ActionType.ASSIGN_FEEDBACK_TRANSLATION_TASK_REQUEST
    });

    try {
      const response = await API(
        {
          data: {
            translator: translatorId,
            deadline
          },
          method: 'PUT',
          url: `/feedback/${feedbackId}/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.ASSIGN_FEEDBACK_TRANSLATION_TASK_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.ASSIGN_FEEDBACK_TRANSLATION_TASK_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later'
        }
      });
    }
  };
};

/*****************************************/
/*                                       */
/* GET ALL ACTIVE TRANSLATOR API CREATOR */
/*                                       */
/*****************************************/

type GetAllActiveTranslatorRequestDataType = {
  token: string;
  userId: string;
};

export const getAllActiveTranslator = ({
  userId,
  token
}: GetAllActiveTranslatorRequestDataType) => {
  return async (dispatch: Dispatch<GetAllActiveTranslatorAction>) => {
    dispatch({
      type: ActionType.GET_ALL_ACTIVE_TRANSLATOR_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/admin/active-translators/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_ALL_ACTIVE_TRANSLATOR_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_ACTIVE_TRANSLATOR_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/*************************************/
/*                                   */
/* GET ALL ACTIVE CLIENT API CREATOR */
/*                                   */
/*************************************/

type GetAllActiveClientRequestDataType = {
  token: string;
  userId: string;
};

export const getAllActiveClient = ({
  userId,
  token
}: GetAllActiveClientRequestDataType) => {
  return async (dispatch: Dispatch<GetAllActiveClientAction>) => {
    dispatch({
      type: ActionType.GET_ALL_ACTIVE_CLIENT_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/admin/active-clients/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_ALL_ACTIVE_CLIENT_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_ACTIVE_CLIENT_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/***************************************/
/*                                     */
/* GET ALL ACTIVE LANGUAGE API CREATOR */
/*                                     */
/***************************************/

type GetAllActiveLanguageRequestDataType = {};

export const getAllActiveLanguage = () => {
  return async (dispatch: Dispatch<GetAllActiveLanguageAction>) => {
    dispatch({
      type: ActionType.GET_ALL_ACTIVE_LANGUAGE_REQUEST
    });

    try {
      const response = await API({
        data: '',
        method: 'GET',
        url: `/languages`
      });
      dispatch({
        type: ActionType.GET_ALL_ACTIVE_LANGUAGE_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_ACTIVE_LANGUAGE_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later'
        }
      });
    }
  };
};

/***********************************/
/*                                 */
/* GET FEEDBACK DETAIL API CREATOR */
/*                                 */
/***********************************/

type GetFeedbackDetailRequestDataType = {
  token: string;
  userId: string;
  feedbackId: string;
};

export const getFeedbackDetail = ({
  token,
  userId,
  feedbackId
}: GetFeedbackDetailRequestDataType) => {
  return async (dispatch: Dispatch<GetFeedbackDetailAction>) => {
    dispatch({
      type: ActionType.GET_FEEDBACK_DETAIL_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/feedback/${feedbackId}/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_FEEDBACK_DETAIL_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_FEEDBACK_DETAIL_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/*******************************************/
/*                                         */
/* APPROVE TRANSLATED FEEDBACK API CREATOR */
/*                                         */
/*******************************************/

type ReviewTranslatedFeedbackRequestDataType = {
  token: string;
  userId: string;
  feedbackId: string;
};

export const reviewTranslatedFeedback = ({
  userId,
  feedbackId,
  token
}: ReviewTranslatedFeedbackRequestDataType) => {
  return async (dispatch: Dispatch<ReviewTranslatedFeedbackAction>) => {
    dispatch({
      type: ActionType.REVIEW_TRANSLATED_FEEDBACK_REQUEST
    });
    try {
      const response = await API(
        {
          data: {
            status: 'completed'
          },
          method: 'PUT',
          url: `feedback/${feedbackId}/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.REVIEW_TRANSLATED_FEEDBACK_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.REVIEW_TRANSLATED_FEEDBACK_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};
