import { ActionType } from '../action-types';
import { GetAllClientNotificationAction } from '../actions';

interface IClientNotificationsState {
  getAllClientNotificationsRequesting: boolean;
  getAllClientNotificationsResponse: any | [];
  getAllClientNotificationsSuccess: boolean;
  getAllClientNotificationsSuccessMsg: string | '';
  getAllClientNotificationsError: boolean;
  getAllClientNotificationsErrorMsg: string | '';
  getAllClientNotificationsResponseStatusCode: number | null;
}

const initialState = {
  getAllClientNotificationsRequesting: false,
  getAllClientNotificationsResponse: [],
  getAllClientNotificationsSuccess: false,
  getAllClientNotificationsSuccessMsg: '',
  getAllClientNotificationsError: false,
  getAllClientNotificationsErrorMsg: '',
  getAllClientNotificationsResponseStatusCode: null
};

const reducer = (
  state: IClientNotificationsState = initialState,
  action: GetAllClientNotificationAction
): IClientNotificationsState => {
  switch (action.type) {
    case ActionType.GET_ALL_CLIENT_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        getAllClientNotificationsRequesting: true,
        getAllClientNotificationsResponse: [],
        getAllClientNotificationsSuccess: false,
        getAllClientNotificationsSuccessMsg: '',
        getAllClientNotificationsError: false,
        getAllClientNotificationsErrorMsg: '',
        getAllClientNotificationsResponseStatusCode: null
      };
    case ActionType.GET_ALL_CLIENT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getAllClientNotificationsRequesting: false,
        getAllClientNotificationsResponse: action.payload.data,
        getAllClientNotificationsSuccess: true,
        getAllClientNotificationsSuccessMsg: action.payload.message,
        getAllClientNotificationsResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_ALL_CLIENT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        getAllClientNotificationsRequesting: false,
        getAllClientNotificationsResponse: action.payload.data,
        getAllClientNotificationsError: true,
        getAllClientNotificationsErrorMsg: action.payload.message,
        getAllClientNotificationsResponseStatusCode: action.payload.statusCode
      };
    default:
      return state;
  }
};

export default reducer;
