import { combineReducers } from 'redux';
import loginReducer from '../container/module/public/login/reducers';
import registerReducer from '../container/module/public/register/reducers';
import protectedLanguageReducer from '../container/module/protected/language/reducers';
import protectedEmailVerificationReducer from '../container/module/protected/email-verification/reducers';
import entryFeedbackReducer from '../container/module/protected/entry-feedback/reducers';
import adminHomeReducer from '../container/module/protected/dashboard/admin/home/reducers';
import adminNotificationsReducer from '../container/module/protected/dashboard/admin/notifications/reducers';
import adminFeedbackReducer from '../container/module/protected/dashboard/admin/feedback/reducers';
import translatorFeedbackReducer from '../container/module/protected/dashboard/translator/feedback/reducers';
import translatorNotificationReducer from '../container/module/protected/dashboard/translator/notifications/reducers';
import clientFeedbackReducer from '../container/module/protected/dashboard/client/feedback/reducers';
import clientNotificationReducer from '../container/module/protected/dashboard/client/notifications/reducers';
import adminTranslatorReducer from '../container/module/protected/dashboard/admin/translator/reducers';
import adminClientReducer from '../container/module/protected/dashboard/admin/client/reducers';
import translatorDocumentInfoReducer from '../container/module/protected/dashboard/translator/settings/general/document-info/reducers';
import adminLanguageReducer from '../container/module/protected/dashboard/admin/language/reducers';

import authReducer from './cache/auth-info/reducers';
import localeReducer from './cache/locale/reducers';

const reducers = combineReducers({
  auth: authReducer,
  login: loginReducer,
  register: registerReducer,
  adminHome: adminHomeReducer,
  adminFeedback: adminFeedbackReducer,
  entryFeedback: entryFeedbackReducer,
  protectedLanguage: protectedLanguageReducer,
  protectedEmailVerification: protectedEmailVerificationReducer,
  translatorFeedback: translatorFeedbackReducer,
  adminTranslator: adminTranslatorReducer,
  adminClient: adminClientReducer,
  translatorDocumentInfo: translatorDocumentInfoReducer,
  translatorNotifications: translatorNotificationReducer,
  clientFeedback: clientFeedbackReducer,
  clientNotifications: clientNotificationReducer,
  adminLanguage: adminLanguageReducer,
  adminNotifications: adminNotificationsReducer,
  locale: localeReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
