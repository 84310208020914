import { ActionType } from '../action-types';
import { Action } from '../actions';

type remarksObj = {
  email: string;
  document: string;
};

type UserInfoObjType = {
  _id: string;
  role: string;
  email: string;
  lastName: string;
  firstName: string;
  remarks: remarksObj;
  isEmailVerified: boolean;
  document: any[];
  secretKey: string;
};

type ResponseObjDataType = {
  token: string;
  user: UserInfoObjType;
};

interface IUserInfoState {
  userInfo: ResponseObjDataType | null;
}

const initialState = {
  userInfo: null
};

const reducer = (
  state: IUserInfoState = initialState,
  action: Action
): IUserInfoState => {
  switch (action.type) {
    case ActionType.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };
    case ActionType.CLEAR_USER_INFO:
      return {
        ...state,
        userInfo: null
      };
    default:
      return state;
  }
};

export default reducer;
