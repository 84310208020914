import { ActionType } from '../action-types';
import { Action } from '../actions';

interface ILocaleState {
  localeCode: string;
}

const initialState = {
  localeCode: 'us'
};

const reducer = (
  state: ILocaleState = initialState,
  action: Action
): ILocaleState => {
  switch (action.type) {
    case ActionType.SET_LOCALE:
      return {
        ...state,
        localeCode: action.payload.localeCode
      };
    default:
      return state;
  }
};

export default reducer;
