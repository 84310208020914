import { ActionType } from '../action-types';
import {
  GetAllAssignedFeedbackListAction,
  GetFeedbackDetailAction,
  UpdateShowFeedbackOnWebsiteAction
} from '../actions';

interface IClientFeedbackState {
  getAllAssignedFeedbackListRequesting: boolean;
  getAllAssignedFeedbackListResponse: any | [];
  getAllAssignedFeedbackListSuccess: boolean;
  getAllAssignedFeedbackListSuccessMsg: string | '';
  getAllAssignedFeedbackListError: boolean;
  getAllAssignedFeedbackListErrorMsg: string | '';
  getAllAssignedFeedbackListResponseStatusCode: number | null;

  getFeedbackDetailRequesting: boolean;
  getFeedbackDetailResponse: any | [];
  getFeedbackDetailSuccess: boolean;
  getFeedbackDetailSuccessMsg: string | '';
  getFeedbackDetailError: boolean;
  getFeedbackDetailErrorMsg: string | '';
  getFeedbackDetailResponseStatusCode: number | null;

  updateShowFeedbackOnWebsiteRequesting: boolean;
  updateShowFeedbackOnWebsiteResponse: any | [];
  updateShowFeedbackOnWebsiteSuccess: boolean;
  updateShowFeedbackOnWebsiteSuccessMsg: string | '';
  updateShowFeedbackOnWebsiteError: boolean;
  updateShowFeedbackOnWebsiteErrorMsg: string | '';
  updateShowFeedbackOnWebsiteResponseStatusCode: number | null;

}

const initialState = {
  getAllAssignedFeedbackListRequesting: false,
  getAllAssignedFeedbackListResponse: [],
  getAllAssignedFeedbackListSuccess: false,
  getAllAssignedFeedbackListSuccessMsg: '',
  getAllAssignedFeedbackListError: false,
  getAllAssignedFeedbackListErrorMsg: '',
  getAllAssignedFeedbackListResponseStatusCode: null,

  getFeedbackDetailRequesting: false,
  getFeedbackDetailResponse: [],
  getFeedbackDetailSuccess: false,
  getFeedbackDetailSuccessMsg: '',
  getFeedbackDetailError: false,
  getFeedbackDetailErrorMsg: '',
  getFeedbackDetailResponseStatusCode: null,

  updateShowFeedbackOnWebsiteRequesting: false,
  updateShowFeedbackOnWebsiteResponse: [],
  updateShowFeedbackOnWebsiteSuccess: false,
  updateShowFeedbackOnWebsiteSuccessMsg: '',
  updateShowFeedbackOnWebsiteError: false,
  updateShowFeedbackOnWebsiteErrorMsg: '',
  updateShowFeedbackOnWebsiteResponseStatusCode: null,

};

const reducer = (
  state: IClientFeedbackState = initialState,
  action:
    | GetAllAssignedFeedbackListAction
    | GetFeedbackDetailAction
    | UpdateShowFeedbackOnWebsiteAction
): IClientFeedbackState => {
  switch (action.type) {
    case ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_REQUEST:
      return {
        ...state,
        getAllAssignedFeedbackListRequesting: true,
        getAllAssignedFeedbackListResponse: [],
        getAllAssignedFeedbackListSuccess: false,
        getAllAssignedFeedbackListSuccessMsg: '',
        getAllAssignedFeedbackListError: false,
        getAllAssignedFeedbackListErrorMsg: '',
        getAllAssignedFeedbackListResponseStatusCode: null
      };
    case ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        getAllAssignedFeedbackListRequesting: false,
        getAllAssignedFeedbackListResponse: action.payload.data,
        getAllAssignedFeedbackListSuccess: true,
        getAllAssignedFeedbackListSuccessMsg: action.payload.message,
        getAllAssignedFeedbackListResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_ERROR:
      return {
        ...state,
        getAllAssignedFeedbackListRequesting: false,
        getAllAssignedFeedbackListResponse: action.payload.data,
        getAllAssignedFeedbackListError: true,
        getAllAssignedFeedbackListErrorMsg: action.payload.message,
        getAllAssignedFeedbackListResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_FEEDBACK_DETAIL_REQUEST:
      return {
        ...state,
        getFeedbackDetailRequesting: true,
        getFeedbackDetailResponse: [],
        getFeedbackDetailSuccess: false,
        getFeedbackDetailSuccessMsg: '',
        getFeedbackDetailError: false,
        getFeedbackDetailErrorMsg: '',
        getFeedbackDetailResponseStatusCode: null
      };
    case ActionType.GET_FEEDBACK_DETAIL_SUCCESS:
      return {
        ...state,
        getFeedbackDetailRequesting: false,
        getFeedbackDetailResponse: action.payload.data,
        getFeedbackDetailSuccess: true,
        getFeedbackDetailSuccessMsg: action.payload.message,
        getFeedbackDetailResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_FEEDBACK_DETAIL_ERROR:
      return {
        ...state,
        getFeedbackDetailRequesting: false,
        getFeedbackDetailResponse: action.payload.data,
        getFeedbackDetailError: true,
        getFeedbackDetailErrorMsg: action.payload.message,
        getFeedbackDetailResponseStatusCode: action.payload.statusCode
      };

    case ActionType.UPDATE_SHOW_FEEDBACK_ON_WEBSITE_REQUEST:
      return {
        ...state,
        updateShowFeedbackOnWebsiteRequesting: true,
        updateShowFeedbackOnWebsiteResponse: [],
        updateShowFeedbackOnWebsiteSuccess: false,
        updateShowFeedbackOnWebsiteSuccessMsg: '',
        updateShowFeedbackOnWebsiteError: false,
        updateShowFeedbackOnWebsiteErrorMsg: '',
        updateShowFeedbackOnWebsiteResponseStatusCode: null
      };
    case ActionType.UPDATE_SHOW_FEEDBACK_ON_WEBSITE_SUCCESS:
      return {
        ...state,
        updateShowFeedbackOnWebsiteRequesting: false,
        updateShowFeedbackOnWebsiteResponse: action.payload.data,
        updateShowFeedbackOnWebsiteSuccess: true,
        updateShowFeedbackOnWebsiteSuccessMsg: action.payload.message,
        updateShowFeedbackOnWebsiteResponseStatusCode: action.payload.statusCode
      };

    case ActionType.UPDATE_SHOW_FEEDBACK_ON_WEBSITE_ERROR:
      return {
        ...state,
        updateShowFeedbackOnWebsiteRequesting: false,
        updateShowFeedbackOnWebsiteResponse: action.payload.data,
        updateShowFeedbackOnWebsiteError: true,
        updateShowFeedbackOnWebsiteErrorMsg: action.payload.message,
        updateShowFeedbackOnWebsiteResponseStatusCode: action.payload.statusCode
      };
      
    default:
      return state;
  }
};

export default reducer;
