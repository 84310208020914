import { ActionType } from '../action-types';
import { Action } from '../actions';

interface IEntryFeedbackState {
  sendFeedbackRequesting: boolean;
  sendFeedbackResponse: any[] | [];
  sendFeedbackSuccess: boolean;
  sendFeedbackSuccessMsg: string | '';
  sendFeedbackError: boolean;
  sendFeedbackErrorMsg: string | '';
  sendFeedbackResponseStatusCode: number | null;

  sendAudioVideoFeedbackRequesting: boolean;
  sendAudioVideoFeedbackResponse: any[] | [];
  sendAudioVideoFeedbackSuccess: boolean;
  sendAudioVideoFeedbackSuccessMsg: string | '';
  sendAudioVideoFeedbackError: boolean;
  sendAudioVideoFeedbackErrorMsg: string | '';
  sendAudioVideoFeedbackResponseStatusCode: number | null;
}

const initialState = {
  sendFeedbackRequesting: false,
  sendFeedbackResponse: [],
  sendFeedbackSuccess: false,
  sendFeedbackSuccessMsg: '',
  sendFeedbackError: false,
  sendFeedbackErrorMsg: '',
  sendFeedbackResponseStatusCode: null,

  sendAudioVideoFeedbackRequesting: false,
  sendAudioVideoFeedbackResponse: [],
  sendAudioVideoFeedbackSuccess: false,
  sendAudioVideoFeedbackSuccessMsg: '',
  sendAudioVideoFeedbackError: false,
  sendAudioVideoFeedbackErrorMsg: '',
  sendAudioVideoFeedbackResponseStatusCode: null
};

const reducer = (
  state: IEntryFeedbackState = initialState,
  action: Action
): IEntryFeedbackState => {
  switch (action.type) {
    case ActionType.SEND_FEEDBACK_REQUEST:
      return {
        ...state,
        sendFeedbackRequesting: true,
        sendFeedbackResponse: [],
        sendFeedbackSuccess: false,
        sendFeedbackSuccessMsg: '',
        sendFeedbackError: false,
        sendFeedbackErrorMsg: '',
        sendFeedbackResponseStatusCode: null
      };
    case ActionType.SEND_FEEDBACK_SUCCESS:
      return {
        ...state,
        sendFeedbackRequesting: false,
        sendFeedbackResponse: action.payload.data,
        sendFeedbackSuccess: true,
        sendFeedbackSuccessMsg: action.payload.message,
        sendFeedbackResponseStatusCode: action.payload.statusCode
      };
    case ActionType.SEND_FEEDBACK_ERROR:
      return {
        ...state,
        sendFeedbackRequesting: false,
        sendFeedbackResponse: action.payload.data,
        sendFeedbackError: true,
        sendFeedbackErrorMsg: action.payload.message,
        sendFeedbackResponseStatusCode: action.payload.statusCode
      };

    case ActionType.SEND_AUDIO_VIDEO_FEEDBACK_REQUEST:
      return {
        ...state,
        sendAudioVideoFeedbackRequesting: true,
        sendAudioVideoFeedbackResponse: [],
        sendAudioVideoFeedbackSuccess: false,
        sendAudioVideoFeedbackSuccessMsg: '',
        sendAudioVideoFeedbackError: false,
        sendAudioVideoFeedbackErrorMsg: '',
        sendAudioVideoFeedbackResponseStatusCode: null
      };
    case ActionType.SEND_AUDIO_VIDEO_FEEDBACK_SUCCESS:
      return {
        ...state,
        sendAudioVideoFeedbackRequesting: false,
        sendAudioVideoFeedbackResponse: action.payload.data,
        sendAudioVideoFeedbackSuccess: true,
        sendAudioVideoFeedbackSuccessMsg: action.payload.message,
        sendAudioVideoFeedbackResponseStatusCode: action.payload.statusCode
      };
    case ActionType.SEND_AUDIO_VIDEO_FEEDBACK_FAILURE:
      return {
        ...state,
        sendAudioVideoFeedbackRequesting: false,
        sendAudioVideoFeedbackResponse: action.payload.data,
        sendAudioVideoFeedbackError: true,
        sendAudioVideoFeedbackErrorMsg: action.payload.message,
        sendAudioVideoFeedbackResponseStatusCode: action.payload.statusCode
      };

    case ActionType.CLEAR_SEND_FEEDBACK_PREVIOUS_STATE_REQUEST:
      return {
        ...state,
        sendFeedbackRequesting: false,
        sendFeedbackResponse: [],
        sendFeedbackSuccess: false,
        sendFeedbackSuccessMsg: '',
        sendFeedbackError: false,
        sendFeedbackErrorMsg: '',
        sendFeedbackResponseStatusCode: null,

        sendAudioVideoFeedbackRequesting: false,
        sendAudioVideoFeedbackResponse: [],
        sendAudioVideoFeedbackSuccess: false,
        sendAudioVideoFeedbackSuccessMsg: '',
        sendAudioVideoFeedbackError: false,
        sendAudioVideoFeedbackErrorMsg: '',
        sendAudioVideoFeedbackResponseStatusCode: null
      };
    default:
      return state;
  }
};

export default reducer;
