import { Dispatch } from 'redux';

import { Action } from '../actions';
import { ActionType } from '../action-types';
import { fbnApi as API } from '../../../../../utils/api-handler';

export const getLanguageList = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.GET_LANGUAGE_LIST_REQUEST
    });

    try {
      const response = await API({
        data: '',
        method: 'GET',
        url: `/languages`
      });
      dispatch({
        type: ActionType.GET_LANGUAGE_LIST_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_LANGUAGE_LIST_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};
