import { ActionType } from '../action-types';
import { GetAllAdminNotificationAction } from '../actions';

interface IAdminNotificationsState {
  getAllAdminNotificationsRequesting: boolean;
  getAllAdminNotificationsResponse: any | [];
  getAllAdminNotificationsSuccess: boolean;
  getAllAdminNotificationsSuccessMsg: string | '';
  getAllAdminNotificationsError: boolean;
  getAllAdminNotificationsErrorMsg: string | '';
  getAllAdminNotificationsResponseStatusCode: number | null;
}

const initialState = {
  getAllAdminNotificationsRequesting: false,
  getAllAdminNotificationsResponse: [],
  getAllAdminNotificationsSuccess: false,
  getAllAdminNotificationsSuccessMsg: '',
  getAllAdminNotificationsError: false,
  getAllAdminNotificationsErrorMsg: '',
  getAllAdminNotificationsResponseStatusCode: null
};

const reducer = (
  state: IAdminNotificationsState = initialState,
  action: GetAllAdminNotificationAction
): IAdminNotificationsState => {
  switch (action.type) {
    case ActionType.GET_ALL_ADMIN_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        getAllAdminNotificationsRequesting: true,
        getAllAdminNotificationsResponse: [],
        getAllAdminNotificationsSuccess: false,
        getAllAdminNotificationsSuccessMsg: '',
        getAllAdminNotificationsError: false,
        getAllAdminNotificationsErrorMsg: '',
        getAllAdminNotificationsResponseStatusCode: null
      };
    case ActionType.GET_ALL_ADMIN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getAllAdminNotificationsRequesting: false,
        getAllAdminNotificationsResponse: action.payload.data,
        getAllAdminNotificationsSuccess: true,
        getAllAdminNotificationsSuccessMsg: action.payload.message,
        getAllAdminNotificationsResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_ALL_ADMIN_NOTIFICATIONS_ERROR:
      return {
        ...state,
        getAllAdminNotificationsRequesting: false,
        getAllAdminNotificationsResponse: action.payload.data,
        getAllAdminNotificationsError: true,
        getAllAdminNotificationsErrorMsg: action.payload.message,
        getAllAdminNotificationsResponseStatusCode: action.payload.statusCode
      };
    default:
      return state;
  }
};

export default reducer;
