export const TRANSLATIONS_FR = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'Accueil',
      about: 'A propos',
      support: 'Support',
      pricing: 'Prix',
      signIn: "Se connecter",
      signUp: "S’inscrire"
    },
    // banner: {
    //   header: 'Système de gestion des commentaires',
    //   subHeader:
    //     "Propulsé par Polaron, Feedback Now est un moyen facile à utiliser, sécurisé et remarquablement rentable d'étendre votre portée et de prendre le pouls de votre public",
    //   supportingText:
    //     "Avec Feedback Now, vous pouvez donner la parole à vos clients dans n'importe quelle langue, n'importe quel format, à tout moment.",
    //   generic: {
    //     getStarted: "Commencer"
    //   }
    // },
    banner: {
      header: 'Dites-nous ... dans votre langue !',
      subHeader:
        "Saviez-vous que dans nos communautés, nous parlons plus de 270 langues, représentons 300 ancêtres et pratiquons 100 religions ?",
      supportingText1:
        "FeedbackNow est un moyen simple, fiable et avantageux d’augmenter",
      supportingText2: "votre présence et de connaitre l’avis de vos utilisateurs dans plus de 50 langues.",
      generic: {
        getStarted: "Donnez votre avis maintenant"
      }
    },
    about: {
      header: 'Dites-nous… dans votre langue !',
      supportingText:
        'Saviez-vous que dans nos communautés, nous parlons plus de 270 langues, représentons 300 ancêtres et pratiquons 100 religions ?',
      boxOne: 'Achèvement',
      boxTwo: 'Soutien',
      boxThree: 'Traduction'
    },
    support: {
      supportingText:
        'Ces statistiques suggèrent que la capture et la gestion des réponses dans différentes langues pourraient vous aider à collecter des données plus précises, à créer un engagement authentique et à améliorer la prise de décision.'
    },
    howItWorks: {
      client: {
        header: 'Comment ça marche pour les clients',
        step_one: 'S’abonner ',
        step_two: 'Obtenir votre clé API',
        step_three: "Envoyer votre retour dans n’importe quelle langue avec votre clé API",
        step_four: 'Obtenir la traduction du retour'
      },
      translator: {
        header: 'Comment ça marche pour les traducteurs',
        step_one: 'Inscription traducteur',
        step_two: 'Télécharger vos certificats NAATI pour valider votre inscription',
        step_three: 'Traduire le retour',
        step_four: 'Paiements'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'Choisissez la méthode',
    enterText: 'Saisissez votre retour',
    generic: {
      submit: 'Envoyer',
      word: 'mots',
      send: 'Envoyer',
      audioRecorder: 'Enregistreur audio',
      audioFile:'Fichier audio',
      videoRecorder:'Enregistreur vidéo',
      videoFile:'Fichier video',
      uploadText:'Télécharger un fichier ou glisser-déposer',
      uploadAudio:'Télécharger fichier audio',
      uploadVideo:'Télécharger video',
      uploading: 'Téléchargement',
      startRecording: 'Commencer l’enregistrement',
      stopRecording: 'Arrêter l’enregistrement',
      saveRecording: 'Sauvegarder l’enregistrement',
      cancelRecording: 'Annuler l’enregistrement',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'Veuillez saisir votre retour. Merci.',
      errorMessage: "Une erreur s’est produite. Veuillez recommencer.",
      successMessage: 'Votre retour est enregistré'
    },
    content: {
      completion: 'Achèvement',
      support: 'Soutien',
      translation: 'Traduction'
    }
  },
  pricingModule: {
    header: 'Prix',
    subheader: 'Choisissez votre plan',
    supportingText:
      "Notre modèle de tarification est flexible et innovant, 80 % des honoraires allant directement aux traducteurs. Que vous soyez une ONG rurale, une petite entreprise ou un grand fournisseur de soins de santé, nous avons un plan adapté à vos besoins. Besoin de plus d'informations? Contactez-nous ici",
    module: {
      title: "Modèle d'entreprise sociale",
      descriptionLineOne:
        "FeedbackNow traduit les commentaires de vos clients via des traducteurs certifiés NAATI. Vous n'avez pas à vous soucier de traduire les commentaires de vos clients. Vous nous avez envoyé des commentaires dans n'importe quelle langue et nous ferons la traduction pour vous.",
      descriptionLineTwo:
        "Vous créerez également des économies de coûts et des gains d'efficacité pour votre organisation."
    }
  },
  footer: {
    gridOne: {
      title: 'AIDE',
      listOne: 'Prix',
      listTwo: 'Documentation',
      listThree: 'Contactez-nous'
    },
    gridTwo: {
      title: 'SOCIETE',
      listOne: 'A propos',
      listTwo: 'Blog'
    },
    gridThree: {
      title: 'JURIDIQUE',
      listOne: 'Confidentialité et politique',
      listTwo: 'Termes et conditions'
    },
    gridFour: {
      title: 'S’ABONNER A NOTRE BULLETIN',
      listOne:
        'Recevez les dernières actualités, articles et ressources dans votre boîte mail',
      placeHolder: "Votre email",
      button: "S’abonner"
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. Tous droits réservés.'
    }
  },
  aboutUsModule: {
    title: 'A propos',
    description:
      "Malgré le progrès technologique, il y aura toujours un besoin d’expertise humaine dans les services de traductions. En effet, les traducteurs forment un lien important entre les prestataires de services et la communauté multiculturelle en Australie. Depuis 20 ans, les clients de Polaron sont au centre de toutes leurs activités."
  },
  contactUsModule: {
    title: 'Contactez-nous',
    header: 'Vous avez besoin d’aide?',
    supportingText:
      'Expliquez-nous votre problème ci-dessous. Nous vous répondrons dans les 24 heures. Si votre problème est urgent, appelez le 1300 88 55 61. ',
    fieldOneLabel: 'Votre nom',
    fieldOnePlaceholder: 'Exemple : John Doe',
    fieldTwoLabel: 'Votre email',
    fieldTwoPlaceholder: 'Exemple : john@feedbacknow.com',
    fieldThreeLabel: 'Votre retour/feedback',
    fieldThreePlaceholder: 'Entrer ici',
    button: 'Envoyer'
  },
  userInfoForm: {
    title: 'Données utilisateur',
    header: 'Garder',
    public: 'Public',
    private: 'Privé',
    fieldOneLabel: 'Prénom',
    fieldOnePlaceholder: 'Exemple: John',
    fieldTwoLabel: 'Nom de famille',
    fieldTwoPlaceholder: 'Exemple: Doe',
    fieldThreeLabel: 'Email',
    fieldThreePlaceholder: 'Exemple : bijay@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'Données utilisateur manquantes'
    }
  }
};
