export const saveItem = (key: string, value: any): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return;
  } catch (error) {
    console.error('Local Storage Error: ', error);
  }
};

export const retriveItem = (key: string): any => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error('Local Storage Error: ', error);
  }
};

export const removeItem = (key: string): void => {
  try {
    localStorage.removeItem(key);
    return;
  } catch (error) {
    console.error('Local Storage Error: ', error);
  }
};

export const clearAllItem = (): void => {
  try {
    localStorage.clear();
    return;
  } catch (error) {
    console.error('Local Storage Error: ', error);
  }
};
