export enum ActionType {
  /*************************************/
  /*                                   */
  /* GET ALL LANGUAGE LIST ACTION TYPE */
  /*                                   */
  /*************************************/

  GET_ALL_LANGUAGE_LIST_REQUEST = 'get_all_language_list_request',
  GET_ALL_LANGUAGE_LIST_SUCCESS = 'get_all_language_list_success',
  GET_ALL_LANGUAGE_LIST_ERROR = 'get_all_language_list_error',

  /***********************************/
  /*                                 */
  /* GET LANGUAGE DETAIL ACTION TYPE */
  /*                                 */
  /***********************************/

  GET_LANGUAGE_DETAIL_REQUEST = 'get_language_detail_request',
  GET_LANGUAGE_DETAIL_SUCCESS = 'get_language_detail_success',
  GET_LANGUAGE_DETAIL_ERROR = 'get_language_detail_error',

  /***********************************/
  /*                                 */
  /* DELETE LANGUAGE ACTION TYPE */
  /*                                 */
  /***********************************/

  DELETE_LANGUAGE_REQUEST = 'delete_language_request',
  DELETE_LANGUAGE_SUCCESS = 'delete_language_success',
  DELETE_LANGUAGE_ERROR = 'delete_language_error',

  /***********************************/
  /*                                 */
  /* ADD LANGUAGE ACTION TYPE */
  /*                                 */
  /***********************************/

  ADD_LANGUAGE_REQUEST = 'add_language_request',
  ADD_LANGUAGE_SUCCESS = 'add_language_success',
  ADD_LANGUAGE_ERROR = 'add_language_error',

  /***********************************/
  /*                                 */
  /* UPDATE LANGUAGE ACTION TYPE */
  /*                                 */
  /***********************************/

  UPDATE_LANGUAGE_REQUEST = 'update_language_request',
  UPDATE_LANGUAGE_SUCCESS = 'update_language_success',
  UPDATE_LANGUAGE_ERROR = 'update_language_error',

}
