import axios from 'axios';
import { Dispatch } from 'redux';

import { Action } from '../actions';
import { ActionType } from '../action-types';
import { fbnApi as API } from '../../../../../utils/api-handler';
import { IoReturnUpBackOutline } from 'react-icons/io5';

type SendFeedbackRequestDataType = {
  type: string;
  fileType: string;
  language: string;
  text: string;
  secretKey: string;
  email: string;
  firstName: string;
  lastName: string;
};

export const sendFeedback = ({
  type,
  fileType,
  language,
  text,
  secretKey,
  email,
  firstName,
  lastName
}: SendFeedbackRequestDataType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEND_FEEDBACK_REQUEST
    });

    try {
      const response = await API({
        data: {
          type,
          fileType,
          language,
          text,
          secretKey,
          email,
          firstName,
          lastName
        },
        method: 'POST',
        url: `/feedback/create`
      });

      dispatch({
        type: ActionType.SEND_FEEDBACK_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.SEND_FEEDBACK_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

type SendAudioVideoFeedbackRequestDataType = {
  type: string;
  fileType: string;
  language: string;
  file: any;
  secretKey: string;
  email: string;
  firstName: string;
  lastName: string;
};

export const sendAudioVideoFeedback = ({
  type,
  fileType,
  language,
  file,
  secretKey,
  email,
  firstName,
  lastName
}: SendAudioVideoFeedbackRequestDataType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEND_AUDIO_VIDEO_FEEDBACK_REQUEST
    });

    try {
      const formData = new FormData();

      formData.append('file', file);

      formData.append('type', type);
      formData.append('fileType', fileType);
      formData.append('language', language);
      formData.append('secretKey', secretKey);
      formData.append('email', email);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);

      await axios.post(
        `https://feedbacknow.services/api/feedback/create-av`,
        formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      dispatch({
        type: ActionType.SEND_AUDIO_VIDEO_FEEDBACK_SUCCESS,
        payload: {
          data: [],
          statusCode: 200,
          message: 'Audio/Video Submitted.'
        }
      });
    } catch (error) {
      dispatch({
        type: ActionType.SEND_AUDIO_VIDEO_FEEDBACK_FAILURE,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

export const clearSendFeedbackPreviousState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_SEND_FEEDBACK_PREVIOUS_STATE_REQUEST
    });
  };
};
