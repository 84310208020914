export enum ActionType {
  /*******************************/
  /*                             */
  /* UPLOAD DOCUMENT ACTION TYPE */
  /*                             */
  /*******************************/

  UPLOAD_DOCUMENT_REQUEST = 'upload_document_request',
  UPLOAD_DOCUMENT_SUCCESS = 'upload_document_success',
  UPLOAD_DOCUMENT_ERROR = 'upload_document_error',

  /*************************************/
  /*                                   */
  /* GET UPLOADED DOCUMENT ACTION TYPE */
  /*                                   */
  /*************************************/

  GET_UPLOADED_DOCUMENT_REQUEST = 'get_uploaded_document_request',
  GET_UPLOADED_DOCUMENT_SUCCESS = 'get_uploaded_document_success',
  GET_UPLOADED_DOCUMENT_ERROR = 'get_uploaded_document_error',

  /************************************/
  /*                                  */
  /* RESET PREVIOUS STATE ACTION TYPE */
  /*                                  */
  /************************************/

  RESET_TRANSLATOR_PROFILE_INFO_PREVIOUS_STATE = 'reset_translator_profile_info_previous_state'
}
