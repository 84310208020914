import { ActionType } from '../action-types';
import {
  Action,
  GetLanguageDetailAction,
  DeleteLanguageAction,
  AddLanguageAction,
} from '../actions';

interface ILanguageState {
  getAllLanguageListRequesting: boolean;
  getAllLanguageListResponse: any | [];
  getAllLanguageListSuccess: boolean;
  getAllLanguageListSuccessMsg: string | '';
  getAllLanguageListError: boolean;
  getAllLanguageListErrorMsg: string | '';
  getAllLanguageResponseStatusCode: number | null;

  getLanguageDetailRequesting: boolean;
  getLanguageDetailResponse: any | [];
  getLanguageDetailResponseStatusCode: number | null;
  getLanguageDetailSuccess: boolean;
  getLanguageDetailSuccessMsg: string | '';
  getLanguageDetailError: boolean;
  getLanguageDetailErrorMsg: string | '';

  deleteLanguageRequesting: boolean;
  deleteLanguageResponse: any | [];
  deleteLanguageResponseStatusCode: number | null;
  deleteLanguageSuccess: boolean;
  deleteLanguageSuccessMsg: string | '';
  deleteLanguageError: boolean;
  deleteLanguageErrorMsg: string | '';

  addLanguageRequesting: boolean;
  addLanguageResponse: any | [];
  addLanguageResponseStatusCode: number | null;
  addLanguageSuccess: boolean;
  addLanguageSuccessMsg: string | '';
  addLanguageError: boolean;
  addLanguageErrorMsg: string | '';
}

const initialState = {
  getAllLanguageListRequesting: false,
  getAllLanguageListResponse: [],
  getAllLanguageListSuccess: false,
  getAllLanguageListSuccessMsg: '',
  getAllLanguageListError: false,
  getAllLanguageListErrorMsg: '',
  getAllLanguageResponseStatusCode: null,

  getLanguageDetailRequesting: false,
  getLanguageDetailResponse: [],
  getLanguageDetailResponseStatusCode: null,
  getLanguageDetailSuccess: false,
  getLanguageDetailSuccessMsg: '',
  getLanguageDetailError: false,
  getLanguageDetailErrorMsg: '',
  
  deleteLanguageRequesting: false,
  deleteLanguageResponse: [],
  deleteLanguageResponseStatusCode: null,
  deleteLanguageSuccess: false,
  deleteLanguageSuccessMsg: '',
  deleteLanguageError: false,
  deleteLanguageErrorMsg: '',

  addLanguageRequesting: false,
  addLanguageResponse: [],
  addLanguageResponseStatusCode: null,
  addLanguageSuccess: false,
  addLanguageSuccessMsg: '',
  addLanguageError: false,
  addLanguageErrorMsg: '',
};

const reducer = (
  state: ILanguageState = initialState,
  action:
    | Action
    | GetLanguageDetailAction
    | DeleteLanguageAction
    | AddLanguageAction
): ILanguageState => {
  switch (action.type) {
    case ActionType.GET_ALL_LANGUAGE_LIST_REQUEST:
      return {
        ...state,
        getAllLanguageListRequesting: true,
        getAllLanguageListResponse: [],
        getAllLanguageListSuccess: false,
        getAllLanguageListSuccessMsg: '',
        getAllLanguageListError: false,
        getAllLanguageListErrorMsg: '',
        getAllLanguageResponseStatusCode: null
      };
    case ActionType.GET_ALL_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        getAllLanguageListRequesting: false,
        getAllLanguageListResponse: action.payload.data,
        getAllLanguageListSuccess: true,
        getAllLanguageListSuccessMsg: action.payload.message,
        getAllLanguageResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_ALL_LANGUAGE_LIST_ERROR:
      return {
        ...state,
        getAllLanguageListRequesting: false,
        getAllLanguageListResponse: action.payload.data,
        getAllLanguageListError: true,
        getAllLanguageListErrorMsg: action.payload.message,
        getAllLanguageResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_LANGUAGE_DETAIL_REQUEST:
      return {
        ...state,
        getLanguageDetailRequesting: true,
        getLanguageDetailResponse: [],
        getLanguageDetailSuccess: false,
        getLanguageDetailSuccessMsg: '',
        getLanguageDetailError: false,
        getLanguageDetailErrorMsg: '',
        getLanguageDetailResponseStatusCode: null
      };
    case ActionType.GET_LANGUAGE_DETAIL_SUCCESS:
      return {
        ...state,
        getLanguageDetailRequesting: false,
        getLanguageDetailResponse: action.payload.data,
        getLanguageDetailSuccess: true,
        getLanguageDetailSuccessMsg: action.payload.message,
        getLanguageDetailResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_LANGUAGE_DETAIL_ERROR:
      return {
        ...state,
        getLanguageDetailRequesting: false,
        getLanguageDetailResponse: action.payload.data,
        getLanguageDetailError: true,
        getLanguageDetailErrorMsg: action.payload.message,
        getLanguageDetailResponseStatusCode: action.payload.statusCode
      };

      case ActionType.DELETE_LANGUAGE_REQUEST:
        return {
          ...state,
          deleteLanguageRequesting: true,
          deleteLanguageResponse: [],
          deleteLanguageSuccess: false,
          deleteLanguageSuccessMsg: '',
          deleteLanguageError: false,
          deleteLanguageErrorMsg: '',
          deleteLanguageResponseStatusCode: null
        };
      case ActionType.DELETE_LANGUAGE_SUCCESS:
        return {
          ...state,
          deleteLanguageRequesting: false,
          deleteLanguageResponse: action.payload.data,
          deleteLanguageSuccess: true,
          deleteLanguageSuccessMsg: action.payload.message,
          deleteLanguageResponseStatusCode: action.payload.statusCode
        };

      case ActionType.DELETE_LANGUAGE_ERROR:
        return {
          ...state,
          deleteLanguageRequesting: false,
          deleteLanguageResponse: action.payload.data,
          deleteLanguageError: true,
          deleteLanguageErrorMsg: action.payload.message,
          deleteLanguageResponseStatusCode: action.payload.statusCode
        };
      case ActionType.ADD_LANGUAGE_REQUEST:
        return {
          ...state,
          addLanguageRequesting: true,
          addLanguageResponse: [],
          addLanguageSuccess: false,
          addLanguageSuccessMsg: '',
          addLanguageError: false,
          addLanguageErrorMsg: '',
          addLanguageResponseStatusCode: null
        };
      case ActionType.ADD_LANGUAGE_SUCCESS:
        return {
          ...state,
          addLanguageRequesting: false,
          addLanguageResponse: action.payload.data,
          addLanguageSuccess: true,
          addLanguageSuccessMsg: action.payload.message,
          addLanguageResponseStatusCode: action.payload.statusCode
        };
      case ActionType.ADD_LANGUAGE_ERROR:
        return {
          ...state,
          addLanguageRequesting: false,
          addLanguageResponse: action.payload.data,
          addLanguageError: true,
          addLanguageErrorMsg: action.payload.message,
          addLanguageResponseStatusCode: action.payload.statusCode
        };  
    default:
      return state;
  }
};

export default reducer;
