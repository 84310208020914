import { ActionType } from '../action-types';
import { GetAllTranslatorNotificationAction } from '../actions';

interface ITranslatorNotificationsState {
  getAllTranslatorNotificationsRequesting: boolean;
  getAllTranslatorNotificationsResponse: any | [];
  getAllTranslatorNotificationsSuccess: boolean;
  getAllTranslatorNotificationsSuccessMsg: string | '';
  getAllTranslatorNotificationsError: boolean;
  getAllTranslatorNotificationsErrorMsg: string | '';
  getAllTranslatorNotificationsResponseStatusCode: number | null;
}

const initialState = {
  getAllTranslatorNotificationsRequesting: false,
  getAllTranslatorNotificationsResponse: [],
  getAllTranslatorNotificationsSuccess: false,
  getAllTranslatorNotificationsSuccessMsg: '',
  getAllTranslatorNotificationsError: false,
  getAllTranslatorNotificationsErrorMsg: '',
  getAllTranslatorNotificationsResponseStatusCode: null
};

const reducer = (
  state: ITranslatorNotificationsState = initialState,
  action: GetAllTranslatorNotificationAction
): ITranslatorNotificationsState => {
  switch (action.type) {
    case ActionType.GET_ALL_TRANSLATOR_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        getAllTranslatorNotificationsRequesting: true,
        getAllTranslatorNotificationsResponse: [],
        getAllTranslatorNotificationsSuccess: false,
        getAllTranslatorNotificationsSuccessMsg: '',
        getAllTranslatorNotificationsError: false,
        getAllTranslatorNotificationsErrorMsg: '',
        getAllTranslatorNotificationsResponseStatusCode: null
      };
    case ActionType.GET_ALL_TRANSLATOR_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getAllTranslatorNotificationsRequesting: false,
        getAllTranslatorNotificationsResponse: action.payload.data,
        getAllTranslatorNotificationsSuccess: true,
        getAllTranslatorNotificationsSuccessMsg: action.payload.message,
        getAllTranslatorNotificationsResponseStatusCode:
          action.payload.statusCode
      };
    case ActionType.GET_ALL_TRANSLATOR_NOTIFICATIONS_ERROR:
      return {
        ...state,
        getAllTranslatorNotificationsRequesting: false,
        getAllTranslatorNotificationsResponse: action.payload.data,
        getAllTranslatorNotificationsError: true,
        getAllTranslatorNotificationsErrorMsg: action.payload.message,
        getAllTranslatorNotificationsResponseStatusCode:
          action.payload.statusCode
      };
    default:
      return state;
  }
};

export default reducer;
