import { ActionType } from '../action-types';
import { GetStatsCountDataAction } from '../actions';

interface IDashboardHomeState {
  getStatsCountDataRequesting: boolean;
  getStatsCountDataResponse: any | [];
  getStatsCountDataSuccess: boolean;
  getStatsCountDataSuccessMsg: string | '';
  getStatsCountDataError: boolean;
  getStatsCountDataErrorMsg: string | '';
  getStatsCountDataResponseStatusCode: number | null;
}

const initialState = {
  getStatsCountDataRequesting: false,
  getStatsCountDataResponse: [],
  getStatsCountDataSuccess: false,
  getStatsCountDataSuccessMsg: '',
  getStatsCountDataError: false,
  getStatsCountDataErrorMsg: '',
  getStatsCountDataResponseStatusCode: null
};

const reducer = (
  state: IDashboardHomeState = initialState,
  action: GetStatsCountDataAction
): IDashboardHomeState => {
  switch (action.type) {
    case ActionType.GET_STATS_COUNT_DATA_REQUEST:
      return {
        ...state,
        getStatsCountDataRequesting: true,
        getStatsCountDataResponse: [],
        getStatsCountDataSuccess: false,
        getStatsCountDataSuccessMsg: '',
        getStatsCountDataError: false,
        getStatsCountDataErrorMsg: '',
        getStatsCountDataResponseStatusCode: null
      };
    case ActionType.GET_STATS_COUNT_DATA_SUCCESS:
      return {
        ...state,
        getStatsCountDataRequesting: false,
        getStatsCountDataResponse: action.payload.data,
        getStatsCountDataSuccess: true,
        getStatsCountDataSuccessMsg: action.payload.message,
        getStatsCountDataResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_STATS_COUNT_DATA_ERROR:
      return {
        ...state,
        getStatsCountDataRequesting: false,
        getStatsCountDataResponse: action.payload.data,
        getStatsCountDataError: true,
        getStatsCountDataErrorMsg: action.payload.message,
        getStatsCountDataResponseStatusCode: action.payload.statusCode
      };
    default:
      return state;
  }
};

export default reducer;
