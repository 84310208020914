import { ActionType } from '../action-types';
import { Action } from '../actions';

interface ILoginState {
  loginRequesting: boolean;
  loginResponse: any[];
  loginSuccess: boolean;
  loginSuccessMsg: string | null;
  loginError: boolean;
  loginErrorMsg: string | null;
  loginResponseStatusCode: number | null;
}

const initialState = {
  loginRequesting: false,
  loginResponse: [],
  loginSuccess: false,
  loginSuccessMsg: '',
  loginError: false,
  loginErrorMsg: '',
  loginResponseStatusCode: null
};

const reducer = (
  state: ILoginState = initialState,
  action: Action
): ILoginState => {
  switch (action.type) {
    case ActionType.LOGIN_REQUEST:
      return {
        ...state,
        loginRequesting: true,
        loginResponse: [],
        loginSuccess: false,
        loginSuccessMsg: '',
        loginError: false,
        loginErrorMsg: '',
        loginResponseStatusCode: null
      };
    case ActionType.LOGIN_SUCCESS:
      return {
        ...state,
        loginRequesting: false,
        loginResponse: action.payload.data,
        loginSuccess: true,
        loginSuccessMsg: action.payload.message,
        loginResponseStatusCode: action.payload.statusCode
      };
    case ActionType.LOGIN_ERROR:
      return {
        ...state,
        loginRequesting: false,
        loginResponse: action.payload.data,
        loginError: true,
        loginErrorMsg: action.payload.message,
        loginResponseStatusCode: action.payload.statusCode
      };
    case ActionType.RESET_LOGIN_PAGE_PREVIOUS_STATE:
      return {
        ...state,
        loginRequesting: false,
        loginResponse: [],
        loginSuccess: false,
        loginSuccessMsg: '',
        loginError: false,
        loginErrorMsg: '',
        loginResponseStatusCode: null
      };
    default:
      return state;
  }
};

export default reducer;
