import { ActionType } from '../action-types';
import { Action } from '../actions';

interface ILanguageState {
  getLanguageListRequesting: boolean;
  getLanguageListResponse: any[] | [];
  getLanguageListSuccess: boolean;
  getLanguageListSuccessMsg: string | '';
  getLanguageListError: boolean;
  getLanguageListErrorMsg: string | '';
  getLanguageListResponseStatusCode: number | null;
}

const initialState = {
  getLanguageListRequesting: false,
  getLanguageListResponse: [],
  getLanguageListSuccess: false,
  getLanguageListSuccessMsg: '',
  getLanguageListError: false,
  getLanguageListErrorMsg: '',
  getLanguageListResponseStatusCode: null
};

const reducer = (
  state: ILanguageState = initialState,
  action: Action
): ILanguageState => {
  switch (action.type) {
    case ActionType.GET_LANGUAGE_LIST_REQUEST:
      return {
        ...state,
        getLanguageListRequesting: true,
        getLanguageListResponse: [],
        getLanguageListSuccess: false,
        getLanguageListSuccessMsg: '',
        getLanguageListError: false,
        getLanguageListErrorMsg: '',
        getLanguageListResponseStatusCode: null
      };
    case ActionType.GET_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        getLanguageListRequesting: false,
        getLanguageListResponse: action.payload.data,
        getLanguageListSuccess: true,
        getLanguageListSuccessMsg: action.payload.message,
        getLanguageListResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_LANGUAGE_LIST_ERROR:
      return {
        ...state,
        getLanguageListRequesting: false,
        getLanguageListResponse: action.payload.data,
        getLanguageListError: true,
        getLanguageListErrorMsg: action.payload.message,
        getLanguageListResponseStatusCode: action.payload.statusCode
      };
    default:
      return state;
  }
};

export default reducer;
