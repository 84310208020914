export const TRANSLATIONS_UR = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'ہوم',
      about: 'تعارف',
      support: 'حمایت',
      pricing: 'قیمتیں',
      signIn: 'سائن اِن ',
      signUp: 'سائن اپ'
    },
    // banner: {
    //   header: 'فیڈ بیک مینجمنٹ سسٹم',
    //   subHeader:
    //     'پولارون کے ذریعے تقویت یافتہ، فیڈ بیک ناؤ استعمال کرنے میں آسان، محفوظ اور قابل ذکر لاگت سے اپنی رسائی کو بڑھانے اور اپنے سامعین کی توجہ حاصل کرنے کا طریقہ ہے۔',
    //   supportingText:
    //     'With Feedback Now, you can give your customers voice in any language, any format, any time. ',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: 'ہمیں بتائیں ۔۔۔ اپنی زبان میں!',
      subHeader:
        'کیا آپ جانتے ہیں کہ ہماری کمیونٹیز میں ہم 270 سے زیادہ زبانیں بولتے ہیں، 300 آباؤ اجداد کی نمائندگی کرتے ہیں اور 100 مذاہب پر عمل کرتے ہیں؟',
      supportingText1:
        'FeedbackNow  ایک آسان، محفوظ اور کم خرچ طریقہ ہے جس سے آپ زیادہ لوگوں تک ',
      supportingText2: 'پہنچ سکتے ہیں اور 50 سے زیادہ زبانوں میں اپنے مطلوبہ لوگوں کے خیالات معلوم کر سکتے ہیں۔',
      generic: {
        getStarted: 'ابھی رائے دیں۔'
      }
    },
    about: {
      header: 'ہمیں اپنی زبان میں بتائیں!',
      supportingText:
        'کیا آپ جانتے ہیں کہ ہماری کمیونٹیز میں ہم 270 سے زیادہ زبانیں بولتے ہیں، 300 آباؤ اجداد کی نمائندگی کرتے ہیں اور 100 مذاہب پر عمل کرتے ہیں؟',
      boxOne: 'تکمیل',
      boxTwo: 'حمایت',
      boxThree: 'ترجمہ'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'کلائنٹس کے لیے یہ کام کیسے چلتا ہے',
        step_one: 'کلائنٹ کے طور پر سائن اپ کریں',
        step_two: 'اپنی API key لیں',
        step_three: 'API key کے ساتھ کسی بھی زبان میں اپنی رائے بھیجیں',
        step_four: 'رائے کا ترجمہ کروائیں'
      },
      translator: {
        header: 'مترجمین کے لیے یہ کام کیسے چلتا ہے',
        step_one: 'مترجم کے طور پر سائن اپ کریں',
        step_two: 'تحریری اور زبانی مترجمین کو اسناد جاری کرنے والی قومی اتھارٹی (NAATI) سے ملنے والے سرٹیفکیٹس اپ لوڈ کریں اور ویریفیکیشن حاصل کریں',
        step_three: 'دی گئی آراء کا ترجمہ کریں',
        step_four: 'معاوضہ حاصل کریں'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'رائے کی قسم چنیں',
    enterText: 'اپنی رائے اینٹر کریں ',
    generic: {
      submit: 'بھیج دیں',
      word: 'الفاظ',
      send: 'Send',
      audioRecorder: 'آڈیو ریکارڈر',
      audioFile:'آڈیو فائل',
      videoRecorder:'وڈیو ریکارڈر',
      videoFile:'وڈیو فائل',
      uploadText:'فائل اپ لوڈ یا ڈریگ اینڈ ڈراپ کریں',
      uploadAudio:'آڈیو اپ لوڈ کریں',
      uploadVideo:'وڈیو اپ لوڈ کریں',
      uploading: 'اپ لوڈ ہو رہا ہے۔',
      startRecording: 'ریکارڈنگ شروع کریں',
      stopRecording: 'ریکارڈنگ روکیں',
      saveRecording: 'ریکارڈنگ سیو کریں',
      cancelRecording: 'ریکارڈنگ کینسل کریں',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'براہ مہربانی آگے جانے سے پہلے اپنی رائے اینٹر کریں۔ شکریہ۔',
      errorMessage: 'کوئی مسئلہ ہے۔ براہ مہربانی دوبارہ کوشش کریں۔',
      successMessage: 'رائے کامیابی سے شامل ہو گئی ہے۔'
    },
    content: {
      completion: 'تکمیل',
      support: 'حمایت',
      translation: 'ترجمہ'
    }
  },
  pricingModule: {
    header: 'قیمتیں',
    subheader: 'اپنے لیے بہترین پلان چنیں',
    supportingText:
      'ہمارا قیمتوں کا تعین کرنے والا ماڈل لچکدار اور جدید ہے، جس میں 80% فیس براہ راست مترجمین کو جاتی ہے۔ چاہے آپ دیہی این جی او ہوں، چھوٹے کاروبار ہوں یا صحت کی دیکھ بھال کرنے والے بڑے، ہمارے پاس آپ کی ضروریات کے مطابق منصوبہ ہے۔ مزید معلومات کی ضرورت ہے؟ ہم سے یہاں رابطہ کریں۔',
    module: {
      title: 'سوشل انٹرپرائز ماڈل',
      descriptionLineOne:
        'فیڈ بیک اب NAATI سے تصدیق شدہ مترجمین کے ذریعے آپ کے گاہک کے تاثرات کا ترجمہ کرتا ہے۔ آپ کو اپنے کسٹمر کے تاثرات کا ترجمہ کرنے کے بارے میں فکر کرنے کی ضرورت نہیں ہے۔ آپ نے ہمیں کسی بھی زبان میں رائے بھیجی اور ہم آپ کے لیے ترجمہ کریں گے۔',
      descriptionLineTwo:
        'آپ اپنی تنظیم کے لیے لاگت کی بچت اور افادیت بھی پیدا کریں گے۔'
    }
  },
  footer: {
    gridOne: {
      title: 'سپّورٹ',
      listOne: 'قیمتیں',
      listTwo: 'کاغذات',
      listThree: 'ہم سے رابطہ کریں'
    },
    gridTwo: {
      title: 'کمپنی',
      listOne: 'تعارف',
      listTwo: 'بلاگ'
    },
    gridThree: {
      title: 'قانونی',
      listOne: 'رازداری اور پالیسی',
      listTwo: 'شرائط و ضوابط'
    },
    gridFour: {
      title: 'ہمارا نیوزلیٹر سبسکرائب کریں',
      listOne:
        'ہر ہفتے آپ کے ان باکس میں تازہ ترین خبریں، مضامین اور وسائل بھیجے جاتے ہیں۔',
      placeHolder: 'اپنا ای میل ایڈریس اینٹر کریں',
      button: 'سبسکرائب کریں'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. جملہ حقوق محفوظ ہیں'
    }
  },
  aboutUsModule: {
    title: 'ہمارا تعارف',
    description:
      'چاہے ٹیکنالوجی کتنی ہی ترقی کر چکی ہو، زبان کے درست حلوں کے لیے اب بھی انسانی قابلیت کی ضرورت ہے۔ مترجمین ہمیشہ سروس پرووائیڈرز اور آسٹریلیا کی ملٹی کلچرل کمیونٹیوں کے درمیان ایک لازمی کڑی بنے رہیں گے۔ Polaron لینگوئج سروسز 20 سال سے زیادہ عرصے سے اپنے کسٹمرز کو اپنے تمام کام کا محور بنائے ہوئے ہے ۔۔۔'
  },
  contactUsModule: {
    title: 'ہم سے رابطہ کریں',
    header: 'ہم کیسے مدد کر سکتے ہیں؟',
    supportingText:
      'براہ مہربانی نیچے ہمیں بتائیں کہ کیا مسئلہ ہے۔ ہم 24 گھنٹوں کے اندر جواب دینے کا ہدف رکھتے ہیں۔ اگر آپ کا مسئلہ فوری توجہ طلب ہے تو براہ مہربانی 1300 88 55 61 پر فون کریں۔',
    fieldOneLabel: 'آپ کا نام',
    fieldOnePlaceholder: 'مثال: John Doe',
    fieldTwoLabel: 'آپ کا ای میل ایڈریس',
    fieldTwoPlaceholder: 'مثال: John@feedbacknow.com',
    fieldThreeLabel: 'آپ کا مسئلہ یا رائے',
    fieldThreePlaceholder: 'یہاں درج کریں۔',
    button: 'بھیج دیں'
  },
  userInfoForm: {
    title: 'یوزر کی معلومات',
    header: 'اسے کیسے رکھا جائے',
    public: 'پبلک',
    private: 'پرائیویٹ',
    fieldOneLabel: 'نام کا پہلا حصہ',
    fieldOnePlaceholder: 'مثال: John',
    fieldTwoLabel: 'نام کا آخری حصہ',
    fieldTwoPlaceholder: 'مثال: Doe',
    fieldThreeLabel: 'ای میل',
    fieldThreePlaceholder: 'مثال: John@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'یوزر کی معلومات لازمی ہیں'
    }
  }
};
