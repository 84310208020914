import { ActionType } from '../action-types';

import { Action } from '../actions';

interface ITranslatorDocumentInfoState {
  uploadDocumentRequesting: boolean;
  uploadDocumentResponse: any | [];
  uploadDocumentSuccess: boolean;
  uploadDocumentSuccessMsg: string | '';
  uploadDocumentError: boolean;
  uploadDocumentErrorMsg: string | '';
  uploadDocumentResponseStatusCode: number | null;

  getUploadedDocumentRequesting: boolean;
  getUploadedDocumentResponse: any | [];
  getUploadedDocumentSuccess: boolean;
  getUploadedDocumentSuccessMsg: string | '';
  getUploadedDocumentError: boolean;
  getUploadedDocumentErrorMsg: string | '';
  getUploadedDocumentResponseStatusCode: number | null;
}

const initialState = {
  uploadDocumentRequesting: false,
  uploadDocumentResponse: [],
  uploadDocumentSuccess: false,
  uploadDocumentSuccessMsg: '',
  uploadDocumentError: false,
  uploadDocumentErrorMsg: '',
  uploadDocumentResponseStatusCode: null,
  getUploadedDocumentRequesting: false,
  getUploadedDocumentResponse: [],
  getUploadedDocumentSuccess: false,
  getUploadedDocumentSuccessMsg: '',
  getUploadedDocumentError: false,
  getUploadedDocumentErrorMsg: '',
  getUploadedDocumentResponseStatusCode: null
};

const reducer = (
  state: ITranslatorDocumentInfoState = initialState,
  action: Action
): ITranslatorDocumentInfoState => {
  switch (action.type) {
    case ActionType.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        uploadDocumentRequesting: true,
        uploadDocumentResponse: [],
        uploadDocumentSuccess: false,
        uploadDocumentSuccessMsg: '',
        uploadDocumentError: false,
        uploadDocumentErrorMsg: '',
        uploadDocumentResponseStatusCode: null
      };
    case ActionType.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadDocumentRequesting: false,
        uploadDocumentResponse: action.payload.data,
        uploadDocumentSuccess: true,
        uploadDocumentSuccessMsg: action.payload.message,
        uploadDocumentResponseStatusCode: action.payload.statusCode
      };

    case ActionType.UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        uploadDocumentRequesting: false,
        uploadDocumentResponse: action.payload.data,
        uploadDocumentError: true,
        uploadDocumentErrorMsg: action.payload.message,
        uploadDocumentResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_UPLOADED_DOCUMENT_REQUEST:
      return {
        ...state,
        getUploadedDocumentRequesting: true,
        getUploadedDocumentResponse: [],
        getUploadedDocumentSuccess: false,
        getUploadedDocumentSuccessMsg: '',
        getUploadedDocumentError: false,
        getUploadedDocumentErrorMsg: '',
        getUploadedDocumentResponseStatusCode: null
      };
    case ActionType.GET_UPLOADED_DOCUMENT_SUCCESS:
      return {
        ...state,
        getUploadedDocumentRequesting: false,
        getUploadedDocumentResponse: action.payload.data,
        getUploadedDocumentSuccess: true,
        getUploadedDocumentSuccessMsg: action.payload.message,
        getUploadedDocumentResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_UPLOADED_DOCUMENT_ERROR:
      return {
        ...state,
        getUploadedDocumentRequesting: false,
        getUploadedDocumentResponse: action.payload.data,
        getUploadedDocumentError: true,
        getUploadedDocumentErrorMsg: action.payload.message,
        getUploadedDocumentResponseStatusCode: action.payload.statusCode
      };

    case ActionType.RESET_TRANSLATOR_PROFILE_INFO_PREVIOUS_STATE:
      return {
        ...state,
        uploadDocumentRequesting: false,
        uploadDocumentResponse: [],
        uploadDocumentSuccess: false,
        uploadDocumentSuccessMsg: '',
        uploadDocumentError: false,
        uploadDocumentErrorMsg: '',
        uploadDocumentResponseStatusCode: null,
        getUploadedDocumentRequesting: false,
        getUploadedDocumentResponse: [],
        getUploadedDocumentSuccess: false,
        getUploadedDocumentSuccessMsg: '',
        getUploadedDocumentError: false,
        getUploadedDocumentErrorMsg: '',
        getUploadedDocumentResponseStatusCode: null
      };
    default:
      return state;
  }
};

export default reducer;
