export const TRANSLATIONS_KR = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: '홈페이지',
      about: '회사 소개',
      support: '지원',
      pricing: '가격',
      signIn: '로그인',
      signUp: '회원가입'
    },
    // banner: {
    //   header: '피드백 관리 시스템',
    //   subHeader:
    //     'Polaron이 제공하는 Feedback Now는 도달 범위를 확장하고 청중의 관심을 끌 수 있는 사용하기 쉽고 안전하며 매우 비용 효율적인 방법입니다.',
    //   supportingText:
    //     'FeedbackNow를 사용하면 언제든지 모든 언어, 모든 형식으로 고객의 의견을 전달할 수 있습니다.',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: '말해주세요… 여러분의 언어로!',
      subHeader:
        '우리 커뮤니티 전체에서 270개 이상의 언어를 사용하고 300개의 조상을 대표하며 100개의 종교를 믿는다는 사실을 알고 계셨습니까?',
      supportingText1:
        'FeedbackNow는 50개 이상의 언어로 여러분의 고객층을 넓히고',
      supportingText2: '반응을 측정하는 데 있어, 사용하기 간편하고 안전하며 비용 효율적인 방법입니다.',
      generic: {
        getStarted: '지금 피드백 제공'
      }
    },
    about: {
      header: '당신의 언어로... 말해주세요!',
      supportingText:
        '우리 커뮤니티 전체에서 270개 이상의 언어를 사용하고 300개의 조상을 대표하며 100개의 종교를 믿는다는 사실을 알고 계셨습니까?',
      boxOne: '완성',
      boxTwo: '지원',
      boxThree: '번역'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: '클라이언트에게는 어떻게 작동하나요',
        step_one: '클라이언트로 회원가입하기',
        step_two: 'API키 받기',
        step_three: 'API키와 함께 어떤 언어로든 피드백 보내기',
        step_four: '번역된 피드백 받기'
      },
      translator: {
        header: '번역가에게는 어떻게 작동하나요',
        step_one: '번역가로 회원가입하기',
        step_two: '호주 공인 번역 (NAATI) 자격증을 업로드하고 인증받기',
        step_three: '할당된 피드백 번역하기',
        step_four: '비용 지급받기'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: '피드백 종류를 선택하세요',
    enterText: '피드백을 입력하세요',
    generic: {
      submit: '제출',
      word: '단어',
      send: 'Send',
      audioRecorder: '오디오 녹음기',
      audioFile:'오디오 파일',
      videoRecorder:'비디오 녹화',
      videoFile:'비디오 파일',
      uploadText:'파일을 업로드하거나 드래그 후 놓으세요',
      uploadAudio:'오디오 업로드',
      uploadVideo:'비디오 업로드',
      uploading: '업로드 중',
      startRecording: '녹화 시작',
      stopRecording: '녹화 중지',
      saveRecording: '녹화 저장',
      cancelRecording: '녹화 취소',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        '진행하기 전에 피드백을 입력해 주세요. 감사합니다.',
      errorMessage: '에러가 발생했습니다. 다시 시도해 주세요.',
      successMessage: '피드백이 성공적으로 제출되었습니다.'
    },
    content: {
      completion: '완성',
      support: '지원',
      translation: '번역'
    }
  },
  pricingModule: {
    header: '가격',
    subheader: '여러분에게 가장 알맞는 플랜을 선택하세요',
    supportingText:
      '당사의 가격 책정 모델은 유연하고 혁신적이며 수수료의 80%가 번역가에게 직접 전달됩니다. 농촌 NGO, 소기업 또는 대형 의료 서비스 제공자이든 관계없이 귀하의 필요에 맞는 플랜이 있습니다. 더 많은 정보가 필요하십니까? 여기로 문의하세요',
    module: {
      title: '사회적 기업 모델',
      descriptionLineOne:
        'FeedbackNow는 NAATI 인증 번역가를 통해 고객 피드백을 번역합니다. 고객 피드백 번역에 대해 걱정할 필요가 없습니다. 어떤 언어로든 피드백을 보내주시면 번역해 드립니다.',
      descriptionLineTwo:
        '귀사의 비용을 절감하게 되며 효율성을 창출하게 될 것입니다.'
    }
  },
  footer: {
    gridOne: {
      title: '지원',
      listOne: '가격',
      listTwo: '문서',
      listThree: '문의하기'
    },
    gridTwo: {
      title: '회사',
      listOne: '회사 소개',
      listTwo: '블로그'
    },
    gridThree: {
      title: '법률',
      listOne: '개인 정보 및 정책',
      listTwo: '이용약관'
    },
    gridFour: {
      title: '우리의 뉴스레터를 구독하세요',
      listOne:
        '최신 뉴스, 기사 및 자료가 매주 메일함으로 전송됩니다.',
      placeHolder: '이메일 입력',
      button: '구독하기'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. 판권 소유.'
    }
  },
  aboutUsModule: {
    title: '회사 소개',
    description:
      '기술이 얼마나 발전하더라도, 정확한 언어 솔루션은 여전히 인간의 전문 지식이 있어야 합니다. 번역가들은 항상 서비스 제공자와 호주의 다문화 사회 간의 중요한 연결고리가 될 것입니다. 지난 20년간, Polaron의 언어 서비스는 모든 일에 있어 고객을 중심으로 두고 있습니다.'
  },
  contactUsModule: {
    title: '문의하기',
    header: '어떻게 도와드릴까요?',
    supportingText:
      '아래에 문제 사항을 설명해 주세요. 24시간 이내에 답변을 목표로 하고 있습니다. 만약 시급한 문제라면, 1300 88 55 61번으로 전화해 주세요.',
    fieldOneLabel: '성명',
    fieldOnePlaceholder: '예시: John Doe',
    fieldTwoLabel: '이메일',
    fieldTwoPlaceholder: '예시: john@feedbacknow.com',
    fieldThreeLabel: '문제 또는 피드백 내용',
    fieldThreePlaceholder: '여기에 입력',
    button: '제출'
  },
  userInfoForm: {
    title: '이용자 정보',
    header: '정보 공개 여부',
    public: '공개',
    private: '비공개',
    fieldOneLabel: '이름',
    fieldOnePlaceholder: '예시: John',
    fieldTwoLabel: '성',
    fieldTwoPlaceholder: '예시: Doe',
    fieldThreeLabel: '이메일',
    fieldThreePlaceholder: '예시: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: '이용자 정보가 필요합니다'
    }
  }
};
