export const TRANSLATIONS_ZH = {
  welcome: 'Welcome to the Chinese Simplified(Mandarin) tutorial',
  home: {
    nav: {
      home: '首页',
      about: '关于',
      support: '支持',
      pricing: '价格',
      signIn: '登录',
      signUp: '注册'
    },
    // banner: {
    //   header: '反馈管理系统',
    //   subHeader:
    //     '在 Polaron 的支持下，Feedback Now 是一种易于使用、安全且极具成本效益的方式，可以扩大您的影响范围并掌握您的受众的脉搏。',
    //   supportingText:
    //     '借助 FeedbackNow，您可以随时以任何语言、任何格式向客户表达意见。',
    //   generic: {
    //     getStarted: '開始'
    //   }
    // },
    banner: {
      header: '来！用您的母语告诉我们……',
      subHeader:
        '您知道吗，在我们的社区中，我们讲 270 多种语言，代表 300 个血统，信奉 100 种宗教？',
      supportingText1:
        'FeedbackNow是一个易于使用且安全高效的平台，',
      supportingText2: '它能帮助您用50余种语言连接全球受众，掌握客户需求。',
      generic: {
        getStarted: '我要反馈'
      }
    },
    about: {
      header: '告诉我们……用您的语言！',
      supportingText:
        '您知道吗，在我们的社区中，我们讲 270 多种语言，代表 300 个血统，信奉 100 种宗教？',
      boxOne: '完成',
      boxTwo: '支持',
      boxThree: '翻译'
    },
    support: {
      supportingText:
        '这些统计数据表明，捕获和管理不同语言的响应可以帮助您收集更准确的数据、建立真实的参与度并改进决策制定。'
    },
    howItWorks: {
      client: {
        header: '它如何为客户服务',
        step_one: '注册成为一名客户',
        step_two: '获得您的API密钥',
        step_three: '用任何语言撰写您的反馈后，与您的API密钥一起发送',
        step_four: '收到翻译后的反馈'
      },
      translator: {
        header: '它如何为翻译工作',
        step_one: '注册成为一名翻译员',
        step_two: '上传您的澳洲翻译资格认证协会（NAATI）证书，获得认证',
        step_three: '翻译分配到的反馈',
        step_four: '收到酬金'
      }
    }
  },
  selectLanguage: {
    title: '选择你的语言.',
    generic: {
      letsGo: '我们走吧'
    }
  },
  selectFeedbackType: {
    title: '选择反馈类型',
    enterText: '输入您的反馈',
    generic: {
      submit: '提交',
      word: '文字',
      send: '發送中',
      audioRecorder: '录制音频',
      audioFile:'音频文件',
      videoRecorder:'录制视频',
      videoFile:'视频文件',
      uploadText:'上传文件 或 拖拽文件至此',
      uploadAudio:'上传音频',
      uploadVideo:'上传视频',
      uploading: '上传中',
      startRecording: '开始录制',
      stopRecording: '停止录制',
      saveRecording: '保存录制',
      cancelRecording: '取消录制',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation: '请在继续之前输入您的反馈。 谢谢你。',
      errorMessage: '出了些问题。 请再试一遍。',
      successMessage: '反馈添加成功'
    }
  },
  pricingModule: {
    header: '价格',
    subheader: '选择最适合您的方案',
    supportingText:
      '我们的定价模式灵活且创新，80% 的费用直接支付给译员。 无论您是农村非政府组织、小型企业还是大型医疗保健提供商，我们都有适合您需求的计划。 需要更多信息？ 在这里联系我们',
    module: {
      title: '社会企业模式',
      descriptionLineOne:
        'FeedbackNow 通过 NAATI 认证的翻译人员翻译您的客户反馈。 您不必担心需自行翻译您收到的客户反馈。 您以任何语言向我们发送反馈，我们将为您进行翻译。',
      descriptionLineTwo:
        '您还将为您的组织节省成本并提高效率。'
    }
  },
  footer: {
    gridOne: {
      title: '支持',
      listOne: '价格',
      listTwo: '文档',
      listThree: '联系我们'
    },
    gridTwo: {
      title: '公司',
      listOne: '关于',
      listTwo: '博客'
    },
    gridThree: {
      title: '合法的',
      listOne: '隐私和政策',
      listTwo: '条款和条件'
    },
    gridFour: {
      title: '订阅我们的简报',
      listOne:
        '每周的最新资讯、文章和资源将发送至您的邮箱。',
      placeHolder: '输入邮箱',
      button: '订阅'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. 版权所有。'
    }
  },
  aboutUsModule: {
    title: '关于我们',
    description:
      '无论科技如何进步，精确的语言解决方案仍需人类专业技能的支持。翻译员永远是连接服务提供商和澳洲多文化社会的重要桥梁。在过去的20年中，Polaron语言服务始终视客户为中心，我们的任何进步都围绕客户进行……'
  },
  contactUsModule: {
    title: '联系我们',
    header: '我们可以如何帮助您？',
    supportingText:
      '请在下方描述您所遇到的问题。我们将在24小时之内联系您。如遇紧急情况，请致电1300 88 55 61。',
    fieldOneLabel: '您的姓名',
    fieldOnePlaceholder: '示例: John Doe',
    fieldTwoLabel: '您的电子邮箱',
    fieldTwoPlaceholder: '示例: john@feedbacknow.com',
    fieldThreeLabel: '您遇到的问题或反馈',
    fieldThreePlaceholder: '在这里输入',
    button: '提交'
  },
  userInfoForm: {
    title: '用户信息',
    header: '使其保持',
    public: '公开',
    private: '私密',
    fieldOneLabel: '名',
    fieldOnePlaceholder: '示例: John',
    fieldTwoLabel: '姓',
    fieldTwoPlaceholder: '示例: Doe',
    fieldThreeLabel: '电子邮箱',
    fieldThreePlaceholder: '示例: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: '请添加用户信息'
    }
  }
};
