import { ActionType } from '../action-types';
import { GetAllClientListAction } from '../actions';

interface IAdminClientState {
  getAllClientListRequesting: boolean;
  getAllClientListResponse: any | [];
  getAllClientListSuccess: boolean;
  getAllClientListSuccessMsg: string | '';
  getAllClientListError: boolean;
  getAllClientListErrorMsg: string | '';
  getAllClientListResponseStatusCode: number | null;
}

const initialState = {
  getAllClientListRequesting: false,
  getAllClientListResponse: [],
  getAllClientListSuccess: false,
  getAllClientListSuccessMsg: '',
  getAllClientListError: false,
  getAllClientListErrorMsg: '',
  getAllClientListResponseStatusCode: null
};

const reducer = (
  state: IAdminClientState = initialState,
  action: GetAllClientListAction
): IAdminClientState => {
  switch (action.type) {
    case ActionType.GET_ALL_CLIENT_LIST_REQUEST:
      return {
        ...state,
        getAllClientListRequesting: true,
        getAllClientListResponse: [],
        getAllClientListSuccess: false,
        getAllClientListSuccessMsg: '',
        getAllClientListError: false,
        getAllClientListErrorMsg: '',
        getAllClientListResponseStatusCode: null
      };
    case ActionType.GET_ALL_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        getAllClientListRequesting: false,
        getAllClientListResponse: action.payload.data,
        getAllClientListSuccess: true,
        getAllClientListSuccessMsg: action.payload.message,
        getAllClientListResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_ALL_CLIENT_LIST_ERROR:
      return {
        ...state,
        getAllClientListRequesting: false,
        getAllClientListResponse: action.payload.data,
        getAllClientListError: true,
        getAllClientListErrorMsg: action.payload.message,
        getAllClientListResponseStatusCode: action.payload.statusCode
      };
    default:
      return state;
  }
};

export default reducer;
