export const TRANSLATIONS_PU = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'ਘਰ',
      about: 'ਬਾਰੇ',
      support: 'ਸਮਰਥਨ',
      pricing: 'ਕੀਮਤ',
      signIn: 'ਸਾਈਨ - ਇਨ',
      signUp: 'ਸਾਇਨ ਅਪ'
    },
    // banner: {
    //   header: 'ਫੀਡਬੈਕ ਪ੍ਰਬੰਧਨ ਸਿਸਟਮ',
    //   subHeader:
    //     'ਪੋਲਾਰਨ ਦੁਆਰਾ ਸੰਚਾਲਿਤ, ਫੀਡਬੈਕ ਨਾਓ ਤੁਹਾਡੀ ਪਹੁੰਚ ਨੂੰ ਵਧਾਉਣ ਅਤੇ ਤੁਹਾਡੇ ਦਰਸ਼ਕਾਂ ਦੀ ਨਬਜ਼ ਲੈਣ ਦਾ ਇੱਕ ਆਸਾਨ, ਸੁਰੱਖਿਅਤ ਅਤੇ ਕਮਾਲ ਦਾ ਲਾਗਤ-ਪ੍ਰਭਾਵਸ਼ਾਲੀ ਤਰੀਕਾ ਹੈ।',
    //   supportingText:
    //     'With Feedback Now, you can give your customers voice in any language, any format, any time. ',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: 'ਸਾਨੂੰ ਦੱਸੋ ... ਤੁਹਾਡੀ ਭਾਸ਼ਾ ਵਿੱਚ',
      subHeader:
        'ਕੀ ਤੁਸੀਂ ਜਾਣਦੇ ਹੋ ਕਿ ਸਾਡੇ ਭਾਈਚਾਰਿਆਂ ਵਿੱਚ ਅਸੀਂ 270 ਤੋਂ ਵੱਧ ਭਾਸ਼ਾਵਾਂ ਬੋਲਦੇ ਹਾਂ, 300 ਵੰਸ਼ਾਂ ਨੂੰ ਦਰਸਾਉਂਦੇ ਹਾਂ ਅਤੇ 100 ਧਰਮਾਂ ਦਾ ਅਭਿਆਸ ਕਰਦੇ ਹਾਂ?',
      supportingText1:
        'FeedbackNow 50+ ਭਾਸ਼ਾਵਾਂ ਵਿੱਚ ਤੁਹਾਡੀ ਪਹੁੰਚ ਨੂੰ ਵਧਾਉਣ ਅਤੇ ਤੁਹਾਡੇ ਦਰਸ਼ਕਾਂ',
      supportingText2: 'ਦੀ ਨਬਜ਼ ਲੈਣ ਦਾ ਇੱਕ ਵਰਤੋਂ ਵਿੱਚ ਆਸਾਨ, ਸੁਰੱਖਿਅਤ ਅਤੇ ਲਾਗਤ-ਪ੍ਰਭਾਵਸ਼ਾਲੀ ਤਰੀਕਾ ਹੈ।',
      generic: {
        getStarted: 'ਹੁਣੇ ਫੀਡਬੈਕ ਦਿਓ'
      }
    },
    about: {
      header: 'ਸਾਨੂੰ ਦੱਸੋ ... ਤੁਹਾਡੀ ਭਾਸ਼ਾ ਵਿੱਚ!',
      supportingText:
        'ਕੀ ਤੁਸੀਂ ਜਾਣਦੇ ਹੋ ਕਿ ਸਾਡੇ ਭਾਈਚਾਰਿਆਂ ਵਿੱਚ ਅਸੀਂ 270 ਤੋਂ ਵੱਧ ਭਾਸ਼ਾਵਾਂ ਬੋਲਦੇ ਹਾਂ, 300 ਵੰਸ਼ਾਂ ਨੂੰ ਦਰਸਾਉਂਦੇ ਹਾਂ ਅਤੇ 100 ਧਰਮਾਂ ਦਾ ਅਭਿਆਸ ਕਰਦੇ ਹਾਂ?',
      boxOne: 'ਸੰਪੂਰਨਤਾ',
      boxTwo: 'ਸਮਰਥਨ',
      boxThree: 'ਅਨੁਵਾਦ'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'ਇਹ ਗਾਹਕਾਂ ਲਈ ਕਿਵੇਂ ਕੰਮ ਕਰਦਾ ਹੈ',
        step_one: 'ਇੱਕ ਗਾਹਕ ਦੇ ਤੌਰ ਤੇ ਸਾਈਨ ਅੱਪ ਕਰੋ',
        step_two: 'ਆਪਣੀ API ਕੁੰਜੀ ਪ੍ਰਾਪਤ ਕਰੋ',
        step_three: 'API ਕੁੰਜੀ ਦੇ ਨਾਲ ਕਿਸੇ ਵੀ ਭਾਸ਼ਾ ਵਿੱਚ ਆਪਣੇ ਫੀਡਬੈਕ ਭੇਜੋ',
        step_four: 'ਅਨੁਵਾਦਿਤ ਫੀਡਬੈਕ ਪ੍ਰਾਪਤ ਕਰੋ'
      },
      translator: {
        header: 'ਇਹ ਅਨੁਵਾਦਕਾਂ ਲਈ ਕਿਵੇਂ ਕੰਮ ਕਰਦਾ ਹੈ',
        step_one: 'ਅਨੁਵਾਦਕ ਵਜੋਂ ਸਾਈਨ ਅੱਪ ਕਰੋ',
        step_two: 'ਆਪਣੇ NAATI ਸਰਟੀਫਿਕੇਟ ਅਪਲੋਡ ਕਰੋ ਅਤੇ ਤਸਦੀਕ ਕਰੋ',
        step_three: 'ਨਿਰਧਾਰਤ ਫੀਡਬੈਕ ਦਾ ਅਨੁਵਾਦ ਕਰੋ',
        step_four: 'ਭੁਗਤਾਨ ਪ੍ਰਾਪਤ ਕਰੋ'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'ਫੀਡਬੈਕ ਕਿਸਮ ਚੁਣੋ',
    enterText: 'ਆਪਣਾ ਫੀਡਬੈਕ ਦਾਖਲ ਕਰੋ',
    generic: {
      submit: 'ਜਮ੍ਹਾਂ ਕਰੋ',
      word: 'ਸ਼ਬਦ',
      send: 'Send',
      audioRecorder: 'ਆਡੀਓ ਰਿਕਾਰਡਰ',
      audioFile:'ਆਡੀਓ ਫਾਈਲ',
      videoRecorder:'ਵੀਡੀਓ ਰਿਕਾਰਡਰ',
      videoFile:'ਵੀਡੀਓ ਫਾਈਲ',
      uploadText:'ਇੱਕ ਫ਼ਾਈਲ ਅੱਪਲੋਡ ਕਰੋ ਜਾਂ ਡਰੈਗ ਐਂਡ ਡ੍ਰੌਪ ਕਰੋ',
      uploadAudio:'ਆਡੀਓ ਅੱਪਲੋਡ ਕਰੋ',
      uploadVideo:'ਵੀਡੀਓ ਅੱਪਲੋਡ ਕਰੋ',
      uploading: 'ਅੱਪਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ',
      startRecording: 'ਰਿਕਾਰਡਿੰਗ ਸ਼ੁਰੂ ਕਰੋ',
      stopRecording: 'ਰਿਕਾਰਡਿੰਗ ਬੰਦ ਕਰੋ',
      saveRecording: 'ਰਿਕਾਰਡਿੰਗ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰੋ',
      cancelRecording: 'ਰਿਕਾਰਡਿੰਗ ਰੱਦ ਕਰੋ',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'ਕਿਰਪਾ ਕਰਕੇ ਅੱਗੇ ਵਧਣ ਤੋਂ ਪਹਿਲਾਂ ਆਪਣਾ ਫੀਡਬੈਕ ਦਰਜ ਕਰੋ। ਤੁਹਾਡਾ ਧੰਨਵਾਦ.',
      errorMessage: 'ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ. ਮੁੜ ਕੋਸ਼ਿਸ ਕਰੋ ਜੀ.',
      successMessage: 'ਫੀਡਬੈਕ ਸਫਲਤਾਪੂਰਵਕ ਸ਼ਾਮਲ ਕੀਤਾ ਗਿਆ'
    },
    content: {
      completion: 'ਸੰਪੂਰਨਤਾ',
      support: 'ਸਮਰਥਨ',
      translation: 'ਅਨੁਵਾਦ'
    }
  },
  pricingModule: {
    header: 'ਕੀਮਤ',
    subheader: 'ਤੁਹਾਡੇ ਲਈ ਸਭ ਤੋਂ ਵਧੀਆ ਯੋਜਨਾ ਚੁਣੋ',
    supportingText:
      'ਸਾਡਾ ਮੁੱਲ ਨਿਰਧਾਰਨ ਮਾਡਲ ਲਚਕਦਾਰ ਅਤੇ ਨਵੀਨਤਾਕਾਰੀ ਹੈ, 80% ਫੀਸਾਂ ਸਿੱਧੇ ਅਨੁਵਾਦਕਾਂ ਨੂੰ ਜਾਂਦੀਆਂ ਹਨ। ਭਾਵੇਂ ਤੁਸੀਂ ਇੱਕ ਪੇਂਡੂ NGO, ਛੋਟਾ ਕਾਰੋਬਾਰ ਜਾਂ ਇੱਕ ਵੱਡਾ ਸਿਹਤ ਸੰਭਾਲ ਪ੍ਰਦਾਤਾ ਹੋ, ਸਾਡੇ ਕੋਲ ਤੁਹਾਡੀਆਂ ਜ਼ਰੂਰਤਾਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਇੱਕ ਯੋਜਨਾ ਹੈ। ਹੋਰ ਜਾਣਕਾਰੀ ਦੀ ਲੋੜ ਹੈ? ਸਾਡੇ ਨਾਲ ਇੱਥੇ ਸੰਪਰਕ ਕਰੋ',
    module: {
      title: 'ਸੋਸ਼ਲ ਐਂਟਰਪ੍ਰਾਈਜ਼ ਮਾਡਲ',
      descriptionLineOne:
        'ਫੀਡਬੈਕਹੁਣ NAATI-ਪ੍ਰਮਾਣਿਤ ਅਨੁਵਾਦਕਾਂ ਦੁਆਰਾ ਤੁਹਾਡੇ ਗਾਹਕ ਫੀਡਬੈਕ ਦਾ ਅਨੁਵਾਦ ਕਰਦਾ ਹੈ। ਤੁਹਾਨੂੰ ਆਪਣੇ ਗਾਹਕ ਫੀਡਬੈਕ ਦਾ ਅਨੁਵਾਦ ਕਰਨ ਬਾਰੇ ਚਿੰਤਾ ਕਰਨ ਦੀ ਕੋਈ ਲੋੜ ਨਹੀਂ ਹੈ। ਤੁਸੀਂ ਸਾਨੂੰ ਕਿਸੇ ਵੀ ਭਾਸ਼ਾ ਵਿੱਚ ਫੀਡਬੈਕ ਭੇਜਿਆ ਹੈ ਅਤੇ ਅਸੀਂ ਤੁਹਾਡੇ ਲਈ ਅਨੁਵਾਦ ਕਰਾਂਗੇ।',
      descriptionLineTwo:
        'ਤੁਸੀਂ ਆਪਣੀ ਸੰਸਥਾ ਲਈ ਲਾਗਤ ਦੀ ਬੱਚਤ ਅਤੇ ਕੁਸ਼ਲਤਾਵਾਂ ਵੀ ਬਣਾ ਰਹੇ ਹੋਵੋਗੇ।'
    }
  },
  footer: {
    gridOne: {
      title: 'ਸਹਿਯੋਗ',
      listOne: 'ਕੀਮਤ',
      listTwo: 'ਦਸਤਾਵੇਜ਼ੀਕਰਨ',
      listThree: 'ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ'
    },
    gridTwo: {
      title: 'ਕੰਪਨੀ',
      listOne: 'ਬਾਰੇ',
      listTwo: 'ਬਲੌਗ'
    },
    gridThree: {
      title: 'ਕਾਨੂੰਨੀ',
      listOne: 'ਗੋਪਨੀਯਤਾ ਅਤੇ ਨੀਤੀ',
      listTwo: 'ਨਿਬੰਧਨ ਅਤੇ ਸ਼ਰਤਾਂ'
    },
    gridFour: {
      title: 'ਆਉਟ ਨਿਊਜ਼ਲੈਟਰ ਲਈ ਗਾਹਕ ਬਣੋ',
      listOne:
        'ਤਾਜ਼ਾ ਖ਼ਬਰਾਂ, ਲੇਖ ਅਤੇ ਸਰੋਤ, ਤੁਹਾਡੇ ਇਨਬਾਕਸ ਨੂੰ ਹਫ਼ਤਾਵਾਰੀ ਭੇਜੇ ਜਾਂਦੇ ਹਨ।',
      placeHolder: 'ਈਮੇਲ ਦਰਜ ਕਰੋ',
      button: 'ਸਬਸਕ੍ਰਾਈਬ ਕਰੋ'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. All rights reserved.'
    }
  },
  aboutUsModule: {
    title: 'ਸਾਡੇ ਬਾਰੇ',
    description:
      'ਭਾਵੇਂ ਤਕਨਾਲੋਜੀ ਕਿੰਨੀ ਵੀ ਅੱਗੇ ਵਧਦੀ ਹੈ, ਸਹੀ ਭਾਸ਼ਾ ਹੱਲਾਂ ਲਈ ਅਜੇ ਵੀ ਮਨੁੱਖੀ ਮੁਹਾਰਤ ਦੀ ਲੋੜ ਹੁੰਦੀ ਹੈ। ਅਨੁਵਾਦਕ ਹਮੇਸ਼ਾ ਸੇਵਾ ਪ੍ਰਦਾਤਾਵਾਂ ਅਤੇ ਆਸਟ੍ਰੇਲੀਆ ਦੇ ਬਹੁ-ਸੱਭਿਆਚਾਰਕ ਭਾਈਚਾਰਿਆਂ ਵਿਚਕਾਰ ਇੱਕ ਮਹੱਤਵਪੂਰਣ ਲਿੰਕ ਹੋਣਗੇ। ਪਿਛਲੇ 20 ਸਾਲਾਂ ਵਿੱਚ, ਪੋਲਾਰਨ ਦੀ ਭਾਸ਼ਾ ਸੇਵਾਵਾਂ ਦੀ ਪੇਸ਼ਕਸ਼ ਲਗਾਤਾਰ ਵਧੀ ਹੈ। ਮੈਲਬੌਰਨ, ਸਿਡਨੀ, ਨਿਊਯਾਰਕ ਅਤੇ ਲੰਡਨ ਵਿੱਚ ਦਫਤਰਾਂ ਦੇ ਨਾਲ, ਸਾਡੀ ਕਾਰਗੁਜ਼ਾਰੀ ਭਰੋਸੇਯੋਗਤਾ ਅਤੇ ਨਵੀਨਤਾ ਲਈ ਇੱਕ ਚੰਗੀ ਕਮਾਈ ਕੀਤੀ ਪ੍ਰਤਿਸ਼ਠਾ ਦਾ ਪ੍ਰਮਾਣ ਹੈ। ਸਾਡੀ ਸਫਲਤਾ ਦੀ ਕੁੰਜੀ ਸਾਡੇ ਗ੍ਰਾਹਕਾਂ ਨੂੰ ਸਾਡੇ ਦੁਆਰਾ ਕੀਤੇ ਹਰ ਕੰਮ ਦੇ ਕੇਂਦਰ ਵਿੱਚ ਰੱਖਣਾ ਅਤੇ ਇੱਕ ਭਰੋਸੇਯੋਗ, ਪੇਸ਼ੇਵਰ ਕਾਰਜ ਸ਼ਕਤੀ ਦਾ ਵਿਕਾਸ ਕਰਨਾ ਹੈ, ਜੋ ਕਿਸੇ ਵੀ ਭਾਸ਼ਾਈ ਅਤੇ ਅੰਤਰ-ਸੱਭਿਆਚਾਰਕ ਚੁਣੌਤੀ ਨੂੰ ਨੈਵੀਗੇਟ ਕਰਨ ਵਿੱਚ ਤੁਹਾਡੀ ਮਦਦ ਕਰਨ ਲਈ ਤਿਆਰ ਹੈ।'
  },
  contactUsModule: {
    title: 'ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ',
    header: 'ਅਸੀਂ ਕਿਵੇਂ ਮਦਦ ਕਰ ਸਕਦੇ ਹਾਂ?',
    supportingText:
      "ਕਿਰਪਾ ਕਰਕੇ ਦੱਸੋ ਕਿ ਹੇਠਾਂ ਕੀ ਮੁੱਦਾ ਹੈ। ਅਸੀਂ 24 ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਜਵਾਬ ਦੇਣ ਦਾ ਟੀਚਾ ਰੱਖਦੇ ਹਾਂ। ਜੇਕਰ ਤੁਹਾਡੀ ਸਮੱਸਿਆ ਜ਼ਰੂਰੀ ਹੈ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ 1300 88 55 61 'ਤੇ ਕਾਲ ਕਰੋ।",
    fieldOneLabel: 'ਤੁਹਾਡਾ ਨਾਮ',
    fieldOnePlaceholder: 'ਉਦਾਹਰਨ: John Doe',
    fieldTwoLabel: 'ਤੁਹਾਡਾ ਈਮੇਲ',
    fieldTwoPlaceholder: 'ਉਦਾਹਰਨ: john@feedbacknow.com',
    fieldThreeLabel: 'ਤੁਹਾਡਾ ਮੁੱਦਾ ਜਾਂ ਫੀਡਬੈਕ',
    fieldThreePlaceholder: 'ਇੱਥੇ ਦਾਖਲ ਹੋਵੋ',
    button: 'ਜਮ੍ਹਾਂ ਕਰੋ'
  },
  userInfoForm: {
    title: 'ਉਪਭੋਗਤਾ ਜਾਣਕਾਰੀ',
    header: 'ਇਹ ਰੱਖੋ',
    public: 'ਜਨਤਕ',
    private: 'ਨਿਜੀ',
    fieldOneLabel: 'ਪਹਿਲਾ ਨਾਂ',
    fieldOnePlaceholder: 'ਉਦਾਹਰਨ: John',
    fieldTwoLabel: 'ਆਖਰੀ ਨਾਂਮ',
    fieldTwoPlaceholder: 'ਉਦਾਹਰਨ: Doe',
    fieldThreeLabel: 'ਈ - ਮੇਲ',
    fieldThreePlaceholder: 'ਉਦਾਹਰਨ: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'ਉਪਭੋਗਤਾ ਜਾਣਕਾਰੀ ਦੀ ਲੋੜ ਹੈ'
    }
  }
};
