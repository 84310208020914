import axios from 'axios';

import { apiConfiguration } from '../../config';

const instance = axios.create({
  baseURL: apiConfiguration.devBaseUrl,
  headers: {
    'Content-type': 'application/json'
  }
});

const fbnApi = (options: any, token = '', optionalHeader = null) => {
  if (token) instance.defaults.headers['Authorization'] = `Bearer ${token}`;
  if (optionalHeader) {
    Object.keys(optionalHeader).map((header, index) => {
      instance.defaults.headers[header] = optionalHeader[header];
    });
  }

  const onSuccess = function(response: any) {
    return response.data;
  };

  const onError = function(error: any) {
    return Promise.reject({
      error: error.response || error.message,
      msg: 'Connection Failed. Try Again Later'
    });
  };

  return instance(options)
    .then(onSuccess)
    .catch(onError);
};

export { fbnApi };
