import { ActionType } from '../action-types';
import {
  Action,
  AssignFeedbackTranslationTaskAction,
  GetAllActiveTranslatorAction,
  GetFeedbackDetailAction,
  ReviewTranslatedFeedbackAction,
  ResetFeedbackModuleStateAction,
  GetAllActiveLanguageAction,
  GetAllActiveClientAction
} from '../actions';

interface IFeedbackState {
  getAllFeedbackListRequesting: boolean;
  getAllFeedbackListResponse: any | [];
  getAllFeedbackListSuccess: boolean;
  getAllFeedbackListSuccessMsg: string | '';
  getAllFeedbackListError: boolean;
  getAllFeedbackListErrorMsg: string | '';
  getAllFeedbackResponseStatusCode: number | null;

  assignFeedbackTranslationTaskRequesting: boolean;
  assignFeedbackTranslationTaskResponse: any | [];
  assignFeedbackTranslationTaskSuccess: boolean;
  assignFeedbackTranslationTaskSuccessMsg: string | '';
  assignFeedbackTranslationTaskError: boolean;
  assignFeedbackTranslationTaskErrorMsg: string | '';
  assignFeedbackTranslationTaskResponseStatusCode: number | null;

  getAllActiveTranslatorRequesting: boolean;
  getAllActiveTranslatorResponse: any | [];
  getAllActiveTranslatorResponseStatusCode: number | null;
  getAllActiveTranslatorSuccess: boolean;
  getAllActiveTranslatorSuccessMsg: string | '';
  getAllActiveTranslatorError: boolean;
  getAllActiveTranslatorErrorMsg: string | '';

  getAllActiveClientRequesting: boolean;
  getAllActiveClientResponse: any | [];
  getAllActiveClientResponseStatusCode: number | null;
  getAllActiveClientSuccess: boolean;
  getAllActiveClientSuccessMsg: string | '';
  getAllActiveClientError: boolean;
  getAllActiveClientErrorMsg: string | '';

  getAllActiveLanguageRequesting: boolean;
  getAllActiveLanguageResponse: any | [];
  getAllActiveLanguageResponseStatusCode: number | null;
  getAllActiveLanguageSuccess: boolean;
  getAllActiveLanguageSuccessMsg: string | '';
  getAllActiveLanguageError: boolean;
  getAllActiveLanguageErrorMsg: string | '';

  getFeedbackDetailRequesting: boolean;
  getFeedbackDetailResponse: any | [];
  getFeedbackDetailResponseStatusCode: number | null;
  getFeedbackDetailSuccess: boolean;
  getFeedbackDetailSuccessMsg: string | '';
  getFeedbackDetailError: boolean;
  getFeedbackDetailErrorMsg: string | '';

  reviewTranslatedFeedbackRequesting: boolean;
  reviewTranslatedFeedbackResponse: any | [];
  reviewTranslatedFeedbackResponseStatusCode: number | null;
  reviewTranslatedFeedbackSuccess: boolean;
  reviewTranslatedFeedbackSuccessMsg: string | '';
  reviewTranslatedFeedbackError: boolean;
  reviewTranslatedFeedbackErrorMsg: string | '';
}

const initialState = {
  getAllFeedbackListRequesting: false,
  getAllFeedbackListResponse: [],
  getAllFeedbackListSuccess: false,
  getAllFeedbackListSuccessMsg: '',
  getAllFeedbackListError: false,
  getAllFeedbackListErrorMsg: '',
  getAllFeedbackResponseStatusCode: null,

  assignFeedbackTranslationTaskRequesting: false,
  assignFeedbackTranslationTaskResponse: [],
  assignFeedbackTranslationTaskSuccess: false,
  assignFeedbackTranslationTaskSuccessMsg: '',
  assignFeedbackTranslationTaskError: false,
  assignFeedbackTranslationTaskErrorMsg: '',
  assignFeedbackTranslationTaskResponseStatusCode: null,

  getAllActiveTranslatorRequesting: false,
  getAllActiveTranslatorResponse: [],
  getAllActiveTranslatorResponseStatusCode: null,
  getAllActiveTranslatorSuccess: false,
  getAllActiveTranslatorSuccessMsg: '',
  getAllActiveTranslatorError: false,
  getAllActiveTranslatorErrorMsg: '',

  getAllActiveClientRequesting: false,
  getAllActiveClientResponse: [],
  getAllActiveClientResponseStatusCode: null,
  getAllActiveClientSuccess: false,
  getAllActiveClientSuccessMsg: '',
  getAllActiveClientError: false,
  getAllActiveClientErrorMsg: '',

  getAllActiveLanguageRequesting: false,
  getAllActiveLanguageResponse: [],
  getAllActiveLanguageResponseStatusCode: null,
  getAllActiveLanguageSuccess: false,
  getAllActiveLanguageSuccessMsg: '',
  getAllActiveLanguageError: false,
  getAllActiveLanguageErrorMsg: '',

  getFeedbackDetailRequesting: false,
  getFeedbackDetailResponse: [],
  getFeedbackDetailResponseStatusCode: null,
  getFeedbackDetailSuccess: false,
  getFeedbackDetailSuccessMsg: '',
  getFeedbackDetailError: false,
  getFeedbackDetailErrorMsg: '',

  reviewTranslatedFeedbackRequesting: false,
  reviewTranslatedFeedbackResponse: [],
  reviewTranslatedFeedbackResponseStatusCode: null,
  reviewTranslatedFeedbackSuccess: false,
  reviewTranslatedFeedbackSuccessMsg: '',
  reviewTranslatedFeedbackError: false,
  reviewTranslatedFeedbackErrorMsg: ''
};

const reducer = (
  state: IFeedbackState = initialState,
  action:
    | Action
    | AssignFeedbackTranslationTaskAction
    | GetAllActiveTranslatorAction
    | GetFeedbackDetailAction
    | ReviewTranslatedFeedbackAction
    | ResetFeedbackModuleStateAction
    | GetAllActiveLanguageAction
    | GetAllActiveClientAction
): IFeedbackState => {
  switch (action.type) {
    case ActionType.RESET_FEEDBACK_MODULE_STATE:
      return {
        ...state,
        getAllFeedbackListRequesting: false,
        getAllFeedbackListResponse: [],
        getAllFeedbackListSuccess: false,
        getAllFeedbackListSuccessMsg: '',
        getAllFeedbackListError: false,
        getAllFeedbackListErrorMsg: '',
        getAllFeedbackResponseStatusCode: null,

        assignFeedbackTranslationTaskRequesting: false,
        assignFeedbackTranslationTaskResponse: [],
        assignFeedbackTranslationTaskSuccess: false,
        assignFeedbackTranslationTaskSuccessMsg: '',
        assignFeedbackTranslationTaskError: false,
        assignFeedbackTranslationTaskErrorMsg: '',
        assignFeedbackTranslationTaskResponseStatusCode: null,

        getAllActiveTranslatorRequesting: false,
        getAllActiveTranslatorResponse: [],
        getAllActiveTranslatorResponseStatusCode: null,
        getAllActiveTranslatorSuccess: false,
        getAllActiveTranslatorSuccessMsg: '',
        getAllActiveTranslatorError: false,
        getAllActiveTranslatorErrorMsg: '',

        getAllActiveClientRequesting: false,
        getAllActiveClientResponse: [],
        getAllActiveClientResponseStatusCode: null,
        getAllActiveClientSuccess: false,
        getAllActiveClientSuccessMsg: '',
        getAllActiveClientError: false,
        getAllActiveClientErrorMsg: '',

        getFeedbackDetailRequesting: false,
        getFeedbackDetailResponse: [],
        getFeedbackDetailResponseStatusCode: null,
        getFeedbackDetailSuccess: false,
        getFeedbackDetailSuccessMsg: '',
        getFeedbackDetailError: false,
        getFeedbackDetailErrorMsg: '',

        reviewTranslatedFeedbackRequesting: false,
        reviewTranslatedFeedbackResponse: [],
        reviewTranslatedFeedbackResponseStatusCode: null,
        reviewTranslatedFeedbackSuccess: false,
        reviewTranslatedFeedbackSuccessMsg: '',
        reviewTranslatedFeedbackError: false,
        reviewTranslatedFeedbackErrorMsg: '',

        getAllActiveLanguageRequesting: false,
        getAllActiveLanguageResponse: [],
        getAllActiveLanguageResponseStatusCode: null,
        getAllActiveLanguageSuccess: false,
        getAllActiveLanguageSuccessMsg: '',
        getAllActiveLanguageError: false,
        getAllActiveLanguageErrorMsg: ''
      };
    case ActionType.GET_ALL_FEEDBACK_LIST_REQUEST:
      return {
        ...state,
        getAllFeedbackListRequesting: true,
        getAllFeedbackListResponse: [],
        getAllFeedbackListSuccess: false,
        getAllFeedbackListSuccessMsg: '',
        getAllFeedbackListError: false,
        getAllFeedbackListErrorMsg: '',
        getAllFeedbackResponseStatusCode: null
      };
    case ActionType.GET_ALL_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        getAllFeedbackListRequesting: false,
        getAllFeedbackListResponse: action.payload.data,
        getAllFeedbackListSuccess: true,
        getAllFeedbackListSuccessMsg: action.payload.message,
        getAllFeedbackResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_ALL_FEEDBACK_LIST_ERROR:
      return {
        ...state,
        getAllFeedbackListRequesting: false,
        getAllFeedbackListResponse: action.payload.data,
        getAllFeedbackListError: true,
        getAllFeedbackListErrorMsg: action.payload.message,
        getAllFeedbackResponseStatusCode: action.payload.statusCode
      };

    case ActionType.ASSIGN_FEEDBACK_TRANSLATION_TASK_REQUEST:
      return {
        ...state,
        assignFeedbackTranslationTaskRequesting: true,
        assignFeedbackTranslationTaskResponse: [],
        assignFeedbackTranslationTaskSuccess: false,
        assignFeedbackTranslationTaskSuccessMsg: '',
        assignFeedbackTranslationTaskError: false,
        assignFeedbackTranslationTaskErrorMsg: '',
        assignFeedbackTranslationTaskResponseStatusCode: null
      };
    case ActionType.ASSIGN_FEEDBACK_TRANSLATION_TASK_SUCCESS:
      return {
        ...state,
        assignFeedbackTranslationTaskRequesting: false,
        assignFeedbackTranslationTaskResponse: action.payload.data,
        assignFeedbackTranslationTaskSuccess: true,
        assignFeedbackTranslationTaskSuccessMsg: action.payload.message,
        assignFeedbackTranslationTaskResponseStatusCode:
          action.payload.statusCode
      };
    case ActionType.ASSIGN_FEEDBACK_TRANSLATION_TASK_ERROR:
      return {
        ...state,
        assignFeedbackTranslationTaskRequesting: false,
        assignFeedbackTranslationTaskResponse: action.payload.data,
        assignFeedbackTranslationTaskError: true,
        assignFeedbackTranslationTaskErrorMsg: action.payload.message,
        assignFeedbackTranslationTaskResponseStatusCode:
          action.payload.statusCode
      };

    case ActionType.GET_ALL_ACTIVE_TRANSLATOR_REQUEST:
      return {
        ...state,
        getAllActiveTranslatorRequesting: true,
        getAllActiveTranslatorResponse: [],
        getAllActiveTranslatorSuccess: false,
        getAllActiveTranslatorSuccessMsg: '',
        getAllActiveTranslatorError: false,
        getAllActiveTranslatorErrorMsg: '',
        getAllActiveTranslatorResponseStatusCode: null
      };
    case ActionType.GET_ALL_ACTIVE_TRANSLATOR_SUCCESS:
      return {
        ...state,
        getAllActiveTranslatorRequesting: false,
        getAllActiveTranslatorResponse: action.payload.data,
        getAllActiveTranslatorSuccess: true,
        getAllActiveTranslatorSuccessMsg: action.payload.message,
        getAllActiveTranslatorResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_ALL_ACTIVE_TRANSLATOR_ERROR:
      return {
        ...state,
        getAllActiveTranslatorRequesting: false,
        getAllActiveTranslatorResponse: action.payload.data,
        getAllActiveTranslatorError: true,
        getAllActiveTranslatorErrorMsg: action.payload.message,
        getAllActiveTranslatorResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_ALL_ACTIVE_CLIENT_REQUEST:
      return {
        ...state,
        getAllActiveClientRequesting: true,
        getAllActiveClientResponse: [],
        getAllActiveClientSuccess: false,
        getAllActiveClientSuccessMsg: '',
        getAllActiveClientError: false,
        getAllActiveClientErrorMsg: '',
        getAllActiveClientResponseStatusCode: null
      };
    case ActionType.GET_ALL_ACTIVE_CLIENT_SUCCESS:
      return {
        ...state,
        getAllActiveClientRequesting: false,
        getAllActiveClientResponse: action.payload.data,
        getAllActiveClientSuccess: true,
        getAllActiveClientSuccessMsg: action.payload.message,
        getAllActiveClientResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_ALL_ACTIVE_CLIENT_ERROR:
      return {
        ...state,
        getAllActiveClientRequesting: false,
        getAllActiveClientResponse: action.payload.data,
        getAllActiveClientError: true,
        getAllActiveClientErrorMsg: action.payload.message,
        getAllActiveClientResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_FEEDBACK_DETAIL_REQUEST:
      return {
        ...state,
        getFeedbackDetailRequesting: true,
        getFeedbackDetailResponse: [],
        getFeedbackDetailSuccess: false,
        getFeedbackDetailSuccessMsg: '',
        getFeedbackDetailError: false,
        getFeedbackDetailErrorMsg: '',
        getFeedbackDetailResponseStatusCode: null
      };
    case ActionType.GET_FEEDBACK_DETAIL_SUCCESS:
      return {
        ...state,
        getFeedbackDetailRequesting: false,
        getFeedbackDetailResponse: action.payload.data,
        getFeedbackDetailSuccess: true,
        getFeedbackDetailSuccessMsg: action.payload.message,
        getFeedbackDetailResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_FEEDBACK_DETAIL_ERROR:
      return {
        ...state,
        getFeedbackDetailRequesting: false,
        getFeedbackDetailResponse: action.payload.data,
        getFeedbackDetailError: true,
        getFeedbackDetailErrorMsg: action.payload.message,
        getFeedbackDetailResponseStatusCode: action.payload.statusCode
      };

    case ActionType.REVIEW_TRANSLATED_FEEDBACK_REQUEST:
      return {
        ...state,
        reviewTranslatedFeedbackRequesting: true,
        reviewTranslatedFeedbackResponse: [],
        reviewTranslatedFeedbackResponseStatusCode: null,
        reviewTranslatedFeedbackSuccess: false,
        reviewTranslatedFeedbackSuccessMsg: '',
        reviewTranslatedFeedbackError: false,
        reviewTranslatedFeedbackErrorMsg: ''
      };
    case ActionType.REVIEW_TRANSLATED_FEEDBACK_SUCCESS:
      return {
        ...state,
        reviewTranslatedFeedbackRequesting: false,
        reviewTranslatedFeedbackResponse: action.payload.data,
        reviewTranslatedFeedbackResponseStatusCode: action.payload.statusCode,
        reviewTranslatedFeedbackSuccess: true,
        reviewTranslatedFeedbackSuccessMsg: action.payload.message
      };
    case ActionType.REVIEW_TRANSLATED_FEEDBACK_ERROR:
      return {
        ...state,
        reviewTranslatedFeedbackRequesting: false,
        reviewTranslatedFeedbackResponse: action.payload.data,
        reviewTranslatedFeedbackResponseStatusCode: action.payload.statusCode,
        reviewTranslatedFeedbackError: true,
        reviewTranslatedFeedbackErrorMsg: action.payload.message
      };

    case ActionType.GET_ALL_ACTIVE_LANGUAGE_REQUEST:
      return {
        ...state,
        getAllActiveLanguageRequesting: true,
        getAllActiveLanguageResponse: [],
        getAllActiveLanguageSuccess: false,
        getAllActiveLanguageSuccessMsg: '',
        getAllActiveLanguageError: false,
        getAllActiveLanguageErrorMsg: '',
        getAllActiveLanguageResponseStatusCode: null
      };
    case ActionType.GET_ALL_ACTIVE_LANGUAGE_SUCCESS:
      return {
        ...state,
        getAllActiveLanguageRequesting: false,
        getAllActiveLanguageResponse: action.payload.data,
        getAllActiveLanguageSuccess: true,
        getAllActiveLanguageSuccessMsg: action.payload.message,
        getAllActiveLanguageResponseStatusCode: action.payload.statusCode
      };
    case ActionType.GET_ALL_ACTIVE_LANGUAGE_ERROR:
      return {
        ...state,
        getAllActiveLanguageRequesting: false,
        getAllActiveLanguageResponse: action.payload.data,
        getAllActiveLanguageError: true,
        getAllActiveLanguageErrorMsg: action.payload.message,
        getAllActiveLanguageResponseStatusCode: action.payload.statusCode
      };

    case ActionType.RESET_FEEDBACK_MODULE_STATE:
      return {
        ...state,
        reviewTranslatedFeedbackRequesting: false,
        reviewTranslatedFeedbackResponse: [],
        reviewTranslatedFeedbackResponseStatusCode: null,
        reviewTranslatedFeedbackSuccess: false,
        reviewTranslatedFeedbackSuccessMsg: '',
        reviewTranslatedFeedbackError: false,
        reviewTranslatedFeedbackErrorMsg: '',

        assignFeedbackTranslationTaskRequesting: false,
        assignFeedbackTranslationTaskResponse: [],
        assignFeedbackTranslationTaskSuccess: false,
        assignFeedbackTranslationTaskSuccessMsg: '',
        assignFeedbackTranslationTaskError: false,
        assignFeedbackTranslationTaskErrorMsg: '',
        assignFeedbackTranslationTaskResponseStatusCode: null
      };
    default:
      return state;
  }
};

export default reducer;
