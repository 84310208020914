import { ActionType } from '../action-types';
import { Action } from '../actions';

interface IEmailVerificationState {
  emailVerificationRequesting: boolean;
  emailVerificationResponse: any[] | [];
  emailVerificationSuccess: boolean;
  emailVerificationSuccessMsg: string | '';
  emailVerificationError: boolean;
  emailVerificationErrorMsg: string | '';
  emailVerificationResponseStatusCode: number | null;
}

const initialState = {
  emailVerificationRequesting: false,
  emailVerificationResponse: [],
  emailVerificationSuccess: false,
  emailVerificationSuccessMsg: '',
  emailVerificationError: false,
  emailVerificationErrorMsg: '',
  emailVerificationResponseStatusCode: null
};

const reducer = (
  state: IEmailVerificationState = initialState,
  action: Action
): IEmailVerificationState => {
  switch (action.type) {
    case ActionType.EMAIL_VERIFICATION_REQUEST:
      return {
        ...state,
        emailVerificationRequesting: true,
        emailVerificationResponse: [],
        emailVerificationSuccess: false,
        emailVerificationSuccessMsg: '',
        emailVerificationError: false,
        emailVerificationErrorMsg: '',
        emailVerificationResponseStatusCode: null
      };
    case ActionType.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerificationRequesting: false,
        emailVerificationResponse: action.payload.data,
        emailVerificationSuccess: true,
        emailVerificationSuccessMsg: action.payload.message,
        emailVerificationResponseStatusCode: action.payload.statusCode
      };
    case ActionType.EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        emailVerificationRequesting: false,
        emailVerificationResponse: action.payload.data,
        emailVerificationError: true,
        emailVerificationErrorMsg: action.payload.message,
        emailVerificationResponseStatusCode: action.payload.statusCode
      };
    default:
      return state;
  }
};

export default reducer;
