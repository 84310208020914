import { Dispatch } from 'redux';

import { Action } from '../actions';
import { ActionType } from '../action-types';
import { fbnApi as API } from '../../../../../utils/api-handler';

/**********************************/
/*                                */
/* EMAIL VERIFICATION API CREATOR */
/*                                */
/**********************************/

type EmailVerificationRequestDataType = {
  userId?: string;
  verificationCode?: string;
};

export const verifyEmail = ({
  userId,
  verificationCode
}: EmailVerificationRequestDataType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.EMAIL_VERIFICATION_REQUEST
    });

    try {
      const response = await API({
        data: '',
        method: 'PUT',
        url: `/email-verification/${userId}/${verificationCode}`
      });
      dispatch({
        type: ActionType.EMAIL_VERIFICATION_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.EMAIL_VERIFICATION_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};
