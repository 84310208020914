export const TRANSLATIONS_NP = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'घर',
      about: 'बारेमा',
      support: 'समर्थन',
      pricing: 'मूल्य',
      signIn: 'साइन इन',
      signUp: 'साइन अप'
    },
    // banner: {
    //   header: 'प्रतिक्रिया व्यवस्थापन प्रणाली',
    //   subHeader:
    //     'पोलारोन द्वारा संचालित, फिडब्याक नाउ प्रयोग गर्न सजिलो, सुरक्षित र उल्लेखनीय रूपमा लागत-प्रभावी तरिका हो तपाईंको पहुँच विस्तार गर्न र तपाईंको दर्शकहरूको नाडी लिन।',
    //   supportingText:
    //     'FeedbackNow को साथ, तपाईंले कुनै पनि भाषा, कुनै पनि ढाँचा, कुनै पनि समयमा आफ्ना ग्राहकहरूलाई आवाज दिन सक्नुहुन्छ।',
    //   generic: {
    //     getStarted: 'जाऔं'
    //   }
    // },
    banner: {
      header: 'हामीलाई भन्नुहोस् ... तपाईंको भाषामा',
      subHeader:
        'के तपाईलाई थाहा छ कि हाम्रा समुदायहरूमा हामी 270 भन्दा बढी भाषाहरू बोल्छौं, 300 वंशहरूको प्रतिनिधित्व गर्छौं र 100 धर्महरू अभ्यास गर्छौं?',
      supportingText1:
        'FeedbackNow तपाईंको पहुँच विस्तार गर्न र 50+ भाषाहरूमा तपाईंको',
      supportingText2: ' दर्शकहरूको नाडी लिन प्रयोग गर्न-गर्न-सजिलो, सुरक्षित र लागत-प्रभावी तरिका हो।',
      generic: {
        getStarted: 'अब प्रतिक्रिया दिनुहोस्'
      }
    },
    about: {
      header: 'हामीलाई भन्नुहोस् ... तपाईंको भाषामा!',
      supportingText:
        'के तपाईलाई थाहा छ कि हाम्रा समुदायहरूमा हामी 270 भन्दा बढी भाषाहरू बोल्छौं, 300 वंशहरूको प्रतिनिधित्व गर्छौं र 100 धर्महरू अभ्यास गर्छौं?',
      boxOne: 'समापन',
      boxTwo: 'समर्थन',
      boxThree: 'अनुवाद'
    },
    support: {
      supportingText:
        'ती तथ्याङ्कहरूले सुझाव दिन्छ कि विभिन्न भाषाहरूमा प्रतिक्रियाहरू क्याप्चर र व्यवस्थापन गर्नाले तपाईंलाई थप सटीक डाटा सङ्कलन गर्न, प्रामाणिक संलग्नता सिर्जना गर्न र निर्णय प्रक्रियामा सुधार गर्न मद्दत गर्न सक्छ। '
    },
    howItWorks: {
      client: {
        header: 'यसले ग्राहकहरूको लागि कसरी काम गर्छ',
        step_one: 'ग्राहकको रूपमा साइन अप गर्नुहोस्',
        step_two: 'आफ्नो API कुञ्जी प्राप्त गर्नुहोस्',
        step_three:
          'API कुञ्जीको साथ कुनै पनि भाषामा आफ्नो प्रतिक्रिया पठाउनुहोस्',
        step_four: 'अनुवादित प्रतिक्रियाहरू प्राप्त गर्नुहोस्'
      },
      translator: {
        header: 'यसले अनुवादकहरूको लागि कसरी काम गर्छ',
        step_one: 'ग्राहकको रूपमा साइन अप गर्नुहोस्',
        step_two: 'आफ्नो API कुञ्जी प्राप्त गर्नुहोस्',
        step_three:
          'API कुञ्जीको साथ कुनै पनि भाषामा आफ्नो प्रतिक्रिया पठाउनुहोस्',
        step_four: 'अनुवादित प्रतिक्रियाहरू प्राप्त गर्नुहोस्'
      }
    }
  },
  selectLanguage: {
    title: 'आफ्नो भाषा छान्नुहोस्',
    generic: {
      letsGo: 'जाऔं'
    }
  },
  selectFeedbackType: {
    title: 'प्रतिक्रिया प्रकार चयन गर्नुहोस्',
    enterText: 'आफ्नो प्रतिक्रिया प्रविष्ट गर्नुहोस्',
    generic: {
      submit: 'पेश गर्नुहोस्',
      word: 'शब्दहरू',
      send: 'पठाउनुहोस्',
      audioRecorder: 'अडियो रेकर्डर',
      audioFile:'अडियो फाइल',
      videoRecorder:'भिडियो रेकर्डर',
      videoFile:'भिडियो फाइल',
      uploadText:'फाइल अपलोड गर्नुहोस् वा तान्नुहोस् र छोड्नुहोस्',
      uploadAudio:'अडियो अपलोड गर्नुहोस्',
      uploadVideo:'भिडियो अपलोड गर्नुहोस्',
      uploading: 'अपलोड गर्दै',
      startRecording: 'रेकर्डिङ सुरु गर्नुहोस्',
      stopRecording: 'रेकर्डिङ रोक्नुहोस्',
      saveRecording: 'रेकर्डिङ बचत गर्नुहोस्',
      cancelRecording: 'रिकॉर्डिंग रद्द करें',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'कृपया अगाडि बढ्नु अघि आफ्नो प्रतिक्रिया प्रविष्ट गर्नुहोस्। धन्यवाद।',
      errorMessage: 'केहि गलत भयो। फेरि प्रयास गर्नुहोस।',
      successMessage: 'प्रतिक्रिया सफलतापूर्वक थपियो'
    },
    content: {
      completion: 'समापन',
      support: 'समर्थन',
      translation: 'अनुवाद'
    }
  },
  pricingModule: {
    header: 'मूल्य',
    subheader: 'तपाईंको लागि उत्तम योजना छान्नुहोस्',
    supportingText:
      'हाम्रो मूल्य निर्धारण मोडेल लचिलो र नवीन छ, 80% शुल्क सिधै अनुवादकहरूमा जान्छ। चाहे तपाईं ग्रामीण एनजीओ होस्, साना व्यवसाय होस् वा ठूलो स्वास्थ्य सेवा प्रदायक होस्, हामीसँग तपाईंको आवश्यकताहरू पूरा गर्ने योजना छ। थप जानकारी चाहिन्छ? हामीलाई यहाँ सम्पर्क गर्नुहोस्',
    module: {
      title: 'सामाजिक उद्यम मोडेल',
      descriptionLineOne:
        'FeedbackNow ले NAATI-प्रमाणित अनुवादकहरू मार्फत तपाईंको ग्राहकको प्रतिक्रिया अनुवाद गर्छ। तपाइँ तपाइँको ग्राहक प्रतिक्रिया अनुवाद को बारे मा चिन्ता छैन। तपाईंले हामीलाई कुनै पनि भाषामा प्रतिक्रिया पठाउनुभयो र हामी तपाईंको लागि अनुवाद गर्नेछौं।',
      descriptionLineTwo:
        'तपाईंले आफ्नो संगठनको लागि लागत बचत र दक्षताहरू पनि सिर्जना गर्नुहुनेछ।'
    }
  },
  footer: {
    gridOne: {
      title: 'समर्थन',
      listOne: 'मूल्य',
      listTwo: 'कागजात',
      listThree: 'हामीलाई सम्पर्क गर्नुहोस'
    },
    gridTwo: {
      title: 'कम्पनी',
      listOne: 'बारे',
      listTwo: 'ब्लग'
    },
    gridThree: {
      title: 'कानूनी',
      listOne: 'गोपनीयता र नीति',
      listTwo: 'प्रयोगका सर्तहरू'
    },
    gridFour: {
      title: 'आउट न्यूजलेटरको सदस्यता लिनुहोस्',
      listOne:
        'ताजा समाचार, लेख, र स्रोतहरू हरेक हप्ता तपाईंको इनबक्समा डेलिभर गरिन्छ।',
      placeHolder: 'इमेल प्रविष्ट गर्नुहोस्',
      button: 'सदस्यता लिनुहोस्'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. All rights reserved.'
    }
  },
  aboutUsModule: {
    title: 'हाम्रोबारे',
    description:
      'टेक्नोलोजी जतिसुकै उन्नत भए पनि, सही भाषा समाधानका लागि अझै पनि मानव विशेषज्ञता चाहिन्छ। अनुवादकहरू सधैं सेवा प्रदायकहरू र अष्ट्रेलियाका बहुसांस्कृतिक समुदायहरू बीचको महत्त्वपूर्ण लिंक हुनेछन्। विगत २० वर्षदेखि, पोलारोनको भाषा सेवाहरूले हाम्रा ग्राहकहरूलाई हामीले गर्ने सबै कुराको केन्द्रमा राख्दै आएको छ।'
  },
  contactUsModule: {
    title: 'हामीलाई सम्पर्क गर्नुहोस',
    header: 'हामी कसरी मद्दत गर्न सक्छौं?',
    supportingText:
      'कृपया तलको समस्या के हो व्याख्या गर्नुहोस्। हामी 24 घण्टा भित्र जवाफ दिने लक्ष्य राख्छौं। यदि तपाईंको मुद्दा अत्यावश्यक छ भने, कृपया 1300 88 55 61 मा कल गर्नुहोस्।',
    fieldOneLabel: 'तिम्रो नाम',
    fieldOnePlaceholder: 'उदाहरण: John Doe',
    fieldTwoLabel: 'तिम्रो इमेल',
    fieldTwoPlaceholder: 'उदाहरण: john@feedbacknow.com',
    fieldThreeLabel: 'तपाईको समस्या वा प्रतिक्रिया',
    fieldThreePlaceholder: 'यहाँ प्रविष्ट गर्नुहोस्',
    button: 'पेश गर्नुहोस्'
  },
  userInfoForm: {
    title: 'प्रयोगकर्ता जानकारी',
    header: 'यसलाई राख',
    public: 'सार्वजनिक',
    private: 'निजी',
    fieldOneLabel: 'नाम',
    fieldOnePlaceholder: 'उदाहरण: John',
    fieldTwoLabel: 'थर',
    fieldTwoPlaceholder: 'उदाहरण: Doe',
    fieldThreeLabel: 'इमेल',
    fieldThreePlaceholder: 'उदाहरण: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'प्रयोगकर्ता जानकारी आवश्यक छ'
    }
  }
};
