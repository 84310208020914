import { Dispatch } from 'redux';

import { ActionType } from '../action-types';
import {
  Action,
  GetLanguageDetailAction,
  DeleteLanguageAction,
  AddLanguageAction
} from '../actions';
import { fbnApi as API } from '../../../../../../../utils/api-handler';

/*************************************/
/*                                   */
/* GET ALL LANGUAGE LIST API CREATOR */
/*                                   */
/*************************************/

type GetAllLanguageListRequestDataType = {
  page: number;
  limit: number;
};

export const getAllLanguageList = ({
  page,
  limit
}: GetAllLanguageListRequestDataType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.GET_ALL_LANGUAGE_LIST_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/languages?page=${page}&perpage=${limit}`
        }
      );

      dispatch({
        type: ActionType.GET_ALL_LANGUAGE_LIST_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_LANGUAGE_LIST_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later'
        }
      });
    }
  };
};

/***********************************/
/*                                 */
/* GET LANGUAGE DETAIL API CREATOR */
/*                                 */
/***********************************/

type GetLanguageDetailRequestDataType = {
  token: string;
  userId: string;
  languageId: string;
};

export const getLanguageDetail = ({
  token,
  userId,
  languageId
}: GetLanguageDetailRequestDataType) => {
  return async (dispatch: Dispatch<GetLanguageDetailAction>) => {
    dispatch({
      type: ActionType.GET_LANGUAGE_DETAIL_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/language/${languageId}/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_LANGUAGE_DETAIL_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_LANGUAGE_DETAIL_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/***********************************/
/*                                 */
/* DELETE LANGUAGE API CREATOR */
/*                                 */
/***********************************/

type DeleteLanguageRequestDataType = {
  token: string;
  userId: string;
  languageId: string;
};

export const deleteLanguage = ({
  token,
  userId,
  languageId
}: DeleteLanguageRequestDataType) => {
  return async (dispatch: Dispatch<DeleteLanguageAction>) => {
    dispatch({
      type: ActionType.DELETE_LANGUAGE_REQUEST
    });
    try {
      const response = await API(
        {
          data: '',
          method: 'DELETE',
          url: `/language/${languageId}/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.DELETE_LANGUAGE_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.DELETE_LANGUAGE_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/***********************************/
/*                                 */
/* ADD LANGUAGE API CREATOR */
/*                                 */
/***********************************/

type AddLanguageRequestDataType = {
  token: string;
  userId: string;
  name: string;
  code: string;
};

export const addLanguage = ({
  token,
  userId,
  name,
  code
}: AddLanguageRequestDataType) => {
  return async (dispatch: Dispatch<AddLanguageAction>) => {
    dispatch({
      type: ActionType.ADD_LANGUAGE_REQUEST
    });
    try {
      const response = await API(
        {
          data: {
            name,
            code
          },
          method: 'POST',
          url: `/language/create/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.ADD_LANGUAGE_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.ADD_LANGUAGE_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};
