import { ActionType } from '../action-types';
import { Action } from '../actions';

interface IRegisterState {
  registerRequesting: boolean;
  registerResponse: string[];
  registerSuccess: boolean;
  registerSuccessMsg: string | null;
  registerError: boolean;
  registerErrorMsg: string | null;
  registerResponseStatusCode: number | null;
}

const initialState = {
  registerRequesting: false,
  registerResponse: [],
  registerSuccess: false,
  registerSuccessMsg: '',
  registerError: false,
  registerErrorMsg: '',
  registerResponseStatusCode: null
};

const reducer = (
  state: IRegisterState = initialState,
  action: Action
): IRegisterState => {
  switch (action.type) {
    case ActionType.REGISTER_REQUEST:
      return {
        ...state,
        registerRequesting: true,
        registerResponse: [],
        registerSuccess: false,
        registerSuccessMsg: '',
        registerError: false,
        registerErrorMsg: '',
        registerResponseStatusCode: null
      };

    case ActionType.REGISTER_SUCCESS:
      return {
        ...state,
        registerRequesting: false,
        registerResponse: action.payload.data,
        registerSuccess: true,
        registerSuccessMsg: action.payload.message,
        registerResponseStatusCode: action.payload.statusCode
      };

    case ActionType.REGISTER_ERROR:
      return {
        ...state,
        registerRequesting: false,
        registerResponse: action.payload.data,
        registerError: true,
        registerErrorMsg: action.payload.message,
        registerResponseStatusCode: action.payload.statusCode
      };

    default:
      return state;
  }
};

export default reducer;
