import { Dispatch } from 'redux';

import { ActionType } from '../action-types';
import {
  GetAllAssignedFeedbackListAction,
  GetFeedbackDetailAction,
  UpdateShowFeedbackOnWebsiteAction
} from '../actions';
import { fbnApi as API } from '../../../../../../../utils/api-handler';

/**********************************************/
/*                                            */
/* GET ALL ASSIGNED FEEDBACK LIST API CREATOR */
/*                                            */
/**********************************************/

type GetAllAssignedFeedbackListRequestDataType = {
  page: number;
  limit: number;
  userId: string;
  token: string;
};
export const getAllAssignedFeedbackList = ({
  userId,
  token,
  page,
  limit
}: GetAllAssignedFeedbackListRequestDataType) => {
  return async (dispatch: Dispatch<GetAllAssignedFeedbackListAction>) => {
    dispatch({
      type: ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/client/feedbacks/${userId}?page=${page}&perpage=${limit}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later'
        }
      });
    }
  };
};

/********************************************/
/*                                          */
/* GET FEEDBACK DETAIL API CREATOR */
/*                                          */
/********************************************/

type GetFeedbackDetailRequestDataType = {
  token: string;
  userId: string;
  feedbackId: string;
};

export const getFeedbackDetail = ({
  token,
  userId,
  feedbackId
}: GetFeedbackDetailRequestDataType) => {
  return async (dispatch: Dispatch<GetFeedbackDetailAction>) => {
    dispatch({
      type: ActionType.GET_FEEDBACK_DETAIL_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/client/feedback/${feedbackId}/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_FEEDBACK_DETAIL_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_FEEDBACK_DETAIL_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/********************************************/
/*                                          */
/* UPDATE SHOW FEEDBACK ON WEBSITE API CREATOR */
/*                                          */
/********************************************/

type UpdateShowFeedbackOnWebsiteRequestDataType = {
  token: string;
  userId: string;
  feedbackId: string;
  showFeedbackOnWebsite: boolean;
};

export const updateShowFeedbackOnWebsite = ({
  token,
  userId,
  feedbackId,
  showFeedbackOnWebsite
}: UpdateShowFeedbackOnWebsiteRequestDataType) => {
  console.log("showFeedbackOnWebsite", showFeedbackOnWebsite);
  return async (dispatch: Dispatch<UpdateShowFeedbackOnWebsiteAction>) => {
    dispatch({
      type: ActionType.UPDATE_SHOW_FEEDBACK_ON_WEBSITE_REQUEST
    });

    try {
      const response = await API(
        {
          data: {showFeedbackOnWebsite},
          method: 'PUT',
          url: `/client/feedback/${feedbackId}/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.UPDATE_SHOW_FEEDBACK_ON_WEBSITE_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.UPDATE_SHOW_FEEDBACK_ON_WEBSITE_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};