export const TRANSLATIONS_IT = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'Home',
      about: 'Chi siamo',
      support: 'Supporto',
      pricing: 'Prezzi',
      signIn: 'Accedi',
      signUp: 'Iscriviti'
    },
    // banner: {
    //   header: 'Sistema di gestione del feedback',
    //   subHeader:
    //     'Alimentato da Polaron, Feedback Now è un modo facile da usare, sicuro e notevolmente conveniente per espandere la tua portata e prendere il polso del tuo pubblico',
    //   supportingText:
    //     'Con FeedbackNow puoi dare voce ai tuoi clienti in qualsiasi lingua, in qualsiasi formato e in qualsiasi momento.',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: 'Dillo…nella tua lingua!',
      subHeader:
        'Sapevi che nelle nostre comunità parliamo oltre 270 lingue, rappresentiamo 300 antenati e pratichiamo 100 religioni?',
      supportingText1:
        'FeedbackNow è un modo facile, sicuro e conveniente di espandere',
      supportingText2: 'il tuo raggio d’azione e capire cosa pensa il tuo pubblico in più di 50 lingue.',
      generic: {
        getStarted: 'Dai un feedback ora'
      }
    },
    about: {
      header: 'Raccontacelo... nella tua lingua!',
      supportingText:
        'Sapevi che nelle nostre comunità parliamo oltre 270 lingue, rappresentiamo 300 antenati e pratichiamo 100 religioni?',
      boxOne: 'Completamento',
      boxTwo: 'Supporto',
      boxThree: 'Traduzione'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'Come funziona per i clienti',
        step_one: 'Iscriviti come cliente',
        step_two: 'Ottieni la tua chiave API',
        step_three: 'Invia il tuo feedback in qualsiasi lingua insieme alla chiave API',
        step_four: 'Ottieni la traduzione del feedback'
      },
      translator: {
        header: 'Come funziona per i traduttori',
        step_one: 'Iscriviti come traduttore',
        step_two: 'Carica i tuoi certificati NAATI per la verifica',
        step_three: 'Traduci i feedback assegnati',
        step_four: 'Ricevi i pagamenti'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'Seleziona il tipo di feedback',
    enterText: 'Inserisci il tuo feedback',
    generic: {
      submit: 'Invia',
      word: 'parole',
      send: 'Send',
      audioRecorder: 'Registratore audio',
      audioFile:'File audio',
      videoRecorder:'Registratore video',
      videoFile:'File video',
      uploadText:'Carica un file o trascinalo ',
      uploadAudio:'Carica l’audio',
      uploadVideo:'Carica il video',
      uploading: 'Caricamento in corso',
      startRecording: 'Inizia la registrazione',
      stopRecording: 'Interrompi la registrazione',
      saveRecording: 'Salva la registrazione',
      cancelRecording: 'Cancella la registrazione',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'Per favore, inserisci il tuo feedback prima di procedere. Grazie ',
      errorMessage: 'Qualcosa è andato storto. Per favore riprova.',
      successMessage: 'Feedback aggiunto con successo'
    },
    content: {
      completion: 'Completamento',
      support: 'Supporto',
      translation: 'Traduzione'
    }
  },
  pricingModule: {
    header: 'Prezzi',
    subheader: 'Seleziona il piano migliore per te',
    supportingText:
      "Il nostro modello tariffario è flessibile e innovativo, con l'80% delle commissioni che vanno direttamente ai traduttori. Che tu sia una ONG rurale, una piccola impresa o un grande operatore sanitario, abbiamo un piano adatto alle tue esigenze. Hai bisogno di maggiori informazioni? Contattaci qui",
    module: {
      title: 'Modello di impresa sociale',
      descriptionLineOne:
        'FeedbackNow traduce il feedback dei tuoi clienti tramite traduttori certificati NAATI. Non devi preoccuparti di tradurre il feedback dei tuoi clienti. Ci hai inviato feedback in qualsiasi lingua e noi faremo la traduzione per te.',
      descriptionLineTwo:
        'Creerai anche risparmi sui costi ed efficienze per la tua organizzazione.'
    }
  },
  footer: {
    gridOne: {
      title: 'SUPPORTO',
      listOne: 'Prezzi',
      listTwo: 'Documentazione',
      listThree: 'Contattaci'
    },
    gridTwo: {
      title: 'AZIENDA',
      listOne: 'Chi siamo',
      listTwo: 'Blog'
    },
    gridThree: {
      title: 'LEGALE',
      listOne: 'Privacy e politica',
      listTwo: 'Termini e Condizioni'
    },
    gridFour: {
      title: 'ISCRIVITI ALLA NOSTRA NEWSLETTER',
      listOne:
        'Le ultime notizie, gli articoli e le risorse inviate direttamente alla tua inbox ogni settimana.',
      placeHolder: "Inserisci l’e-mail",
      button: 'Iscriviti'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. Tutti i diritti riservati.'
    }
  },
  aboutUsModule: {
    title: 'Chi siamo',
    description:
      "Indipendentemente dai progressi tecnologici, soluzioni linguistiche accurate necessitano ancora dell’expertise umana. I traduttori saranno sempre un importante tramite tra i fornitori di servizi e le comunità multiculturali australiane. Negli ultimi 20 anni, noi di Polaron’s language services abbiamo sempre messo i clienti al centro di tutto ciò che facciamo…"
  },
  contactUsModule: {
    title: 'Contattaci',
    header: 'Come possiamo aiutarti?',
    supportingText:
      'Spiegaci qui sotto qual è il tuo problema. Cercheremo di rispondere entro 24 ore. Se il tuo problema è urgente, per favore chiama il 1300 88 55 61.',
    fieldOneLabel: 'Il tuo nome',
    fieldOnePlaceholder: 'Esempio: John Doe',
    fieldTwoLabel: 'La tua e-mail',
    fieldTwoPlaceholder: 'Esempio: john@feedbacknow.com',
    fieldThreeLabel: 'Il tuo problema o il tuo feedback',
    fieldThreePlaceholder: 'entra qui',
    button: 'Invio'
  },
  userInfoForm: {
    title: "Informazioni sull’utente",
    header: 'Tienilo ',
    public: 'Pubblico',
    private: 'Privato',
    fieldOneLabel: 'Nome',
    fieldOnePlaceholder: 'Esempio: John',
    fieldTwoLabel: 'Cognome',
    fieldTwoPlaceholder: 'Esempio: Doe',
    fieldThreeLabel: 'E-mail',
    fieldThreePlaceholder: 'Esempio: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'Richiesta di informazioni dell’utente'
    }
  }
};
