import { Dispatch } from 'redux';

import { ActionType } from '../action-types';
import {
  GetAllAssignedFeedbackListAction,
  GetAssignedFeedbackDetailAction,
  SubmitTranslatedFeedbackAction
} from '../actions';
import { fbnApi as API } from '../../../../../../../utils/api-handler';

/**********************************************/
/*                                            */
/* GET ALL ASSIGNED FEEDBACK LIST API CREATOR */
/*                                            */
/**********************************************/

type GetAllAssignedFeedbackListRequestDataType = {
  page: number;
  limit: number;
  userId: string;
  token: string;
};
export const getAllAssignedFeedbackList = ({
  userId,
  token,
  page,
  limit
}: GetAllAssignedFeedbackListRequestDataType) => {
  return async (dispatch: Dispatch<GetAllAssignedFeedbackListAction>) => {
    dispatch({
      type: ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/translator/feedbacks/${userId}?page=${page}&perpage=${limit}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later'
        }
      });
    }
  };
};

/********************************************/
/*                                          */
/* GET ASSIGNED FEEDBACK DETAIL API CREATOR */
/*                                          */
/********************************************/

type GetAssignedFeedbackDetailRequestDataType = {
  token: string;
  userId: string;
  feedbackId: string;
};

export const getAssignedFeedbackDetail = ({
  token,
  userId,
  feedbackId
}: GetAssignedFeedbackDetailRequestDataType) => {
  return async (dispatch: Dispatch<GetAssignedFeedbackDetailAction>) => {
    dispatch({
      type: ActionType.GET_ASSIGNED_FEEDBACK_DETAIL_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/translator/feedback/${feedbackId}/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_ASSIGNED_FEEDBACK_DETAIL_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ASSIGNED_FEEDBACK_DETAIL_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/******************************************/
/*                                        */
/* SUBMIT TRANSLATED FEEDBACK API CREATOR */
/*                                        */
/******************************************/

type SubmitTranslatedFeedbackRequestDataType = {
  token: string;
  userId: string;
  feedbackId: string;
  translatedText: string;
};

export const submitTranslatedFeedback = ({
  token,
  userId,
  feedbackId,
  translatedText
}: SubmitTranslatedFeedbackRequestDataType) => {
  return async (dispatch: Dispatch<SubmitTranslatedFeedbackAction>) => {
    dispatch({
      type: ActionType.SUBMIT_TRANSLATED_FEEDBACK_REQUEST
    });
    try {
      const response = await API(
        {
          data: {
            translatedText
          },
          method: 'PUT',
          url: `/translator/feedback/${feedbackId}/${userId}`
        },
        token
      );
      dispatch({
        type: ActionType.SUBMIT_TRANSLATED_FEEDBACK_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.SUBMIT_TRANSLATED_FEEDBACK_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later'
        }
      });
    }
  };
};
