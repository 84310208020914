export enum ActionType {
  /*************************************/
  /*                                   */
  /* GET ALL FEEDBACK LIST ACTION TYPE */
  /*                                   */
  /*************************************/

  GET_ALL_FEEDBACK_LIST_REQUEST = 'get_all_feedback_list_request',
  GET_ALL_FEEDBACK_LIST_SUCCESS = 'get_all_feedback_list_success',
  GET_ALL_FEEDBACK_LIST_ERROR = 'get_all_feedback_list_error',

  /************************************************/
  /*                                              */
  /* ASSIGN FEEDBACK TRANSLATION TASK ACTION TYPE */
  /*                                              */
  /************************************************/

  ASSIGN_FEEDBACK_TRANSLATION_TASK_REQUEST = 'assign_feedback_translation_task_request',
  ASSIGN_FEEDBACK_TRANSLATION_TASK_SUCCESS = 'assign_feedback_translation_task_success',
  ASSIGN_FEEDBACK_TRANSLATION_TASK_ERROR = 'assign_feedback_translation_task_error',

  /*****************************************/
  /*                                       */
  /* GET ALL ACTIVE TRANSLATOR ACTION TYPE */
  /*                                       */
  /*****************************************/

  GET_ALL_ACTIVE_TRANSLATOR_REQUEST = 'get_all_active_translator_request',
  GET_ALL_ACTIVE_TRANSLATOR_SUCCESS = 'get_all_active_translator_success',
  GET_ALL_ACTIVE_TRANSLATOR_ERROR = 'get_all_active_translator_error',

  /*************************************/
  /*                                   */
  /* GET ALL ACTIVE CLIENT ACTION TYPE */
  /*                                   */
  /*************************************/

  GET_ALL_ACTIVE_CLIENT_REQUEST = 'get_all_active_client_request',
  GET_ALL_ACTIVE_CLIENT_SUCCESS = 'get_all_active_client_success',
  GET_ALL_ACTIVE_CLIENT_ERROR = 'get_all_active_client_error',

  /***************************************/
  /*                                     */
  /* GET ALL ACTIVE LANGUAGE ACTION TYPE */
  /*                                     */
  /***************************************/

  GET_ALL_ACTIVE_LANGUAGE_REQUEST = 'get_all_active_language_request',
  GET_ALL_ACTIVE_LANGUAGE_SUCCESS = 'get_all_active_language_success',
  GET_ALL_ACTIVE_LANGUAGE_ERROR = 'get_all_active_language_error',

  /***********************************/
  /*                                 */
  /* GET FEEDBACK DETAIL ACTION TYPE */
  /*                                 */
  /***********************************/

  GET_FEEDBACK_DETAIL_REQUEST = 'get_feedback_detail_request',
  GET_FEEDBACK_DETAIL_SUCCESS = 'get_feedback_detail_success',
  GET_FEEDBACK_DETAIL_ERROR = 'get_feedback_detail_error',

  /*******************************************/
  /*                                         */
  /* REVIEW TRANSLATED FEEDBACK ACTION TYPE  */
  /*                                         */
  /*******************************************/

  REVIEW_TRANSLATED_FEEDBACK_REQUEST = 'review_translated_feedback_request',
  REVIEW_TRANSLATED_FEEDBACK_SUCCESS = 'review_translated_feedback_success',
  REVIEW_TRANSLATED_FEEDBACK_ERROR = 'review_translated_feedback_error',

  /***************************/
  /*                         */
  /* RESET STATE ACTION TYPE */
  /*                         */
  /***************************/

  RESET_FEEDBACK_MODULE_STATE = 'reset_feedback_module_state'
}
