export enum ActionType {
  /********************************************/
  /*                                            */
  /* GET ALL ASSIGNED FEEDBACK LIST ACTION TYPE */
  /*                                            */
  /**********************************************/
  GET_ALL_ASSIGNED_FEEDBACK_LIST_REQUEST = 'get_all_assigned_feedback_list_request',
  GET_ALL_ASSIGNED_FEEDBACK_LIST_SUCCESS = 'get_all_assigned_feedback_list_success',
  GET_ALL_ASSIGNED_FEEDBACK_LIST_ERROR = 'get_all_assigned_feedback_list_error',

  /********************************************/
  /*                                          */
  /* GET ASSIGNED FEEDBACK DETAIL ACTION TYPE */
  /*                                          */
  /********************************************/
  GET_ASSIGNED_FEEDBACK_DETAIL_REQUEST = 'get_assigned_feedback_detail_request',
  GET_ASSIGNED_FEEDBACK_DETAIL_SUCCESS = 'get_assigned_feedback_detail_success',
  GET_ASSIGNED_FEEDBACK_DETAIL_ERROR = 'get_assigned_feedback_detail_error',

  /******************************************/
  /*                                        */
  /* SUBMIT TRANSLATED FEEDBACK ACTION TYPE */
  /*                                        */
  /******************************************/
  SUBMIT_TRANSLATED_FEEDBACK_REQUEST = 'submit_translated_feedback_request',
  SUBMIT_TRANSLATED_FEEDBACK_SUCCESS = 'submit_translated_feedback_success',
  SUBMIT_TRANSLATED_FEEDBACK_ERROR = 'submit_translated_feedback_error'
}
