import { Dispatch } from 'redux';

import { Action } from '../actions';
import { ActionType } from '../action-types';
import { fbnApi as API } from '../../../../../utils/api-handler';

type RegisterRequestDataType = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
};

export const register = ({
  email,
  password,
  firstName,
  lastName,
  role
}: RegisterRequestDataType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.REGISTER_REQUEST
    });

    try {
      const response = await API({
        data: {
          email,
          password,
          firstName,
          lastName,
          role
        },
        method: 'POST',
        url: `/signup`
      });

      dispatch({
        type: ActionType.REGISTER_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.REGISTER_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};
