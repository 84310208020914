import { Dispatch } from 'redux';

import { Action } from '../actions';
import { ActionType } from '../action-types';
import { fbnApi as API } from '../../../../../utils/api-handler';
import { saveItem } from '../../../../../utils/local-storage';
import { Action as AuthAction } from '../../../../../redux-manager/cache/auth-info/actions';
import { ActionType as AuthActionType } from '../../../../../redux-manager/cache/auth-info/action-types';

type LoginRequestDataType = {
  email: string;
  password: string;
};

export const login = ({ email, password }: LoginRequestDataType) => {
  return async (dispatch: Dispatch<Action | AuthAction>) => {
    dispatch({
      type: ActionType.LOGIN_REQUEST
    });

    try {
      const response = await API({
        data: {
          email,
          password
        },
        method: 'POST',
        url: `/signin`
      });

      dispatch({
        type: ActionType.LOGIN_SUCCESS,
        payload: response
      });

      saveItem('@userinfo', response.data[0]);

      dispatch({
        type: AuthActionType.SET_USER_INFO,
        payload: response.data[0]
      });
    } catch (error) {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong.'
        }
      });
    }
  };
};

export const resetLoginPreviousState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_LOGIN_PAGE_PREVIOUS_STATE
    });
  };
};
