export const TRANSLATIONS_PT = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'Home',
      about: 'Sobre nós ',
      support: 'Apoiar',
      pricing: 'Preços',
      signIn: 'Entrar',
      signUp: 'Cadastre-se'
    },
    // banner: {
    //   header: 'Sistema de gerenciamento de comentários',
    //   subHeader:
    //     'Desenvolvido pela Polaron, o Feedback Now é uma maneira fácil de usar, segura e extremamente econômica de expandir seu alcance e tomar o pulso de seu público',
    //   supportingText:
    //     'Com o Feedback Now, você pode dar voz aos seus clientes em qualquer idioma, em qualquer formato, a qualquer hora.',
    //   generic: {
    //     getStarted: ''
    //   }
    // },
    banner: {
      header: 'Diga-nos… na sua língua',
      subHeader:
        'Você sabia que em nossas comunidades falamos mais de 270 idiomas, representamos 300 ancestrais e praticamos 100 religiões?',
      supportingText1:
        'FeedbackNow proporciona uma maneira fácil, segura e barata',
      supportingText2: 'de expandir seu alcance e conhecer seu público em mais de 50 línguas. ',
      generic: {
        getStarted: 'Dê feedback agora'
      }
    },
    about: {
      header: 'Diga-nos… na sua língua!',
      supportingText:
        'Você sabia que em nossas comunidades falamos mais de 270 idiomas, representamos 300 ancestrais e praticamos 100 religiões?',
      boxOne: 'Conclusão',
      boxTwo: 'Apoiar',
      boxThree: 'Tradução'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'Como funciona para os clientes',
        step_one: 'Cadastre-se como cliente',
        step_two: 'Obtenha sua chave de API',
        step_three: 'Envie seus comentários em qualquer idioma junto com a chave API',
        step_four: 'Receba comentários traduzidos'
      },
      translator: {
        header: 'Como funciona para tradutores',
        step_one: 'Cadastre-se como tradutor',
        step_two: 'Faça upload do seu certificado NAATI e seja verificado',
        step_three: 'Traduza os comentários enviados a você',
        step_four: 'Receba por seu trabalho'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'Selecione o tipo de comentário',
    enterText: 'Digite seu comentário',
    generic: {
      submit: 'Enviar',
      word: 'palavras',
      send: 'Enviar',
      audioRecorder: 'Gravador de áudio',
      audioFile:'Arquivo de áudio',
      videoRecorder:'Gravador de video',
      videoFile:'Arquivo de vídeo',
      uploadText:'Carregue o arquivo ou arraste-o e solte aqui',
      uploadAudio:'Carregue o Áudio',
      uploadVideo:'Carregue o vídeo',
      uploading: 'Enviando',
      startRecording: 'Comece a gravar',
      stopRecording: 'Pare de gravar',
      saveRecording: 'Salvar gravação',
      cancelRecording: 'Cancelar gravação',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'Insira seu comentário antes de prosseguir. Obrigado. ',
      errorMessage: 'Algo deu errado. Por favor, tente novamente.',
      successMessage: 'Comentário enviado com sucesso.'
    },
    content: {
      completion: 'Conclusão',
      support: 'Apoiar',
      translation: 'Tradução'
    }
  },
  pricingModule: {
    header: 'Preços',
    subheader: 'Escolha o melhor plano pra você',
    supportingText:
      'Nosso modelo de preços é flexível e inovador, com 80% dos honorários indo diretamente para os tradutores. Quer seja uma ONG rural, uma pequena empresa ou um grande prestador de cuidados de saúde, temos um plano adequado às suas necessidades. Precisa de mais informação? Contacte-nos aqui',
    module: {
      title: 'Modelo de Empresa Social',
      descriptionLineOne:
        'O FeedbackNow traduz o feedback do cliente por meio de tradutores certificados pela NAATI. Você não precisa se preocupar em traduzir o feedback do cliente. Você nos enviou feedback em qualquer idioma e faremos a tradução para você.',
      descriptionLineTwo:
        'Você também estará criando economia de custos e eficiências para sua organização.'
    }
  },
  footer: {
    gridOne: {
      title: 'APOIAR',
      listOne: 'Preços',
      listTwo: 'Documentação',
      listThree: 'Entre em Contato'
    },
    gridTwo: {
      title: 'EMPRESA',
      listOne: 'Sobre nós',
      listTwo: 'Blog'
    },
    gridThree: {
      title: 'JURÍDICO',
      listOne: 'Privacidade e política',
      listTwo: 'Termos e Condições'
    },
    gridFour: {
      title: 'ASSINE A NOSSA NEWSLETTER',
      listOne:
        'Receba as últimas notícias, artigos e recursos enviados para sua caixa de entrada semanalmente.',
      placeHolder: 'ASSINAR',
      button: 'Se inscrever'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. Todos os direitos reservados.'
    }
  },
  aboutUsModule: {
    title: 'Sobre nós',
    description:
      'Não importa o quanto a tecnologia avance, precisão na soluções de linguagem ainda exige experiência humana. Os tradutores sempre serão um elo vital entre os provedores de serviços e as comunidades multiculturais da Austrália. Nos últimos 20 anos, os serviços de idiomas da Polaron colocam nossos clientes no centro de tudo o que fazemos…'
  },
  contactUsModule: {
    title: 'Entre em Contato',
    header: 'Como podemos ajudar?',
    supportingText:
      'Explique qual é o problema abaixo. Nosso objetivo é responder dentro de 24 horas. Se o seu problema for urgente, ligue para 1300 88 55 61.',
    fieldOneLabel: 'Seu Nome',
    fieldOnePlaceholder: 'Exemplo: John Doe',
    fieldTwoLabel: 'Seu E-mail',
    fieldTwoPlaceholder: 'Exemplo: john@feedbacknow.com',
    fieldThreeLabel: 'Seu problema ou Comentário',
    fieldThreePlaceholder: 'Entre aqui',
    button: 'Enviar'
  },
  userInfoForm: {
    title: 'Informação do usuário',
    header: 'Mantênha meu comentário',
    public: 'Público',
    private: 'Privado',
    fieldOneLabel: 'Primeiro Nome',
    fieldOnePlaceholder: 'Exemplo: John',
    fieldTwoLabel: 'Último Nome',
    fieldTwoPlaceholder: 'Exemplo: Doe',
    fieldThreeLabel: 'E-mail',
    fieldThreePlaceholder: 'Exemplo: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'Informação do usuário requerida'
    }
  }
};
