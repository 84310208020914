import { Dispatch } from 'redux';

import { Action } from '../actions';
import { ActionType } from '../action-types';

interface ILocalizationProps {
  localeCode: string;
}

export const setLocale = (payload: ILocalizationProps) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_LOCALE,
      payload
    });
  };
};
