import { ActionType } from '../action-types';
import {
  GetAllAssignedFeedbackListAction,
  GetAssignedFeedbackDetailAction,
  SubmitTranslatedFeedbackAction
} from '../actions';

interface ITranslatorFeedbackState {
  getAllAssignedFeedbackListRequesting: boolean;
  getAllAssignedFeedbackListResponse: any | [];
  getAllAssignedFeedbackListSuccess: boolean;
  getAllAssignedFeedbackListSuccessMsg: string | '';
  getAllAssignedFeedbackListError: boolean;
  getAllAssignedFeedbackListErrorMsg: string | '';
  getAllAssignedFeedbackListResponseStatusCode: number | null;

  getAssignedFeedbackDetailRequesting: boolean;
  getAssignedFeedbackDetailResponse: any | [];
  getAssignedFeedbackDetailSuccess: boolean;
  getAssignedFeedbackDetailSuccessMsg: string | '';
  getAssignedFeedbackDetailError: boolean;
  getAssignedFeedbackDetailErrorMsg: string | '';
  getAssignedFeedbackDetailResponseStatusCode: number | null;

  submitTranslatedFeedbackRequesting: boolean;
  submitTranslatedFeedbackResponse: any | [];
  submitTranslatedFeedbackSuccess: boolean;
  submitTranslatedFeedbackSuccessMsg: string | '';
  submitTranslatedFeedbackError: boolean;
  submitTranslatedFeedbackErrorMsg: string | '';
  submitTranslatedFeedbackResponseStatusCode: number | null;
}

const initialState = {
  getAllAssignedFeedbackListRequesting: false,
  getAllAssignedFeedbackListResponse: [],
  getAllAssignedFeedbackListSuccess: false,
  getAllAssignedFeedbackListSuccessMsg: '',
  getAllAssignedFeedbackListError: false,
  getAllAssignedFeedbackListErrorMsg: '',
  getAllAssignedFeedbackListResponseStatusCode: null,

  getAssignedFeedbackDetailRequesting: false,
  getAssignedFeedbackDetailResponse: [],
  getAssignedFeedbackDetailSuccess: false,
  getAssignedFeedbackDetailSuccessMsg: '',
  getAssignedFeedbackDetailError: false,
  getAssignedFeedbackDetailErrorMsg: '',
  getAssignedFeedbackDetailResponseStatusCode: null,

  submitTranslatedFeedbackRequesting: false,
  submitTranslatedFeedbackResponse: [],
  submitTranslatedFeedbackSuccess: false,
  submitTranslatedFeedbackSuccessMsg: '',
  submitTranslatedFeedbackError: false,
  submitTranslatedFeedbackErrorMsg: '',
  submitTranslatedFeedbackResponseStatusCode: null
};

const reducer = (
  state: ITranslatorFeedbackState = initialState,
  action:
    | GetAllAssignedFeedbackListAction
    | GetAssignedFeedbackDetailAction
    | SubmitTranslatedFeedbackAction
): ITranslatorFeedbackState => {
  switch (action.type) {
    case ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_REQUEST:
      return {
        ...state,
        getAllAssignedFeedbackListRequesting: true,
        getAllAssignedFeedbackListResponse: [],
        getAllAssignedFeedbackListSuccess: false,
        getAllAssignedFeedbackListSuccessMsg: '',
        getAllAssignedFeedbackListError: false,
        getAllAssignedFeedbackListErrorMsg: '',
        getAllAssignedFeedbackListResponseStatusCode: null
      };
    case ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        getAllAssignedFeedbackListRequesting: false,
        getAllAssignedFeedbackListResponse: action.payload.data,
        getAllAssignedFeedbackListSuccess: true,
        getAllAssignedFeedbackListSuccessMsg: action.payload.message,
        getAllAssignedFeedbackListResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_ALL_ASSIGNED_FEEDBACK_LIST_ERROR:
      return {
        ...state,
        getAllAssignedFeedbackListRequesting: false,
        getAllAssignedFeedbackListResponse: action.payload.data,
        getAllAssignedFeedbackListError: true,
        getAllAssignedFeedbackListErrorMsg: action.payload.message,
        getAllAssignedFeedbackListResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_ASSIGNED_FEEDBACK_DETAIL_REQUEST:
      return {
        ...state,
        getAssignedFeedbackDetailRequesting: true,
        getAssignedFeedbackDetailResponse: [],
        getAssignedFeedbackDetailSuccess: false,
        getAssignedFeedbackDetailSuccessMsg: '',
        getAssignedFeedbackDetailError: false,
        getAssignedFeedbackDetailErrorMsg: '',
        getAssignedFeedbackDetailResponseStatusCode: null
      };
    case ActionType.GET_ASSIGNED_FEEDBACK_DETAIL_SUCCESS:
      return {
        ...state,
        getAssignedFeedbackDetailRequesting: false,
        getAssignedFeedbackDetailResponse: action.payload.data,
        getAssignedFeedbackDetailSuccess: true,
        getAssignedFeedbackDetailSuccessMsg: action.payload.message,
        getAssignedFeedbackDetailResponseStatusCode: action.payload.statusCode
      };

    case ActionType.GET_ASSIGNED_FEEDBACK_DETAIL_ERROR:
      return {
        ...state,
        getAssignedFeedbackDetailRequesting: false,
        getAssignedFeedbackDetailResponse: action.payload.data,
        getAssignedFeedbackDetailError: true,
        getAssignedFeedbackDetailErrorMsg: action.payload.message,
        getAssignedFeedbackDetailResponseStatusCode: action.payload.statusCode
      };

    case ActionType.SUBMIT_TRANSLATED_FEEDBACK_REQUEST:
      return {
        ...state,
        submitTranslatedFeedbackRequesting: true,
        submitTranslatedFeedbackResponse: [],
        submitTranslatedFeedbackSuccess: false,
        submitTranslatedFeedbackSuccessMsg: '',
        submitTranslatedFeedbackError: false,
        submitTranslatedFeedbackErrorMsg: '',
        submitTranslatedFeedbackResponseStatusCode: null
      };
    case ActionType.SUBMIT_TRANSLATED_FEEDBACK_SUCCESS:
      return {
        ...state,
        submitTranslatedFeedbackRequesting: false,
        submitTranslatedFeedbackResponse: action.payload.data,
        submitTranslatedFeedbackSuccess: true,
        submitTranslatedFeedbackSuccessMsg: action.payload.message,
        submitTranslatedFeedbackResponseStatusCode: action.payload.statusCode
      };

    case ActionType.SUBMIT_TRANSLATED_FEEDBACK_ERROR:
      return {
        ...state,
        submitTranslatedFeedbackRequesting: false,
        submitTranslatedFeedbackResponse: action.payload.data,
        submitTranslatedFeedbackError: true,
        submitTranslatedFeedbackErrorMsg: action.payload.message,
        submitTranslatedFeedbackResponseStatusCode: action.payload.statusCode
      };
    default:
      return state;
  }
};

export default reducer;
