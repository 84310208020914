export enum ActionType {
  /*****************/
  /*               */
  /* SET USER INFO */
  /*               */
  /*****************/
  SET_USER_INFO = 'set_user_info',

  /*******************/
  /*                 */
  /* CLEAR USER INFO */
  /*                 */
  /*******************/
  CLEAR_USER_INFO = 'clear_user_info'
}
