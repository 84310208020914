export enum ActionType {
  /************************************************/
  /*                                              */
  /* GET ALL TRANSLATOR NOTIFICATIONS ACTION TYPE */
  /*                                              */
  /************************************************/

  GET_ALL_TRANSLATOR_NOTIFICATIONS_REQUEST = 'get_all_translator_notifications_request',
  GET_ALL_TRANSLATOR_NOTIFICATIONS_SUCCESS = 'get_all_translator_notifications_success',
  GET_ALL_TRANSLATOR_NOTIFICATIONS_ERROR = 'get_all_translator_notifications_error'
}
