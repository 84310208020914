export const TRANSLATIONS_AR = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'الصفحة الرئيسية',
      about: 'بخصوص',
      support: 'يدعم',
      pricing: 'التسعير',
      signIn: 'تسجيل الدخول',
      signUp: 'التسجيل'
    },
    // banner: {
    //   header: 'نظام إدارة الملاحظات',
    //   subHeader:
    //     'مدعوم من Polaron ، يعد Feedback Now طريقة سهلة الاستخدام وآمنة وفعالة من حيث التكلفة لتوسيع نطاق وصولك والاستفادة من جمهوركb',
    //   supportingText:
    //     'مع FeedbackNow ، يمكنك منح عملائك صوتًا بأي لغة وبأي تنسيق وفي أي وقت.',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: 'أخبرنا... بلغتك!',
      subHeader:
      'هل تعلم أنه في مجتمعاتنا نتحدث أكثر من 270 لغة ، ونمثل 300 سلالة ونمارس 100 ديانة؟',
      supportingText1:
      'FeedbackNow هي طريقة سهلة الاستخدام وآمنة وفعالة من حيث التكلفة ',
      supportingText2: 'لتوسيع نطاق وصولك وأخذ نبض جمهورك بأكثر من  50لغة.',
      generic: {
        getStarted: 'قدم ملاحظاتك الآن'
      }
    },
    about: {
      header: 'أخبرنا ... بلغتك!',
      supportingText:
        'هل تعلم أنه في مجتمعاتنا نتحدث أكثر من 270 لغة ، ونمثل 300 سلالة ونمارس 100 ديانة؟',
      boxOne: 'انتهاء',
      boxTwo: 'يدعم',
      boxThree: 'ترجمة'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'كيف تعمل بالنسبة للزبائن',
        step_one: 'قم بالتسجيل كزبون',
        step_two: 'احصل على مفتاح رمزي API',
        step_three: 'أرسل ملاحظاتك بأي لغة مع مفتاح رمزي  API ',
        step_four: 'احصل على تعليقات مترجمة'
      },
      translator: {
        header: 'كيف تعمل بالنسبة للمترجمين الكتابيين',
        step_one: 'قم بالتسجيل كمترجم كتابي',
        step_two: 'قم بتحميل شهادات هيئة الاعتماد الوطنية للمترجمين الشفهيين والكتابيين (NAATI)  الخاصة بك واحصل على تصديق',
        step_three: 'ترجمة التعليقات المعيّنة',
        step_four: 'يتقاضون رواتبهم'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'اختر نوع الملاحظات',
    enterText: 'أدخل ملاحظاتك',
    generic: {
      submit: 'أرسل',
      word: 'كلمات',
      send: 'إرسال',
      audioRecorder: 'مسجِّل صوتي',
      audioFile:'ملف صوتي',
      videoRecorder:'مسجِّل فيديو',
      videoFile:'ملف فيديو',
      uploadText:'تحميل الملف أو سحبه وإفلاته',
      uploadAudio:'تحميل صوتي',
      uploadVideo:'تحميل الفيديو',
      uploading: 'تحميل',
      startRecording: 'بدء التسجيل',
      stopRecording: 'إيقاف التسجيل',
      saveRecording: 'حفظ التسجيل',
      cancelRecording: 'إلغاء التسجيل',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'يرجى أن تدخل ملاحظاتك قبل المتابعة. شكرًا لك.',
      errorMessage: 'حدث خطأ ما. يرجى أن تحاول مرة أخرى.',
      successMessage: 'تمّت إضافة التعليقات بنجاح.'
    },
    content: {
      completion: 'انتهاء',
      support: 'يدعم',
      translation: 'ترجمة'
    }
  },
  pricingModule: {
    header: 'التسعير',
    subheader: 'اختر أفضل خطة لك',
    supportingText:
      'نموذج التسعير الخاص بنا مرن ومبتكر ، حيث يذهب 80٪ من الرسوم مباشرة إلى المترجمين. سواء كنت منظمة غير حكومية ريفية أو شركة صغيرة أو مقدم رعاية صحية كبير ، فلدينا خطة تناسب احتياجاتك. تحتاج المزيد من المعلومات؟ اتصل بنا هنا',
    module: {
      title: 'نموذج المؤسسة الاجتماعية',
      descriptionLineOne:
        'يترجم الآن ملاحظات العملاء عبر مترجمين معتمدين من NAATI. لا داعي للقلق بشأن ترجمة ملاحظات العملاء. لقد أرسلت إلينا ملاحظاتك بأي لغة وسنقوم بالترجمة نيابة عنك.',
      descriptionLineTwo:
        'ستعمل أيضًا على توفير التكاليف والكفاءات لمؤسستك.'
    }
  },
  footer: {
    gridOne: {
      title: 'الدعم',
      listOne: 'التسعير',
      listTwo: 'التوثيق',
      listThree: 'اتصل بنا'
    },
    gridTwo: {
      title: 'الشركة',
      listOne: 'بخصوص',
      listTwo: 'المدوّنة'
    },
    gridThree: {
      title: 'قانوني',
      listOne: 'الخصوصية والسياسة',
      listTwo: 'الأحكام والشروط'
    },
    gridFour: {
      title: 'اشترك في نشرتنا الإخبارية',
      listOne:
        'آخر الأخبار والمقالات والموارد، يتمّ إرسالها إلى صندوق بريدك الاكتروني أسبوعيا.',
      placeHolder: 'أدخل البريد الالكتروني',
      button: 'اشترك'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. كل الحقوق محفوظة.'
    }
  },
  aboutUsModule: {
    title: 'بخصوصنا',
    description:
      'بغض النظر عن مدى تقدم التكنولوجيا، لا تزال الحلول اللغوية الدقيقة تتطلب خبرة بشرية. سيكون المترجمون الكتابيون دائما حلقة وصل حيوية بين مقدمي الخدمات والمجتمعات المتعددة الثقافات في أستراليا. على مدى السنوات الـ 20 الماضية، كانت خدمات Polaron اللغوية تضع زبائننا في صميم كل ما نقوم به ...'
  },
  contactUsModule: {
    title: 'اتصل بنا',
    header: 'كيف يمكننا المساعدة؟',
    supportingText:
      'يرجى توضيح المشكلة أدناه. نسعى إلى الرد في غضون 24 ساعة. إذا كانت مشكلتك عاجلة، فيرجى الاتصال بالرقم 1300 88 55 61.',
    fieldOneLabel: 'اسمك',
    fieldOnePlaceholder: 'مثل: John Doe',
    fieldTwoLabel: 'بريدك الالكتروني',
    fieldTwoPlaceholder: 'مثل: john@feedbacknow.com',
    fieldThreeLabel: 'مشكلتك أو ملاحظاتك',
    fieldThreePlaceholder: 'أدخل هنا',
    button: 'أرسل'
  },
  userInfoForm: {
    title: 'معلومات المستخدم',
    header: 'احتفظ بها',
    public: 'علناً',
    private: 'سراً',
    fieldOneLabel: 'الاسم الأول',
    fieldOnePlaceholder: 'مثل: John',
    fieldTwoLabel: 'اسم العائلة',
    fieldTwoPlaceholder: 'مثل: Doe',
    fieldThreeLabel: 'البريد الالكتروني',
    fieldThreePlaceholder: 'مثل: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'معلومات المستخدم المطلوبة'
    }
  }
};
