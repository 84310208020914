import axios from 'axios';
import { Dispatch } from 'redux';

import { Action } from '../actions';
import { ActionType } from '../action-types';
import { fbnApi as API } from '../../../../../../../../../utils/api-handler';

/**************************/
/*                        */
/* UPLOAD DOCUMENT ACTION */
/*                        */
/**************************/

type UploadDocumentRequestDataType = {
  userId: string;
  token: string;
  expiryDate: any;
  language: string;
  practicionerId: string;
  document: any;
};

export const uploadDocument = ({
  userId,
  token,
  expiryDate,
  language,
  practicionerId,
  document
}: UploadDocumentRequestDataType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPLOAD_DOCUMENT_REQUEST
    });

    try {
      const formData = new FormData();

      document.map((imageObj: any) => {
        if (imageObj) {
          formData.append('document', imageObj);
        }
      });
      formData.append('practicionerId', practicionerId);
      formData.append('expiryDate', expiryDate);
      formData.append('language', language);

      const response = await axios.post(
        `https://feedbacknow.services/api/translator/document/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      dispatch({
        type: ActionType.UPLOAD_DOCUMENT_SUCCESS,
        payload: {
          data: response.data.data[0],
          statusCode: 200,
          message: 'Document Uploaded.'
        }
      });
    } catch (error) {
      dispatch({
        type: ActionType.UPLOAD_DOCUMENT_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later'
        }
      });
    }
  };
};

/*************************/
/*                       */
/* GET UPLOADED DOCUMENT */
/*                       */
/*************************/

type GetUploadedDocumentRequestDataType = {
  userId: string;
  token: string;
};
export const getUploadedDocument = ({
  userId,
  token
}: GetUploadedDocumentRequestDataType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.GET_UPLOADED_DOCUMENT_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/translator/documents/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_UPLOADED_DOCUMENT_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_UPLOADED_DOCUMENT_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later'
        }
      });
    }
  };
};

export const resetProfileInfoPreviousState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_TRANSLATOR_PROFILE_INFO_PREVIOUS_STATE
    });
  };
};
