import { Dispatch } from 'redux';

import { Action } from '../actions';
import { ActionType } from '../action-types';
import { clearAllItem } from '../../../../utils/local-storage';

type remarksObj = {
  email: string;
  document: string;
};

type UserInfoDataType = {
  _id: string;
  role: string;
  email: string;
  isEmailVerified: boolean;
  lastName: string;
  firstName: string;
  remarks: remarksObj;
  document: any[];
  secretKey: string;
};

interface IUser {
  token: string;
  user: UserInfoDataType;
}

export const saveUserInfo = (value: IUser) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_USER_INFO,
      payload: value
    });
  };
};

export const clearUserInfo = () => {
  return async (dispatch: Dispatch<Action>) => {
    clearAllItem();
    dispatch({
      type: ActionType.CLEAR_USER_INFO
    });
  };
};
