export const TRANSLATIONS_GR = {
  welcome: 'Welcome to the tutorial',
  home: {
    nav: {
      home: 'Αρχική Σελίδα',
      about: 'Σχετικά με',
      support: 'Υποστήριξη',
      pricing: 'Τιμολόγηση',
      signIn: 'Σύνδεση',
      signUp: 'Εγγραφή'
    },
    // banner: {
    //   header: 'Σύστημα Διαχείρισης Ανατροφοδότησης',
    //   subHeader:
    //     'Με την υποστήριξη της Polaron, το Feedback Now είναι ένας εύκολος στη χρήση, ασφαλής και αξιοσημείωτα οικονομικά αποδοτικός τρόπος για να επεκτείνετε την απήχησή σας και να πάρετε τον παλμό του κοινού σας',
    //   supportingText:
    //     'Με το FeedbackNow, μπορείτε να δώσετε στους πελάτες σας φωνή σε οποιαδήποτε γλώσσα, οποιαδήποτε μορφή, οποιαδήποτε στιγμή.',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: 'Πείτε μας … στη γλώσσα σας!',
      subHeader:
        'Το FeedbackNow είναι ένας εύχρηστος, ασφαλής και οικονομικός τρόπος για να επεκτείνετε την απήχησή σας',
      supportingText1:
        'FeedbackNow είναι ένας εύκολος στη χρήση, ασφαλής και οικονομικά αποδοτικός',
        supportingText2: 'τρόπος να διευρύνετε τον ορίζοντά σας και να σφυγμομετρήσετε το ακροατήριό σας σε 50+ γλώσσες.',
      generic: {
        getStarted: 'Δώστε σχόλια τώρα'
      }
    },
    about: {
      header: 'Πες μας… στη γλώσσα σου!',
      supportingText:
        'Γνωρίζατε ότι στις κοινότητές μας μιλάμε περισσότερες από 270 γλώσσες, αντιπροσωπεύουμε 300 καταβολές και ασκούμε 100 θρησκείες;',
      boxOne: 'Ολοκλήρωση',
      boxTwo: 'Υποστήριξη',
      boxThree: 'Μετάφραση'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: 'Πώς λειτουργεί για τους πελάτες',
        step_one: 'Εγγραφείτε ως πελάτης',
        step_two: 'Λάβετε το δικό σας κλειδί API',
        step_three: 'Στείλτε τα σχόλιά σας σε οποιαδήποτε γλώσσα μαζί με το κλειδί API',
        step_four: 'Λάβετε μεταφρασμένα σχόλια'
      },
      translator: {
        header: 'Πώς λειτουργεί για τους μεταφραστές',
        step_one: 'Εγγραφείτε ως μεταφραστής',
        step_two: 'Μεταφορτώστε τα πιστοποιητικά σας της Εθνικής Υπηρεσίας Πιστοποίησης Μεταφραστών και Διερμηνέων (NAATI) για επιβεβαίωση',
        step_three: 'Μεταφράστε τα καθορισμένα σχόλια',
        step_four: 'Πληρωθείτε '
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: 'Επιλέξτε τον Τύπο Σχολίων ',
    enterText: 'Εισαγάγετε τα Σχόλιά Σας',
    generic: {
      submit: 'Υποβολή',
      word: 'λέξεις',
      send: 'Send',
      audioRecorder: 'Συσκευή Ηχογράφησης',
      audioFile:'Ηχητικό Αρχείο',
      videoRecorder:'Συσκευή Βιντεοσκόπησης',
      videoFile:'Αρχείο Βίντεο',
      uploadText:'Μεταφόρτωση αρχείου ή σύρσιμο και απόθεση',
      uploadAudio:'Μεταφόρτωση Ακουστικού Υλικού',
      uploadVideo:'Μεταφόρτωση Βίντεο',
      uploading: 'Μεταφόρτωση',
      startRecording: 'Αρχίστε την Καταγραφή',
      stopRecording: 'Σταματήστε την Καταγραφή',
      saveRecording: 'Αποθηκεύστε την Καταγραφή',
      cancelRecording: 'Ακυρώστε την Καταγραφή',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        'Παρακαλούμε εισαγάγετε τα σχόλιά σας πριν να προχωρήσετε. Σας ευχαριστούμε.',
      errorMessage: 'Κάτι πήγε λάθος. Παρακαλούμε ξαναπροσπαθήστε',
      successMessage: 'Τα σχόλια προστέθηκαν επιτυχώς.'
    },
    content: {
      completion: 'Ολοκλήρωση',
      support: 'Υποστήριξη',
      translation: 'Μετάφραση'
    }
  },
  pricingModule: {
    header: 'Τιμολόγηση',
    subheader: 'Επιλέξτε το καλύτερο πρόγραμμα για εσάς',
    supportingText:
      'Το μοντέλο τιμολόγησης μας είναι ευέλικτο και καινοτόμο, με το 80% των αμοιβών να πηγαίνει απευθείας στους μεταφραστές. Είτε είστε αγροτική ΜΚΟ, μικρή επιχείρηση ή μεγάλος πάροχος υγειονομικής περίθαλψης, έχουμε ένα σχέδιο που ταιριάζει στις ανάγκες σας. Χρειάζεστε περισσότερες πληροφορίες; Επικοινωνήστε μαζί μας εδώ',
    module: {
      title: 'Μοντέλο Κοινωνικής Επιχείρησης',
      descriptionLineOne:
        'Το FeedbackNow μεταφράζει τα σχόλια των πελατών σας μέσω μεταφραστών πιστοποιημένων από το NAATI. Δεν χρειάζεται να ανησυχείτε για τη μετάφραση των σχολίων των πελατών σας. Μας στείλατε σχόλια σε οποιαδήποτε γλώσσα και θα κάνουμε τη μετάφραση για εσάς.',
      descriptionLineTwo:
        'Θα δημιουργήσετε επίσης εξοικονόμηση κόστους και αποδοτικότητα για τον οργανισμό σας.'
    }
  },
  footer: {
    gridOne: {
      title: 'ΥΠΟΣΤΗΡΙΞΗ',
      listOne: 'Τιμολόγηση',
      listTwo: 'Τεκμηρίωση',
      listThree: 'Επικοινωνήστε Μαζί Μας'
    },
    gridTwo: {
      title: 'ΕΤΑΙΡΕΙΑ',
      listOne: 'Σχετικά με',
      listTwo: 'Ιστολόγιο [Μπλογκ]'
    },
    gridThree: {
      title: 'ΝΟΜΙΚΟΣ',
      listOne: 'Απόρρητο και Πολιτική',
      listTwo: 'Οροι και Προϋποθέσεις'
    },
    gridFour: {
      title: 'ΓΙΝΕΤΕ ΣΥΝΔΡΟΜΗΤΗΣ ΣΤΟ ΕΝΗΜΕΡΩΤΙΚΟ ΔΕΛΤΙΟ ΜΑΣ',
      listOne:
        'Τα τελευταία νέα, άρθρα και πόροι, αποστέλλονται στα εισερχόμενά σας κάθε εβδομάδα.',
      placeHolder: 'Εισαγάγετε email',
      button: 'Γίνετε Συνδρομητής'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow. Με επιφύλαξη παντός δικαιώματος.'
    }
  },
  aboutUsModule: {
    title: 'Σχετικά με εμάς',
    description:
      'Ανεξάρτητα από το μέγεθος  ανάπτυξης της τεχνολογίας, οι λύσεις για την ακρίβεια της γλώσσας απαιτούν ακόμα την ανθρώπινη πραγματογνωμοσύνη. Οι μεταφραστές θα είναι πάντα ένας ζωτικός σύνδεσμος μεταξύ των παρόχων υπηρεσιών και των πολυπολιτισμικών κοινοτήτων της Αυστραλίας. Κατά την διάρκεια των τελευταίων 20 ετών, οι γλωσσικές υπηρεσίες της Polaron θέτουν τους πελάτες μας στο επίκεντρο όλων όσων κάνουμε…'
  },
  contactUsModule: {
    title: 'Επικοινωνήστε Μαζί Μας',
    header: 'Πώς μπορούμε να βοηθήσουμε;',
    supportingText:
      'Παρακαλείστε να εξηγήσετε το ζήτημα παρακάτω. Στοχεύουμε να απαντήσουμε εντός 24 ωρών. Εάν το ζήτημά σας είναι επείγον, παρακαλείστε να καλέσετε το 1300 88 55 61.',
    fieldOneLabel: 'Το Όνομά Σας',
    fieldOnePlaceholder: 'Παράδειγμα: John Doe',
    fieldTwoLabel: 'Η Διεύθυνση Email Σας',
    fieldTwoPlaceholder: 'Παράδειγμα: john@feedbacknow.com',
    fieldThreeLabel: 'Το Ζήτημα ή τα Σχόλιά Σας',
    fieldThreePlaceholder: 'Μπείτε εδώ',
    button: 'Υποβολή'
  },
  userInfoForm: {
    title: 'Στοιχεία Χρήστη',
    header: 'τηρήστε τα',
    public: 'Δημόσια',
    private: 'Ιδιωτικά',
    fieldOneLabel: 'Όνομα',
    fieldOnePlaceholder: 'Παράδειγμα: John',
    fieldTwoLabel: 'Επώνυμο',
    fieldTwoPlaceholder: 'Παράδειγμα: Doe',
    fieldThreeLabel: 'Email',
    fieldThreePlaceholder: 'Παράδειγμα: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: 'Απαιτούνται Στοιχεία Χρήστη'
    }
  }
};
