export const TRANSLATIONS_ZH_CAN = {
  welcome: 'Welcome to the Chinese traditional(cantonese) tutorial',
  home: {
    nav: {
      home: '首頁',
      about: '關於我們',
      support: '支持',
      pricing: '定價',
      signIn: '登錄',
      signUp: '註冊'
    },
    // banner: {
    //   header: '反饋管理系統',
    //   subHeader:
    //     '在 Polaron 的支持下，Feedback Now 是一種易於使用、安全且極具成本效益的方式，可以擴大您的影響範圍並掌握您的受眾的脈搏',
    //   supportingText:
    //     '借助 FeedbackNow，您可以隨時以任何語言、任何格式向客戶表達意見。',
    //   generic: {
    //     getStarted: 'Get Started'
    //   }
    // },
    banner: {
      header: '用您的母語告訴我們！',
      subHeader:
        '您知道嗎，在我們的社區中，我們講 270 多種語言，代表 300 個血統，信奉 100 種宗教？',
      supportingText1:
        'FeedbackNow是一種易用、安全且經濟高效的方式，',
      supportingText2: '可以擴大您的影響範圍並以 50 多種語言把握您受眾的需求。',
      generic: {
        getStarted: '立即反饋'
      }
    },
    about: {
      header: '告訴我們……用您的語言！',
      supportingText:
        '您知道嗎，在我們的社區中，我們講 270 多種語言，代表 300 個血統，信奉 100 種宗教？',
      boxOne: '完成',
      boxTwo: '支持',
      boxThree: '翻譯'
    },
    support: {
      supportingText:
        'Those statistics suggest that capturing and managing responses in different languages could help you collect more accurate data, create authentic engagement and improve decision making. '
    },
    howItWorks: {
      client: {
        header: '對客戶來說，它是如何工作的',
        step_one: '註冊成為客戶',
        step_two: '獲取您的API金鑰',
        step_three: '使用任何語言及 API 金鑰發送您的回饋資訊',
        step_four: '獲得翻譯回饋'
      },
      translator: {
        header: '對譯員來說，它是如何工作的',
        step_one: '註冊成為譯員',
        step_two: '上傳並驗證您的NAATI 證書',
        step_three: '翻譯指定的回饋意見',
        step_four: '獲得報酬'
      }
    }
  },
  selectLanguage: {
    title: 'Choose Your Language.',
    generic: {
      letsGo: "Let's Go"
    }
  },
  selectFeedbackType: {
    title: '選擇回饋類型',
    enterText: '輸入您的回饋意見',
    generic: {
      submit: '提交',
      word: '文字',
      send: '提交',
      audioRecorder: '答錄機',
      audioFile:'音訊檔',
      videoRecorder:'錄影機',
      videoFile:'視頻檔',
      uploadText:'上傳或拖放文件',
      uploadAudio:'上傳音訊檔',
      uploadVideo:'上傳視頻檔',
      uploading: '上傳中',
      startRecording: '開始錄製',
      stopRecording: '停止錄製',
      saveRecording: '保存檔',
      cancelRecording: '取消錄製',
      acceptRecording: '',
      processing: '',
      startingIn: '',
      recordingNow: '',
      recordingDeleted: '',
    }
  },
  thankyou: {
    title: 'Thank you!',
    message: 'We have received your feedback.',
  },
  generic: {
    message: {
      fieldValidation:
        '請輸入您的回饋，然後繼續下一步。謝謝。',
      errorMessage: '出錯了，請再試一次。',
      successMessage: '回饋資訊添加成功。',
    },
    content: {
      completion: '完成',
      support: '支持',
      translation: '翻譯'
    }
  },
  pricingModule: {
    header: '定價',
    subheader: '選擇最適合您的計畫',
    supportingText:
      '我們的定價模式靈活且創新，80% 的費用直接支付給譯員。 無論您是農村非政府組織、小型企業還是大型醫療保健提供者，我們都有適合您需求的計劃。 需要更多信息？ 在這里聯系我們',
    module: {
      title: '社會企業模式',
      descriptionLineOne:
        'FeedbackNow 通過 NAATI 認證的翻譯人員翻譯您的客戶反饋。 您不必擔心翻譯您的客戶反饋。 您以任何語言向我們發送反饋，我們將為您進行翻譯。',
      descriptionLineTwo:
        '您還將為您的組織節省成本並提高效率。'
    }
  },
  footer: {
    gridOne: {
      title: '支持',
      listOne: '定價',
      listTwo: '文件',
      listThree: '聯繫我們'
    },
    gridTwo: {
      title: '公司簡介',
      listOne: '關於我們',
      listTwo: '博客'
    },
    gridThree: {
      title: '合法的',
      listOne: '隐私和政策',
      listTwo: '条款和条件'
    },
    gridFour: {
      title: '訂閱我們的新聞',
      listOne:
        '最新的新聞、文章和資源，每週發送至您的收件箱。',
      placeHolder: '輸入電子郵件',
      button: '訂閱'
    },
    copyRight: {
      lineOne: '2024 FeedbackNow。版權所有。'
    }
  },
  aboutUsModule: {
    title: '關於我們',
    description:
      '無論技術如何發展，準確的語言解決方案仍然需要人類的專業知識。譯員將始終是服務提供者與澳大利亞多元文化社區之間的重要紐帶。在過去的 20 年裡，Polaron 語言服務一直把客戶放在我們一切工作的核心位置......'
  },
  contactUsModule: {
    title: '聯繫我們',
    header: '我們能提供什麼幫助？',
    supportingText:
      '請在下面說明您的問題。我們通常會在 24 小時內回復。如果您的問題很緊急，請致電 1300 88 55 61。',
    fieldOneLabel: '您的名字',
    fieldOnePlaceholder: '例如: John Doe',
    fieldTwoLabel: '您的電子郵件',
    fieldTwoPlaceholder: '例如: john@feedbacknow.com',
    fieldThreeLabel: '您的問題或回饋',
    fieldThreePlaceholder: '在这里输入',
    button: '提交'
  },
  userInfoForm: {
    title: '使用者資訊',
    header: '保留',
    public: '公開',
    private: '不公開',
    fieldOneLabel: '名字',
    fieldOnePlaceholder: '例如: John',
    fieldTwoLabel: '姓氏',
    fieldTwoPlaceholder: '例如: Doe',
    fieldThreeLabel: '電子郵件',
    fieldThreePlaceholder: '例如: john@feedbacknow.com',
    generic: {
      fieldValidationMessage: '使用者資訊必填'
    }
  }
};
