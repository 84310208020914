import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_ZH } from './cn';
import { TRANSLATIONS_EN } from './us';
import { TRANSLATIONS_ES } from './es';
import { TRANSLATIONS_PL } from './pl';
import { TRANSLATIONS_NP } from './np';

import { TRANSLATIONS_ZH_CAN } from './ca';
import { TRANSLATIONS_AR } from './ar';
import { TRANSLATIONS_FI } from './fi';
import { TRANSLATIONS_GR } from './gr';
import { TRANSLATIONS_HI } from './hi';
import { TRANSLATIONS_IT } from './it';
import { TRANSLATIONS_KR } from './kr';
import { TRANSLATIONS_PU } from './pu';
import { TRANSLATIONS_UR } from './ur';
import { TRANSLATIONS_VI } from './vi';
import { TRANSLATIONS_FR } from './fr';
import { TRANSLATIONS_PT } from './pt';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      us: {
        translation: TRANSLATIONS_EN
      },
      cn: {
        translation: TRANSLATIONS_ZH
      },
      es: {
        translation: TRANSLATIONS_ES
      },
      pl: {
        translation: TRANSLATIONS_PL
      },
      np: {
        translation: TRANSLATIONS_NP
      },

      ca: {
        translation: TRANSLATIONS_ZH_CAN
      },
      ar: {
        translation: TRANSLATIONS_AR
      },
      fi: {
        translation: TRANSLATIONS_FI
      },
      gr: {
        translation: TRANSLATIONS_GR
      },
      hi: {
        translation: TRANSLATIONS_HI
      },

      it: {
        translation: TRANSLATIONS_IT
      },
      kr: {
        translation: TRANSLATIONS_KR
      },
      pu: {
        translation: TRANSLATIONS_PU
      },
      ur: {
        translation: TRANSLATIONS_UR
      },
      vi: {
        translation: TRANSLATIONS_VI
      },
      fr: {
        translation: TRANSLATIONS_FR
      },
      pt: {
        translation: TRANSLATIONS_PT
      }
    }
  });

i18n.changeLanguage('us');
