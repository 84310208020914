import { Dispatch } from 'redux';

import { ActionType } from '../action-types';
import {
  VerifyTranslatorAction,
  GetAllTranslatorListAction,
  GetTranslatorDetailAction,
  RejectTranslatorAction,
  RejectDocumentAction,
  ResetAction,
  VerifyDocumentAction
} from '../actions';

import { fbnApi as API } from '../../../../../../../utils/api-handler';

/***************************************/
/*                                     */
/* GET ALL TRANSLATOR LIST API CREATOR */
/*                                     */
/***************************************/

type GetAllTranslatorRequestDataType = {
  userId: string;
  token: string;
  page: number;
  limit: number;
};

export const getAllTranslatorList = ({
  userId,
  token,
  page,
  limit
}: GetAllTranslatorRequestDataType) => {
  return async (dispatch: Dispatch<GetAllTranslatorListAction>) => {
    dispatch({
      type: ActionType.GET_ALL_TRANSLATOR_LIST_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/admin/translators/${userId}?page=${page}&perpage=${limit}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_ALL_TRANSLATOR_LIST_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_TRANSLATOR_LIST_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/*************************************/
/*                                   */
/* GET TRANSLATOR DETAIL API CREATOR */
/*                                   */
/*************************************/

type GetTranslatorDetailRequestDataType = {
  userId: string;
  token: string;
  translatorId: string;
};

export const getTranslatorDetail = ({
  userId,
  token,
  translatorId
}: GetTranslatorDetailRequestDataType) => {
  return async (dispatch: Dispatch<GetTranslatorDetailAction>) => {
    dispatch({
      type: ActionType.GET_TRANSLATOR_DETAIL_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'GET',
          url: `/admin/translator/${translatorId}/${userId}`
        },
        token
      );

      dispatch({
        type: ActionType.GET_TRANSLATOR_DETAIL_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_TRANSLATOR_DETAIL_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/*********************************/
/*                               */
/* VERIFY TRANSLATOR API CREATOR */
/*                               */
/*********************************/

type VerifyTranslatorRequestDataType = {
  userId: string;
  token: string;
  translatorId: string;
};

export const verifyTranslator = ({
  userId,
  token,
  translatorId
}: VerifyTranslatorRequestDataType) => {
  return async (dispatch: Dispatch<VerifyTranslatorAction>) => {
    dispatch({
      type: ActionType.VERIFY_TRANSLATOR_REQUEST
    });

    try {
      const response = await API(
        {
          data: '',
          method: 'PUT',
          url: `/admin/verify-translator/${translatorId}/${userId}`
        },
        token
      );
      dispatch({
        type: ActionType.VERIFY_TRANSLATOR_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.VERIFY_TRANSLATOR_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/*********************************/
/*                               */
/* REJECT TRANSLATOR API CREATOR */
/*                               */
/*********************************/

type RejectTranslatorRequestDataType = {
  userId: string;
  token: string;
  remarks: string;
  translatorId: string;
};

export const rejectTranslator = ({
  userId,
  token,
  remarks,
  translatorId
}: RejectTranslatorRequestDataType) => {
  return async (dispatch: Dispatch<RejectTranslatorAction>) => {
    dispatch({
      type: ActionType.REJECT_TRANSLATOR_REQUEST
    });

    try {
      const response = await API(
        {
          data: {
            remarks
          },
          method: 'PUT',
          url: `/admin/reject-translator/${translatorId}/${userId}`
        },
        token
      );
      dispatch({
        type: ActionType.REJECT_TRANSLATOR_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.REJECT_TRANSLATOR_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

type VerifyDocumentRequestDataType = {
  userId: string;
  token: string;
  documentId: string;
};

export const verifyDocument = ({
  userId,
  token,
  documentId
}: VerifyDocumentRequestDataType) => {
  return async (dispatch: Dispatch<VerifyDocumentAction>) => {
    dispatch({
      type: ActionType.VERIFY_DOCUMENT_REQUEST
    });

    try {
      const response = await API(
        {
          // data: {
          //   remarks
          // },
          method: 'PUT',
          url: `/admin/verify-document/${documentId}/${userId}`
        },
        token
      );
      dispatch({
        type: ActionType.VERIFY_DOCUMENT_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.VERIFY_DOCUMENT_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

/*********************************/
/*                               */
/* REJECT DOCUMENT API CREATOR */
/*                               */
/*********************************/

type RejectDocumentRequestDataType = {
  userId: string;
  token: string;
  remarks: string;
  documentId: string;
};

export const rejectDocument = ({
  userId,
  token,
  remarks,
  documentId
}: RejectDocumentRequestDataType) => {
  return async (dispatch: Dispatch<RejectDocumentAction>) => {
    dispatch({
      type: ActionType.REJECT_DOCUMENT_REQUEST
    });

    try {
      const response = await API(
        {
          data: {
            remarks
          },
          method: 'PUT',
          url: `/admin/reject-document/${documentId}/${userId}`
        },
        token
      );
      dispatch({
        type: ActionType.REJECT_DOCUMENT_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ActionType.REJECT_DOCUMENT_ERROR,
        payload: {
          data: [],
          statusCode: 400,
          message: 'Something went wrong. Please Try Again Later.'
        }
      });
    }
  };
};

export const resetTranslatorDetailInfoPreviousState = () => {
  return async (dispatch: Dispatch<ResetAction>) => {
    dispatch({
      type: ActionType.RESET_TRANSLATOR_DETAIL_INFO_PREVIOUS_STATE
    });
  };
};
