export enum ActionType {
  /***************************************/
  /*                                     */
  /* GET ALL TRANSLATOR LIST ACTION TYPE */
  /*                                     */
  /***************************************/

  GET_ALL_TRANSLATOR_LIST_REQUEST = 'get_all_translator_list_request',
  GET_ALL_TRANSLATOR_LIST_SUCCESS = 'get_all_translator_list_success',
  GET_ALL_TRANSLATOR_LIST_ERROR = 'get_all_translator_list_error',

  /*************************************/
  /*                                   */
  /* GET TRANSLATOR DETAIL ACTION TYPE */
  /*                                   */
  /*************************************/

  GET_TRANSLATOR_DETAIL_REQUEST = 'get_translator_detail_request',
  GET_TRANSLATOR_DETAIL_SUCCESS = 'get_translator_detail_success',
  GET_TRANSLATOR_DETAIL_ERROR = 'get_translator_detail_error',

  /*********************************/
  /*                               */
  /* VERIFY TRANSLATOR ACTION TYPE */
  /*                               */
  /*********************************/

  VERIFY_TRANSLATOR_REQUEST = 'verify_translator_request',
  VERIFY_TRANSLATOR_SUCCESS = 'verify_translator_success',
  VERIFY_TRANSLATOR_ERROR = 'verify_translator_error',

  /*********************************/
  /*                               */
  /* REJECT TRANSLATOR ACTION TYPE */
  /*                               */
  /*********************************/

  REJECT_TRANSLATOR_REQUEST = 'reject_translator_request',
  REJECT_TRANSLATOR_SUCCESS = 'reject_translator_success',
  REJECT_TRANSLATOR_ERROR = 'reject_translator_error',

  /*******************************/
  /*                             */
  /* VERIFY DOCUMENT ACTION TYPE */
  /*                             */
  /*******************************/

  VERIFY_DOCUMENT_REQUEST = 'verify_document_request',
  VERIFY_DOCUMENT_SUCCESS = 'verify_document_success',
  VERIFY_DOCUMENT_ERROR = 'verify_document_error',

  /*********************************/
  /*                               */
  /* REJECT DOCUMENT ACTION TYPE */
  /*                               */
  /*********************************/

  REJECT_DOCUMENT_REQUEST = 'reject_document_request',
  REJECT_DOCUMENT_SUCCESS = 'reject_document_success',
  REJECT_DOCUMENT_ERROR = 'reject_document_error',

  /************************************/
  /*                                  */
  /* RESET PREVIOUS STATE ACTION TYPE */
  /*                                  */
  /************************************/

  RESET_TRANSLATOR_DETAIL_INFO_PREVIOUS_STATE = 'reset_translator_detail_info_previous_state'
}
