export enum ActionType {
  /**************************************8****/
  /*                                         */
  /* GET ALL CLIENT NOTIFICATIONS ACTION TYPE */
  /*                                         */
  /*******************************************/

  GET_ALL_CLIENT_NOTIFICATIONS_REQUEST = 'get_all_client_notifications_request',
  GET_ALL_CLIENT_NOTIFICATIONS_SUCCESS = 'get_all_client_notifications_success',
  GET_ALL_CLIENT_NOTIFICATIONS_ERROR = 'get_all_client_notifications_error'
}
