export enum ActionType {
  SEND_FEEDBACK_REQUEST = 'send_feedback_request',
  SEND_FEEDBACK_SUCCESS = 'send_feedback_success',
  SEND_FEEDBACK_ERROR = 'send_feedback_error',

  SEND_AUDIO_VIDEO_FEEDBACK_REQUEST = 'send_audio_video_feedback_request',
  SEND_AUDIO_VIDEO_FEEDBACK_SUCCESS = 'send_audio_video_feedback_success',
  SEND_AUDIO_VIDEO_FEEDBACK_FAILURE = 'send_audio_video_feedback_failure',

  CLEAR_SEND_FEEDBACK_PREVIOUS_STATE_REQUEST = 'clear_send_feedback_previous_state_request'
}
